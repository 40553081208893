import {Link} from "react-router-dom"
import HouseIcon from "../components/icons/HouseIcon";
import AccountsUsers from "../components/Dashboard/AccountsUsers";
import '../components/FundedAccounts/funded-accounts.css'
import FlagIcon from "../components/icons/FlagIcon";
import CheckCircle from "../components/icons/CheckCircle";
import "../components/FundedAccounts/list_price.css";

const FundedAccounts = () => {
  return (
    <div className="d-flex flex-column container-content-app container-funded-accounts">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
        <Link to="/dashboard"><HouseIcon /> Inicio</Link>
        </li>
        <li class="breadcrumb-item active">Mis cuentas fondeadas</li>
      </ul>
      <AccountsUsers />
      <div className="row container-info-funded-accounts">
        <div className="col-12 col-lg-6 " style={{display:'none'}}>
        </div>

        <div className="col-12 col-lg-6 mt-3 mt-lg-0 m-auto">
          <div className="container-information">
            <FlagIcon style={{height:'128px', width:'128px'}}/>
            <h3>EXAMEN CUENTA FONDEADA</h3>
            <h4>Opera con hasta $100,000 dólares </h4>
            <p>Muéstranos tus habilidades. Supera el examen y recibe una cuenta fondeada!</p>
            <ul>
              <li><div><CheckCircle/></div><span>Le proveemos una cuenta con hasta 100,000 dólares</span></li>
              <li><div><CheckCircle/></div><span>Las mejores condiciones y reglas</span></li>
              <li><div><CheckCircle/></div><span>Opera como y cuando quieras</span></li>
              <li><div><CheckCircle/></div><span>Media de pago de $7k dólares</span></li>
            </ul>
            <Link to="/start-test" className="button-send">Empezar Examen Ahora</Link>
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default FundedAccounts;
