import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HouseIcon from "../../components/icons/HouseIcon";
import {
  getDatabase,
  ref,
  query,
  get,
  limitToFirst,
  endBefore,
  limitToLast,
  orderByKey,
  startAfter,
} from "firebase/database";
import { app } from "../../firebase/firebase";
import "../../components/Users/users.css";

const UserCard = (props) => {
  return (
    <Link to={"/admin/user/" + props.userId} style={{ textDecoration: "none" }} >
      <div className="user-card position-relative" >
        <div className="row">
          <div className="col-12 col-sm-12 ">
            <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
              <h2 className="user-label">Usuario</h2>
              {props.accounts > 0 ? (
                <span className="label-buyer">Cliente</span>
              ) : (
                ""
              )}
            </div>
            <p className="user-title">
              {props.first_name} {props.last_name}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-7 mb-1 col-sm-7">
            <h4 className="user-label">Correo</h4>
            <p className="m-0 text-white" style={{ wordBreak: "break-all" }}>
              {props.email}
            </p>
          </div>
          <div className="col-12 col-lg-5 mb-1 col-sm-7">
            <h4 className="user-label">Fecha de creacion</h4>
            <p className="text-white m-0">
              {new Date(props.created_at).toString() !== "Invalid Date"
                ? new Date(props.created_at)
                    .toISOString()
                    .slice(0, 10)
                    .replaceAll("-", "/")
                : ""}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

const ListUsers = () => {
  const [users, setUsers] = useState([
    {
      firstname: "",
      last_name: "",
      email: "",
      created_at: "",
      accounts: 0,
    },
  ]);

  const [paginateView,setPaginateView] = useState(true)

  const [emailSearch,setEmailSearch] = useState("")
  const [lastUser, setLastUser] = useState({});
  const [firstUser, setFirstUser] = useState({});
  const itemsPerPage = 8; // Set the number of items per page

  const [load, setLoad] = useState(false);
  const [error, setOnError] = useState(false);

  const handleSearch = async (e) => {
    if(e){e.preventDefault()}
    const db = getDatabase(app);
    const usersRef = ref(db, "users"); // Reference to the users node

    try {
      const queryData = query(usersRef);
      const data = await get(queryData);

      if (data.exists()) {
        const usersList = Object.values(data.val());
        const dataUsers = usersList.filter((el) => {
          //catch the key to obtain data user
          const key_data = Object.keys(el)[0];
          return el[key_data].email.toLowerCase() === emailSearch.toLowerCase();
        });

        if (dataUsers.length > 0) {
          const dataSearch = dataUsers.map((el) => {
            //catch the key to obtain data user
            const key_data = Object.keys(el)[0];
            return {
              first_name: el[key_data].first_name || "",
              last_name: el[key_data].last_name || "",
              created_at: el[key_data].created_at || "",
              email: el[key_data].email || "",
              userId: el[key_data].userId,
              accounts: el.accounts ? Object.keys(el.accounts).length : 0,
            };
          });

          setUsers(dataSearch);
          setLoad(true);
          setOnError(false);
          setPaginateView(false)
        }else{
            setUsers([]);
              setLoad(true);
              setOnError(false);
              setPaginateView(false)
          }
      }
    } catch (err) {
        setUsers([]);
          setLoad(true);
          setOnError(false);
          setPaginateView(false)
      console.error(err);
    }
  };

  const request = async (initial, nextPage) => {
    //Request to list users
    setPaginateView(true)
    try {
      setLoad(false);
      const db = getDatabase(app);
      const usersRef = ref(db, "users");
      let usersQuery = query(
        usersRef,
        orderByKey(),
        limitToFirst(itemsPerPage)
      );
      if (!initial && nextPage) {
        usersQuery = query(
          usersRef,
          orderByKey(),
          startAfter(String(Object.values(lastUser)[0]["userId"])),
          limitToFirst(itemsPerPage)
        );
      }
      if (!initial && !nextPage) {
        usersQuery = query(
          usersRef,
          orderByKey(),
          endBefore(String(Object.values(firstUser)[0]["userId"])),
          limitToLast(itemsPerPage)
        );
      }
      const users = await get(usersQuery);
      if (users.exists()) {
        const usersList = Object.values(users.val());
        const dataUsers = usersList.map((el) => {
          //catch the key to obtain data user
          const key_data = Object.keys(el)[0];
          return {
            first_name: el[key_data].first_name || "",
            last_name: el[key_data].last_name || "",
            created_at: el[key_data].created_at || "",
            email: el[key_data].email || "",
            userId: el[key_data].userId,
            accounts: el.accounts ? Object.keys(el.accounts).length : 0,
          };
        });
        setFirstUser(users.val()[Object.keys(users.val())[0]]);
        setLastUser(
          users.val()[
            Object.keys(users.val())[Object.keys(users.val()).length - 1]
          ]
        );
        setUsers(dataUsers);
        setLoad(true);
        setOnError(false);
      } else {
        setLoad(true);
        setOnError(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputSearch = (e)=>{
    setEmailSearch(e.target.value)
    if(e.target.value === ""){
        request(true,true)
        setPaginateView(true)
    }
  }

  useEffect(() => {
    request(true, true);
  }, []);
  return (
    <div className="d-flex flex-column container-content-app">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="#">
            <HouseIcon /> Inicio
          </Link>
        </li>
        <li className="breadcrumb-item">Soporte</li>
        <li className="breadcrumb-item active">Usuarios</li>
      </ul>

      <div className="container-admin-users mb-5">
        <section className="section-users-admin container-page-users  section-content-page ">
          <div className="container">
            <div className="row">
              <h2 className="title-section title-dashboard-user text-center text-lg-start mb-lg-0">
                Usuarios registrados
              </h2>
            </div>
            <form onSubmit={handleSearch} className="d-flex flex-column flex-lg-row align-items-center justify-content-center gap-3 flex-wrap justify-content-lg-between align-items-lg-center mt-1">
              <input
                className="input-email"
                type="text"
                placeholder="Correo electronico"
                onInput={handleInputSearch}
                value={emailSearch}
              />
              <button
                className="button-send-transparent m-0"
                style={{
                  width: "100px",
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="submit"
              >
                Buscar
              </button>
            </form>
            {load ? (
                <>
              <div className="container-cards-users mt-3">
                {users.length > 0 ? users.map((el) => {
                  return (
                    <div key={el.userId}>
                    <UserCard
                      userId={el.userId}
                      first_name={el.first_name}
                      last_name={el.last_name}
                      created_at={el.created_at}
                      email={el.email}
                      accounts={el.accounts}
                    />
                    </div>
                  );
                }) : ''}
              </div>

              {
                users.length === 0 ? 
                <div className=" mt-3">
                <div className="d-flex flex-column justify-content-center gap-2 align-items-center">
                          <h3 className="title-section text-center title-dashboard-user text-center text-lg-start mb-lg-0">No se han encontrado usuarios</h3>
                          <button className="button-send-transparent" onClick={()=>{
                              setEmailSearch("")
                              request(true,true)
                              setPaginateView(true)
                          }}>Mostrar lista</button>
                      </div>
                      </div> : ''
              }
            </>
            ) : (
              ""
            )}

            {!load ? (
              <div
                style={{ height: "300px" }}
                className="d-flex flex-column align-items-center justify-content-center mb-4 mb-lg-0 m-auto"
              >
                <div
                  className="lds-dual-ring position-relative loader-mod"
                  style={{ position: "relative" }}
                ></div>
              </div>
            ) : (
              ""
            )}

           { paginateView === true ? <div className="d-flex align-items-center gap-2 mt-3 justify-content-between">
              <button
                className="button-send-transparent"
                onClick={() => {
                  request(false, false);
                }}
                style={{
                  textTransform: "capitalize",
                  padding: "10px 20px",
                  width: "max-content",
                }}
              >
                Anterior
              </button>
              <button
                className="button-send-transparent"
                onClick={() => {
                  request(false, true);
                }}
                style={{
                  textTransform: "capitalize",
                  padding: "10px 20px",
                  width: "max-content",
                }}
              >
                Siguiente
              </button>
            </div> : '' }
          </div>
        </section>
      </div>
    </div>
  );
};

export default ListUsers;
