import {
    getDatabase,
    ref,
    set,
    push,
    orderByChild,
    equalTo,
    query,
    get,
    update,
  } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { app, getUserDetails,auth } from "../../firebase/firebase";
import {useState,useEffect} from "react"
import { onAuthStateChanged } from "firebase/auth";

const SkrillCheckout = (props) =>{
    const [uid,setUid] = useState("")
    const [userData,setUserData] = useState({})
    const [randomUid,setRandomUid] = useState(window.crypto.randomUUID())
    const [emailPay] = useState("binaryfunded.official@gmail.com")
    //const [emailPay] = useState("demoqcoflexible@sun-fish.com")
    const [prices_packages] = useState([49,99,199,499,899,1499])
    const [formSubmit,setFormSubmit] = useState(false)

    const getDataUser = async () =>{
        try{
          const data = await getUserDetails()
          setUserData(data)
        }catch(err){
          console.log(err)
        }
      }

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
          console.log(user.uid);
          setUid(user.uid)
        });
      },[])
    
      useEffect(()=>{
        if(uid){
          getDataUser()
        }
      },[uid])

      useEffect(()=>{
        setRandomUid(window.crypto.randomUUID())
    },[formSubmit])

    // Save data to firebase from the check payment
    const navigate = useNavigate();

    const paymentSkrill = async (e)=>{

        try{
            console.log(props)
            e.preventDefault()
            if(formSubmit) return
            const userDetails = await getUserDetails()
            console.log(userData)
            setFormSubmit(true)
            if(prices_packages.indexOf(props.price) < 0){
                return navigate('/',{ replace: true });
            }
            
            if(!props.customerInfo.country){
              setFormSubmit(false)
              return alert("Por favor seleccione un pais")
            }
            const form = e.target
            console.log(props.customerInfo.country.split("-"))
            console.log(form['transaction_id'])
           /* const db = getDatabase(app);
            const ref_data = ref(db, `customers/payments/skrill_payments/${props.toBroker.toLowerCase()}/${userDetails.userId}/${form['transaction_id'].value}`);
            const newDoc = push(ref_data);
            const sendDataNew = {
              transaction_id:form['transaction_id'].value,
              firstname: userDetails.first_name,
              lastname: userDetails.last_name,
              code_country:props.customerInfo.country.split("-")[0],
              state_country: props.customerInfo.country.split("-")[1],
              email: userDetails.email,
              trader: props.toBroker,
              price: props.price,
              status:'in_progress',
              package: props.toPackage,
              created_at: new Date().toISOString(),
            };

    
            console.log(sendDataNew)
            
            setFormSubmit(false)
            const paymentsList = ref(db, `customers/payments/skrill_payments/${props.toBroker.toLowerCase()}/${userDetails.userId}/${form['transaction_id'].value}`);
    
            const paymentQuery = query(
                paymentsList
              );
    
            const data_previous = await get(paymentQuery);
            if (!data_previous.exists()) {
                const save = await set(newDoc, sendDataNew);
            }*/
            setFormSubmit(false)
            return form.submit()

        }catch(e){
            console.log(e)
            setFormSubmit(false)
            alert("Ocurrio un error al intentar esta transaccion, vuelva a intentarlo o intente con otro metodo de pago")
        }
       
        
    }
    return(
        <div class="skrill-container">
            <form className="d-flex flex-column" 
                    name="skrill_payment_form"
                    onSubmit={paymentSkrill}
                    method="post"
                    target="_blank"
                    action="https://pay.skrill.com"
                    id="skrill_payment_form">
                <input type="text" name="pay_to_email" value={emailPay} hidden/>
                <input type="text" name="transaction_id" value={randomUid} hidden/>   
                <input type="text" name="cancel_url" value={`${window.location.protocol}//${window.location.host}/checkout"`} hidden/>
                <input type="text" name="return_url" value={`${window.location.protocol}//${window.location.host}/complete-skrill`} hidden/>
                <input type="text" name="confirmation_note" value="Su pedido se ha completado" hidden/>
                <input type="text" name="pay_from_email" value={props.customerInfo.email}hidden/>
                <input type="text" name="recipient_description" value="Binary Funded" hidden/>
                <input type="text" name="firstname" value={userData.first_name} hidden/>
                <input type="text" name="lastname" value={userData.last_name} hidden/>
                <input type="text" name="logo_url" value="https://i.imgur.com/msHxtCV.png" hidden/>
                <input type="text" name="title" value="customer" hidden/>
                <input type="text" name="country" value={props.customerInfo.country.split("-")[1]} hidden/>
                <input type="text" name="amount" value={props.price} hidden/>
                <input type="text" name="status_url" value={`${window.location.protocol}//${window.location.host}/complete-skrill`} hidden/>
                <input type="text" name="currency" value="USD" hidden/>
                <input type="text" name="detail1_description" value="Compra de cuenta" hidden/>
                <input type="text" name="detail2_description" value="Fondeo a cuenta:" hidden/>
                <input type="text" name="detail3_description" value="Precio de cuenta" hidden/>
                <input type="text" name="detail1_text" value={props.toPackage} hidden/>
                <input type="text" name="detail2_text" value={props.toBroker} hidden/>
                <input type="text" name="detail3_text" value={`$${props.price}`} hidden/>
                <button className="button-send" type="submit" >Realizar pedido</button>
            </form>
        </div>
    )
}

export default SkrillCheckout;



