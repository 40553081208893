import * as React from "react"
const AffiliatesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="white"
      d="M6.5 12.024c2.698 0 4.898-2.238 4.898-4.983 0-2.744-2.2-4.982-4.897-4.982-2.698 0-4.897 2.238-4.897 4.982 0 2.745 2.2 4.983 4.897 4.983Zm0-7.716c1.482 0 2.687 1.226 2.687 2.733 0 1.507-1.205 2.733-2.686 2.733-1.482 0-2.687-1.226-2.687-2.733 0-1.507 1.205-2.733 2.687-2.733Z"
    />
    <path
      fill="white"
      d="M18.395 9.965c-2.653 0-4.997 1.395-6.357 3.509a7.481 7.481 0 0 0-4.433-1.45C3.415 12.023 0 15.486 0 19.75v1.125C0 21.494.497 22 1.105 22s1.106-.506 1.106-1.125V19.75c0-3.025 2.42-5.489 5.394-5.489 1.139 0 2.222.36 3.118 1.001l.365.293a7.977 7.977 0 0 0-.299 2.137v3.183c0 .619.498 1.125 1.106 1.125.608 0 1.105-.506 1.105-1.125v-3.183c0-3.026 2.42-5.489 5.395-5.489 2.973 0 5.394 2.463 5.394 5.489v2.058h-5.394c-.608 0-1.106.506-1.106 1.125S17.787 22 18.395 22h6.5c.608 0 1.105-.506 1.105-1.125v-3.183c0-4.263-3.416-7.727-7.605-7.727ZM23.292 4.983c0-2.745-2.2-4.983-4.897-4.983s-4.897 2.238-4.897 4.983c0 2.744 2.2 4.982 4.897 4.982s4.897-2.238 4.897-4.982Zm-4.897 2.733c-1.481 0-2.686-1.226-2.686-2.733 0-1.508 1.205-2.734 2.686-2.734 1.481 0 2.686 1.226 2.686 2.734 0 1.507-1.205 2.733-2.686 2.733Z"
    />
  </svg>
)
export default AffiliatesIcon
