import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import HouseIcon from "../components/icons/HouseIcon";
import { useNavigate } from "react-router-dom";
import { app} from "../firebase/firebase";
import '../components/Support/support-messages.css'
import '../components/Support/tickets.css'
import ModalDetailMessage from "../components/Support/ModalDetailMessage";
import {
    getDatabase,
    ref,
    query,
    get,
  } from "firebase/database";


const SupportMessages = () => {
    const LIMIT_ELEMENTS = 6
    const [showModal,setShowModal] = useState(false)
    const [modalDetail,setModalDetail] = useState([{fullname:'',date:'',email:'',message:''}])
    const [messages,setMessages]= useState([])
    const [load,setLoad] = useState(false)
    const [error,setOnError] = useState(true)

    const options_date = { 
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric", 
        minute: "numeric",
        second: "numeric"
      };
  

    const handleClose = ()=>{
        setShowModal(false)
    }


    const showMessage = (show,message) =>{
        setShowModal(true)
        setModalDetail(message);
    }

    const requestMessages = async() =>{
        try{
            const db = getDatabase(app);
            const messagesRef = ref(db, "support/messages");
            const messagesQuery = query(messagesRef);
            const messages = await get(messagesQuery)
            if(messages.exists()){
                const listMessages = Object.values(messages.val())
                console.log(messages)
                const orderMessage = listMessages.sort((a,b)=> new Date(b.created_at) - new Date(a.created_at))
                setMessages(orderMessage)
                setLoad(true)
                setOnError(false)
            }else{
                setLoad(true)
                setOnError(true)
            }
        }catch(e){
            console.log(e)
            setLoad(true)
            setOnError(true)
        }
    }

    const [offSetList, setoffSetList] = useState(0);
    
    const handleNext = () => {
        if (offSetList + LIMIT_ELEMENTS < messages.length) {
          setoffSetList(offSetList + LIMIT_ELEMENTS);
        }
      };
    
      const handlePrev = () => {
        if (offSetList > 0) {
          setoffSetList(offSetList - LIMIT_ELEMENTS);
        }
      };



    useEffect(() => {
        window.scrollTo(0, 0);
        requestMessages()
      }, []);

 

    return (
        <>
            <ModalDetailMessage
                show={showModal}
                ticket={modalDetail}
                handleClose={handleClose}
            />
            <div className="d-flex flex-column container-content-app">
                <ul className="breadcrumb">
            <li className="breadcrumb-item">
                <Link to="#">
                <HouseIcon /> Inicio
                </Link>
            </li>
            <li className="breadcrumb-item">Soporte</li>
            <li className="breadcrumb-item active">Mensajes</li>
            </ul>

                <div className="container-admin-support-messages">
                    <section className="section-support-admin container-page-support ">
                        <h2 className="title text-center text-lg-start">Mensajes de usuarios</h2>
                        {load && messages.length >= 1 && !error ? <p className='text-center text-white text-lg-start'>Cantidad de mensajes creados: {messages.length}</p> : ''}
                        {!load ? (
                            <div className="d-flex flex-column align-items-center justify-content-center mb-4 mb-lg-0">
                                <div className="lds-dual-ring"></div>
                            </div>
                            ) : (
                            ""
                            )}

                        {load && error ? <div className='d-flex flex-column'> 
                            <p className='text-start text-white'>Ha ocurrido un error al cargar los mensajes, prueba a recargar</p>
                            <button className='button-transparent button-send-transparent m-auto m-lg-0' type="button" onClick={()=>{}}>Recargar</button>
                             </div>: ''}

                        {load && !error ? <div className="container-messages">
                            {
                                messages.slice(offSetList, offSetList + LIMIT_ELEMENTS).map((message,index)=>{
                                    return(
                                        <div className="d-flex flex-column message" key={index} onClick={()=>{showMessage(true,{...message, fullname:`${message.firstname} ${message.lastname}`})}}>
                                            <div className="row">
                                                <div className="d-flex flex-column col-12 col-lg-6">
                                                    <span>Mensaje de:</span>
                                                    <p>{`${message.firstname} ${message.lastname}`}</p>
                                                </div>
                                                <div className="d-flex flex-column col-12 col-lg-6">
                                                    <span>Fecha de creacion:</span>
                                                    <p style={{fontSize:'14px', textTransform:'capitalize'}}>{message.created_at ? new Date(message.created_at) !== "Invalid Date" ? new Date(message.created_at).toLocaleDateString("es-ES", options_date): "" : ""}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="d-flex flex-column">
                                                    <span>Correo:</span>
                                                    <p>{message.email}</p>
                                                </div>
                                            </div>
                                    
                                        </div>
                                    )
                                })
                            }
                         


                        </div> : ''}

                        {messages.length > LIMIT_ELEMENTS ? 
                        <div className="d-flex align-items-center gap-2 mt-3 justify-content-between">
                            <button
                            className="button-send-transparent"
                            onClick={() => {
                                handlePrev();
                            }}
                            style={{
                                textTransform: "capitalize",
                                padding: "10px 20px",
                                width: "max-content",
                            }}
                            >
                            Anterior
                            </button>
                            <button
                            className="button-send-transparent"
                            onClick={() => {
                                handleNext();
                            }}
                            style={{
                                textTransform: "capitalize",
                                padding: "10px 20px",
                                width: "max-content",
                            }}
                            >
                            Siguiente
                            </button>
                        </div>: ''}
                    </section>
                    
                </div>
            </div>
        </>
    );
}

export default SupportMessages;