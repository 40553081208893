import React from "react"
import AccountsIcon from "../icons/AccountsIcon";
import { Link } from "react-router-dom";
import warningIcon from "../../assets/icons/warning.png"
import './advice.css'
const Advice = () =>{
    return(
        <>
        <div className="advice-element">
            <div className="container-message">
                <img src={warningIcon} alt="alert"/>
                <p>
                Lo sentimos, esta página es accesible solo para los traders de BinaryFunded (los traders de BF son aquellos que ya superaron el examen y ya están administrando una cuenta fondeada de BinaryFunded).
                </p>

                <Link
            to="/start-test"
            className="nav-item button-send"
          >
            <div>
              <AccountsIcon/>
              <p>Empezar Nuevo examen</p>
            </div>
          </Link>
            </div>
        </div>
        </>
    )
}

export default Advice;