import {useState, useEffect} from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    getDatabase,
    ref,
    set,
    get,
    push,
  } from "firebase/database";
  import { app, getUserDetails } from "../../firebase/firebase";
const ModalCreateTicket = (props)=>{
    const [data,setData] = useState({subject:'',description:''})
    const [show, setShow] = useState(props.show);
    const [onSubmit,setOnSubmit] = useState(false)

    const handleChangeInput = (e) =>{
        const {name,value} = e.target;
        setData({
            ...data,
            [name]:value
        })
    
    }

    const sendForm = async (e) =>{
        e.preventDefault()
        if(onSubmit) return
        setOnSubmit(true)
        if(data.subject === '' || data.description === ''){
            setOnSubmit(false)
            alert('Por favor complete todos los campos')
            return
        }

        if(data.subject.length > 40){
            setOnSubmit(false)
            alert('El asunto no puede tener mas de 40 caracteres')
            return
        }

        try{
            //Generate random id
            const id = window.crypto.getRandomValues(new Uint32Array(1))[0]
            const userDetails = await getUserDetails()
            
            //Create ticket
            
            const db = getDatabase(app);
            const ref_data = ref(db, `support/tickets/${userDetails.userId}/${id}`);

            //Check if user has accounts
            const data_account = ref(db, `/users/${userDetails.userId}/accounts`);
            const data_account_query = await get(data_account)
            let buyer = false
            if(data_account_query.exists()){
              buyer = true
            }

            //Send the data
            const sendDataNew={id:id, subject:data.subject,description:data.description,created_at:new Date().toISOString(),status:'Abierto', uid:userDetails.userId, buyer:buyer};

            await set(ref_data, sendDataNew);
            alert("Ticket creado correctamente")
            setOnSubmit(false)
            setData({subject:'',description:''})
            props.handleClose()

        }catch(e){
            setOnSubmit(false)
            alert("Error al crear el ticket, intente nuevamente")
        }
    }
    useEffect(() => {
      setShow(props.show);
    }, [props]);


    return (
        <>
        <Modal
          className="modal-custom-ticket modal-custom-ticket-create"
          show={show}
          onHide={props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Nuevo ticket</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="d-flex flex-column container-form" onSubmit={sendForm}>

              <div className="d-flex flex-column mb-1">
                <label for="subject">Asunto</label>
                <input type="text" name="subject" placeholder="Introduce el asunto del ticket" onInput={(e)=>{handleChangeInput(e)}} value={data.subject}/>
              </div>
              
              <div className="d-flex flex-column">
                <label for="subject">Descripción</label>
                <textarea name="description" placeholder="Introduce la descripción del ticket" onInput={(e)=>{handleChangeInput(e)}} value={data.description}/>
              </div>

              <div className='d-lfex flex-column'>
                <button className='button-send m-auto mt-4' type="submit">Enviar</button>
              </div>
    
            </form>
          </Modal.Body>
          <Modal.Footer>
                <Button variant="secondary" type="button" onClick={props.handleClose}>
                Cerrar
                </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
}

export default ModalCreateTicket