import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, getUserDetails } from "../../firebase/firebase";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
//import {verifyToken} from '../API'
const ProtectedRoute = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState(null);
  const [IsAdmin, setIsAdmin] = useState(false);
  const getDataUser = async () => {
    const data = await getUserDetails();
    if (!data.admin) {
      navigate("/dashboard");
    }
    setIsAdmin(data.admin);
  };

  useEffect(() => {
    getDataUser();
  }, [uid]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
      }
    });
  }, []);

  return IsAdmin ? (
    <>
      <Outlet />
    </>
  ) : (
    <div className="d-flex flex-column container-content-app">
      <div className="d-flex flex-column align-items-center justify-content-center mb-4 mb-lg-0 m-auto">
        <div
          className="lds-dual-ring loader-mod"
          style={{ position: "absolute", top: "140px" }}
        ></div>
      </div>
    </div>
  );
};

export default ProtectedRoute;
