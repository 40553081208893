import './skrillsuccess.css'
import {Link} from "react-router-dom"
const SkrillSuccess = () =>{
    return (
        <div className="container-success">
            <div className="content">
                <h3>Su pago en Skrill ha sido procesado</h3>
                <p>Pronto deberá de recibir un email para confirmar su transacción</p>
                <Link className="button-link" to="/dashboard">Volver a la pagina principal</Link>
            </div>
        </div>
    )
}

export default SkrillSuccess