import { useState, useEffect } from "react";
import {Link} from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/firebase";
import NavbarWhenUserLogin from "../components/Navbar/NavbarWhenUserLogin";
import NewFooter from "../components/Footer/NewFooter";
import Navbar from "../components/Navbar/Navbar";
import '../components/Support/support.css'
import EmailIcon from '../components/icons/EmailIcon'

import ChatIcon from "../components/icons/ChatIcon";

const Support = () =>{
    const [user, setUser] = useState(null);
 

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
        if (user) {
            setUser(true);
        } else {
            setUser(false);
        }
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="container-page-support">
             {user ? <NavbarWhenUserLogin /> : <Navbar />}
             <section className="container-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="content-support">
                                <h1 className="text-white">Contactanos</h1>
                                <p className="text-white">Simplemente envíenos sus preguntas o inquietudes y le brindaremos la mejor ayuda que necesita. </p>
                                <p className="text-white" style={{marginBottom:'0px'}}>Puedes escribirnos por nuestro chat en vivo y nuestro equipo se pondra en contacto con usted</p>
                                <Link style={{textDecoration:'none', marginTop:'5px'}} className="button-send" target="_blank" rel="noopener noreferrer" to="https://tawk.to/chat/66895addeaf3bd8d4d18d995/1i248lseh"><ChatIcon style={{fill:'#0e140c'}}/>Chat en vivo</Link>
                                <p className="text-white" style={{marginBottom:'4px', marginTop:'5px',textDecoration: 'none',textTransform: 'none',fontSize: '16px'}}>Tambien puede comunicarse utilizando los siguientes medios:</p>
                                <ul>
                                    {/*<li><PhoneIcon className="icon-contact"/>Telefono: <Link to="tel:+5500000000" target="_blank" rel="noopener noreferrer" >+5500000000</Link> </li>*/}
                                    <li><EmailIcon className="icon-contact"/>Correo: <Link target="_blank" rel="noopener noreferrer" to="mailto:soporte@binaryfunded.com">soporte@binaryfunded.com</Link></li>
                                    <li><svg
                className="icon-contact"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 448 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                </svg>Instagram:<Link to="https://www.instagram.com/binaryfunded" rel="noopener noreferrer" target="_blank">
                
                 @binaryfunded
              </Link></li>
                                </ul>
                            </div>
                            {/*
                            <div className="d-none mt-4 gap-3 flex-wrap justify-content-center justify-content-lg-start">
                                <Link target="_blank" rel="noopener noreferrer" to="/" className="button-send-transparent"><WhatsappIcon className="whatsapp-icon" style={{transform:'scale(0.7)', marginLeft:'-7px'}}/> <span>Whatsapp</span></Link>
                                <Link target="_blank" rel="noopener noreferrer" to="/" className="button-send-transparent"><TelegramOutlineIcon className="telegram-icon" style={{height:'35px' ,width:'35px', marginRight:'7px'}}/> <span>Telegram</span></Link>
                            </div>*/}
                        </div>
                       {/* <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                            <div className="container-form-contact">
                                <form className="d-flex flex-column" onSubmit={handleForm}>
                                    <h2 className="text-center text-white">¿Tiene alguna pregunta?</h2>
                                    <p className="text-center">Rellene este formulario y le responderemos lo antes posible</p>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 d-flex flex-column field">
                                            <label for="name">Nombre</label>
                                            <input type="text" name="firstname" onInput={(e)=>{handleInput(e)}} placeholder="Escribe tu nombre..." value={formSupport.firstname} required/>
                                        </div>
                                        <div className="col-12 mt-2 mt-lg-0 col-lg-6 d-flex flex-column field">
                                            <label for="lastname">Apellido</label>
                                            <input type="text" name="lastname" onInput={(e)=>{handleInput(e)}}  placeholder="Escribe tu apellido..." value={formSupport.lastname} required/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-12 d-flex flex-column field">
                                            <label for="email">Correo</label>
                                            <input type="email" name="email" onInput={(e)=>{handleInput(e)}}  placeholder="Escribe tu correo..." value={formSupport.email} required/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-lg-12 d-flex flex-column field">
                                            <label for="message">Mensaje</label>
                                            <textarea name="message" placeholder="Escribe tu mensaje..." onInput={(e)=>{handleInput(e)}}  cols={6} value={formSupport.message} required></textarea>
                                        </div>
                                    </div>

                                    <button className="button-send m-auto mt-4" type="submit">Enviar</button>
                                </form>
                            </div>
                        </div>*/}
                    </div>
                </div>
                
             </section>

             {/* <Tickets user={user}/> */} 

             <NewFooter />
        </div>
    )
}

export default Support