import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import tutorialVideoQuotex from "../../assets/videos/tutorial.mp4";
function ModalAccountInfo(props) {
  // This component is a modal that shows the information of the account
  const [show, setShow] = useState(props.show);
  const [PAGEURL] = useState({
    quotex: "https://qxbroker.com/",
    iqoption: "",
    binomo: "",
  });
  // This function is executed when the component is rendered
  useEffect(() => {
    setShow(props.show);
  }, [props]);

  return (
    <>
      <Modal
        className="modal-custom-accounts"
        show={show}
        onHide={props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Informacion de la cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column">
              <h3>Correo</h3>
              <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                {props.user.email}
              </p>
            </div>
            <div className="d-flex flex-column">
              <h3>Contraseña</h3>
              <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                {props.user.password}
              </p>
            </div>
            <div className="d-flex flex-column">
              <h3>Servidor</h3>
              <p
                style={{
                  fontSize: "14px",
                  wordBreak: "break-all",
                  textTransform: "capitalize",
                }}
              >
                {props.user.server}
              </p>
            </div>
            <div className="d-flex flex-column" style={{borderTop:'1px solid white', paddingTop:'10px'}}>
              <p style={{fontSize:'14px'}}>Es importante que estas credenciales coincidan con la cuenta de {props.user.server} que está utilizando para completar el examen, puede ser una cuenta nueva o antigua, y esta debe de estar desactivada la doble autenticación para que nuestro equipo pueda auditar la cuenta durante el examen.</p>
              <p style={{fontSize:'14px'}}>Si tiene algun problema o ha introducido mal las credenciales, por favor contacte o abra un ticket en <Link className="link-modal" to="/support">soporte</Link></p>
              </div>
           {/* <div class="accordion" id={`accordion-${props.user.email}`}>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id={`headingOne-${props.user.email}`}
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#CollapseOne-${props.user.email}`}
                    aria-expanded="false"
                    aria-controls={`CollapseOne-${props.user.email}`}
                  >
                    ¿Como puedo acceder a cuenta de {props.user.server} por
                    primera vez?
                  </button>
                </h2>
                <div
                  id={`CollapseOne-${props.user.email}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`headingOne-${props.user.email}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-4 pt-0">
                    <div className="d-flex flex-column">
                      <video
                        className="video-account"
                        style={{ maxWidth: "450px" }}
                        controls
                      >
                        <source src={tutorialVideoQuotex} type="video/mp4" />
                      </video>
                      <h4
                        className="description-title mt-2"
                        style={{ fontSize: "17px" }}
                      >
                        Pasos descriptivos
                      </h4>
                      <p>
                        1. Ingresa a la plataforma de{" "}
                        <Link
                          className="url-server"
                          to={`${PAGEURL[props.user.server]}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {props.user.server}{" "}
                        </Link>
                      </p>
                      <p>
                        2. Dirigase al boton para registrar el correo y la
                        contraseña
                      </p>
                      <p>
                        3.(Importante) Complete los datos del formulario usando
                        las credenciales proporcionadas
                      </p>
                      <p>
                        4. De click en el boton azul registro y cambie su cuenta
                        real por la de demo
                      </p>
                      <p>5. Comienza con tus operaciones</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalAccountInfo;
