import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getDatabase, ref, update, query,get } from "firebase/database";
import { app } from "../../firebase/firebase";
const ModalDetail = (props) => {
  const [show, setShow] = useState(props.show);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [infoUser,setInforUser] = useState({first_name:'',last_name:'',email:''})
  const changeStatus = async () => {
    try {
      if (props.isAdmin) {
        //Update status from the ticket
        const db = getDatabase(app);
        const ref_data = ref(
          db,
          `support/tickets/${props.ticket.uid}/${props.ticket.id}`
        );
        await update(ref_data, {
          status: status,
          updated_at: new Date().toISOString(),
        });
        alert("Ticket actualizado correctamente");
        props.handleClose();
        props.requestTicketsAdmin();
      }
    } catch (e) {
      alert("Ocurrio un error al actualizar el ticket");
      return;
    }
  };

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };

  const loadUserData = async () =>{
    try{
      setLoading(true)
      if(props.ticket.uid){
        const db = getDatabase(app);
        const ref_data = ref(
          db,
          `users/${props.ticket.uid}`
        );
        const userQuery = query(ref_data);
        
        const data_previous = await get(userQuery);
        if(data_previous.exists()){
          const userIdData = Object.keys(data_previous.val())[0];
          const dataUser = data_previous.val()[userIdData]
          setInforUser(dataUser)
          setLoading(false)
        }

      }
    }catch(e){
      setLoading(false)
      alert('Ocurrio un error al cargar la informacion del ticket')
      props.handleClose()
    }
  }

  useEffect(() => {
    if(props.show === true && props.isAdmin === true){
      loadUserData()
    }
    setShow(props.show);
    setStatus(
      props.ticket.status ? props.ticket.status.toLowerCase() : "abierto"
    );
  }, [props]);
  return (
    <>
      <Modal
        className="modal-custom-ticket"
        show={show}
        onHide={props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalles del ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {loading === false ? <div className="d-flex flex-column">
            {/*<div className="d-flex flex-column">
              <h3>Identificador</h3>
              <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                {props.ticket?.id}
              </p>
            </div>*/}

            {props.isAdmin && props.ticket.buyer ? (
              <div
                style={{ position: "absolute", right: "9px" }}
                className="d-flex flex-column"
              >
                <h3
                  style={{
                    background: "#b0ef96",
                    color: "black",
                    fontWeight: "500",
                    padding: "2px 6px",
                    width: "max-content",
                    fontSize: "14px",
                  }}
                >
                  Cliente
                </h3>
              </div>
            ) : (
              ""
            )}

            <div className="d-flex flex-column">
              <h3>Asunto</h3>
              <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                {props.ticket?.subject}
              </p>
            </div>

            {infoUser.first_name && infoUser.last_name && props.isAdmin ? (
              <div className="row">
                <div className="col-12 col-lg-6 d-flex flex-column">
                  <h3>Nombre</h3>
                  <p
                    style={{
                      fontSize: "14px",

                      textTransform: "capitalize",
                    }}
                  >
                    {infoUser.first_name || ""}
                  </p>
                </div>

                <div className="col-12 col-lg-6 d-flex flex-column">
                  <h3>Apellido</h3>
                  <p
                    style={{
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {infoUser.last_name || ""}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}


            {props.isAdmin && infoUser.email ? (
              <div className="d-flex flex-column">
                <h3>Email del usuario</h3>
                <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                  {infoUser.email}
                </p>
              </div>
            ) : (
              ""
            )}

          
            <div className="d-flex flex-column">
              <h3>Descripción</h3>
              <p
                style={{
                  fontSize: "14px",

                  maxHeight: "180px",
                  overflow: "auto",
                }}
              >
                {props.ticket?.description}
              </p>
            </div>

            <div className="row">
              <div className="col-12 col-lg-6 d-flex flex-column">
                <h3>Fecha de creacion</h3>
                <p
                  style={{
                    fontSize: "14px",

                    textTransform: "capitalize",
                  }}
                >
                  {new Date(props.ticket.created_at).toString() !==
                  "Invalid Date"
                    ? new Date(props.ticket.created_at)
                        .toISOString()
                        .slice(0, 10)
                        .replaceAll("-", "/")
                    : ""}
                </p>
              </div>

              {!props.isAdmin ? (
                <div className="col-12 col-lg-6 d-flex flex-column">
                  <h3>Estado</h3>
                  <p
                    style={{
                      fontSize: "14px",
                      textTransform: "capitalize",
                    }}
                  >
                    {props.ticket.status}
                  </p>
                </div>
              ) : (
                ""
              )}
              {props.isAdmin ? (
                <div className="col-12 col-lg-6 d-flex flex-column">
                  <h3>Estado</h3>
                  <select
                    className="select-modal"
                    onChange={handleChangeStatus}
                    defaultValue={status}
                  >
                    <option value="abierto">Abierto</option>
                    <option value="atendido">Atendido</option>
                    <option value="cerrado">Cerrado</option>
                  </select>
                </div>
              ) : (
                ""
              )}
            </div>
            {props.isAdmin ? (
              <div className="d-flex flex-column">
                <button
                  className="button-send m-auto mt-3"
                  onClick={() => {
                    changeStatus();
                  }}
                  type="button"
                >
                  Actualizar ticket
                </button>
              </div>
            ) : (
              ""
            )}
          </div> : ''}
          {loading ? <div className="d-flex flex-column align-items-center justify-content-center mb-4 mb-lg-0 m-auto">
        <div
          className="lds-dual-ring loader-mod"
        ></div>
      </div> : ''}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={props.handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDetail;
