import React, { useState, useEffect } from "react";
import { auth, app, getUserDetails } from "../../firebase/firebase";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";


import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import "./LoginPage.css";

const LoginPage = () => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    general: "",
  });
  const [login, setLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (userFirebase) => {
      if (userFirebase) {
        setUser(userFirebase);
        const dataUser = await getUserDetails();
        if(dataUser.admin){
          navigate('/admin/support/tickets')
          return
        }else{
          navigate('/dashboard')
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const handleClickLogin = (e) => {
    e.preventDefault();
    try {
      signInWithEmailAndPassword(auth, user.email, user.password)
        .then(async (credentials) => {
          console.log(credentials);
          setLogin(true)
          const dataUser = await getUserDetails();
          if(dataUser.admin){
            navigate('/admin/support/tickets')
            return
          }else{
            navigate('/dashboard')
          }
        })
        .catch((err) => {
          alert("No se pudo iniciar sesion, verifique los datos")
          console.log(err);
        });
     
    } catch (err) {
      console.error(err);
      alert("No se pudo iniciar sesion, verifique los datos")
      setErrors({
        ...errors,
        general: "Login failed. Please check your credentials and try again.",
      });
    }
  };

  const handleClickSignup = () => {
    navigate("/SignUp");
  };
  function ShowPassword() {
    setShowPassword(false);
    setHidePassword(true);
  }
  function HidePassword() {
    setHidePassword(false);
    setShowPassword(true);
  }
  return (
    <div className="container login-container container-login-section">
      <form onSubmit={handleClickLogin}>
        <h1 className="login-text fw-bold">Iniciar sesion</h1>
        <div className="field-input text-center">
          <label label="email">Correo electronico</label>
          <input
            name="email"
            type="text"
            className="input-login"
            placeholder="Introduce tu correo"
            value={user.email}
            onChange={(event) =>
              setUser({ ...user, email: event.target.value })
            }
          />
          {errors.email && (
            <p className="text-danger error-md">{errors.email}</p>
          )}
        </div>
        <div className="position-relative field-input text-center">
          <label label="password">Contraseña</label>
          <div className="d-flex position-relative w-100">
            <input
              name="password"
              type={showPassword ? "text" : "password"}
              className="input-login"
              placeholder="Introduce tu contraseña..."
              value={user.password}
              onChange={(event) =>
                setUser({ ...user, password: event.target.value })
              }
            />
            {showPassword ? (
              <FaEye onClick={ShowPassword} className="eye-icon" />
            ) : (
              <FaEyeSlash onClick={HidePassword} className="eye-icon" />
            )}
          </div>

          {errors.password && (
            <p className="text-danger error-md">{errors.password}</p>
          )}
        </div>
        <button type="submit" className="button-send">
          Ingresar
        </button>
        <div>
          <p className="acc-text">
            No estas registrado?{" "}
            <Link style={{textDecoration:'none'}} to="/SignUp" className="register-text">
              Registrate!
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
