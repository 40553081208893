import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import PopupCheckout from "./PopupCheckout";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import "./checkout.css";
import { Link } from "react-router-dom";
import SkrillCheckout from "./SkrillCheckout";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import FlagIcon from "../icons/FlagIcon";
import CardsCheckout from "./CardsCheckout";
import EthereumIcon from "../../assets/ethereum-icon.png";
import UsdtIcon from "../../assets/usdt-icon.png";
import BitcoinIcon from "../../assets/bitcoin-icon.png";
import SrillLogo from "../../assets/skrill-logo.png";
import MastercardLogo from "../../assets/mastercard-logo.png";
import CheckoutChoosePackages from "./CkeckoutChoosePackages";
import {PRICES} from '../../utils/index'
const CheckoutPage = () => {
  const navigate = useNavigate();
  //Style select
  const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      background: "transparent",
      textColor: "white",
      color: "white !important",
      border: state.isFocused ? "1px solid #b0ef96" : "1px solid #4f969c",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        border: state.isFocused ? "1px solid #b0ef96" : "1px solid #4f969c",
      },
      "&:focus": {
        border: state.isFocused ? "1px solid #b0ef96" : "1px solid #4f969c",
      },
    }),

    input: (provided) => ({
      ...provided,
      color: "white",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white", // Custom colour
    }),
  };

  const selectStylePayments = {
    control: (provided, state) => ({
      ...provided,
      background: "#285c64",
      textColor: "white",
      color: "white !important",
      border: state.isFocused ? "1px solid #b0ef96" : "1px solid #4f969c",
      borderRadius: "8px",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        border: state.isFocused ? "1px solid #b0ef96" : "1px solid #4f969c",
      },
      "&:focus": {
        border: state.isFocused ? "1px solid #b0ef96" : "1px solid #4f969c",
      },
    }),

    input: (provided) => ({
      ...provided,
      color: "white",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white", // Custom colour
    }),
  };

  const { Option, SingleValue } = components;

  const IconOption = (props) => (
    <Option {...props} isOptionDisabled={props.data.isDisabled} isDisabled={props.data.isDisabled}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.data.label}

        {props.data.icons
          ? props.data.icons.map((el,index) => {
              return (
                <img
                key={index}
                  src={el}
                  style={{
                    objectFit: "contain",
                    height: 24,
                    marginLeft: "5px",
                  }}
                  alt={props.data.label}
                />
              );
            })
          : ""}
      </div>
    </Option>
  );

  const ValueOption = (props) => (
    <SingleValue {...props}>
      {props.data.label}{" "}
      {props.data.icons
        ? props.data.icons.map((el,index) => {
            return (
              <img
                key={index}
                src={el}
                style={{
                  objectFit: "contain",
                  height: 22,
                  marginLeft: "7px",
                  marginTop: "-3px",
                }}
                alt={props.data.label}
              />
            );
          })
        : ""}
    </SingleValue>
  );
  //States
  const API_KEY_NOWPAYMENTS = "238HNGY-DPHMFAX-N7RNVGH-WCNJ02G";
  const [dataOrder, setDataOrder] = useState({
    price: 0,
    name: "Examen Trader",
    description: "",
    broker: "",
    package: "",
  });
  const [countriesList, setCountries] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);
  const [currencies, setCurrencies] = useState([{ value: "", label: "" }]);
  const [acceptTerms, setAcceptTerms] = useState({
    first: false,
    second: true,
  });
  const [paymentMethod, setPaymentMethod] = useState("cripto-currency");
  const [sendActive, setSendActive] = useState(false);
  const [prices_packages] = useState(PRICES);
  const [selectedPackage,setSelectedPackage]= useState({name:"",trade:"",price:0})
  const [customerInfo, setCustomerInfo] = useState({
    firstname: "",
    lastname: "",
    country: "",
    street_address: "",
    town_city: "",
    state_country: "",
    postcode: "",
    email: "",
  });
  const [criptoTransaction, setCriptoTransaction] = useState({
    price_amount: 0,
    price_currency: "usd",
    pay_currency: "",
    ipn_callback_url: "https://nowpayments.io",
    order_id: "",
    is_fee_paid_by_user: false,
    is_fixed_rate: false,
    order_description: "",
  });

  const [paymentCrypto, setPaymentCrypto] = useState({
    payment_id: "",
    created_at: "",
    expiration_estimate_date: "",
    pay_address: "",
    price_amount: "",
    price_currency: "",
    pay_amount: "",
    pay_currency: "",
    order_description: "",
  });

  const [payOptions] = useState([
    {
      value: "cripto-currency",
      label: "Criptomoneda",
      icons: [UsdtIcon, EthereumIcon, BitcoinIcon],
    },
    { value: "skrill", label: "Skrill", icons: [SrillLogo],isDisabled: true },
    {
      value: "card",
      label: " Tarjetas de crédito / débito",
      icons: [MastercardLogo],
      isDisabled: true
    },
  ]);

  const [showPopup, setShowPopup] = useState(false);
  const [showFacturaction, setShowFacturaction] = useState(false);

  useEffect(() => {
    if (paymentMethod !== "cripto-currency") {
      setShowFacturaction(true);
    }
  }, [paymentMethod]);

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("to_pay")) || false;
    /*if (!localData || !localData.pay_amount) {
      navigate("/", { replace: true });
    }*/

    setDataOrder({
      name: `${localData.name}`,
      package: `${localData.name}`,
      description:`Examen - ${localData.name} - ${localData.broker}`,
      price: localData.pay_amount,
      broker: localData.broker,
    });
    setCriptoTransaction({
      ...criptoTransaction,
      price_amount: localData.pay_amount,
    });
    //Obtain all currencies on the list
    const getAllCurrency = async () => {

      const list = {
        currencies: [
          {
            code: "USDTTRC20",
            network: "trx",
            ticker: "usdt - trc20",
          },
          {
            code: "BTC",
            ticker: "btc",
            network: "btc",
          },
          {
            code: "ETH",
            ticker: "eth",
            network: "eth",
          },
          {
            code: "USDTERC20",
            network: "eth",
            ticker: "usdt - erc20",
          },
         
        ],
      };
      const data = list.currencies.map((el) => {
        return {
          value: el.code,
          label: `${el.ticker?.toUpperCase()}  ${
            el.ticker?.toUpperCase() === el.network?.toUpperCase()
              ? ""
              : `- ${el.network?.toUpperCase()}`
          }`,
        };
      });
      setCurrencies(data);
    };

    getAllCurrency();

  }, []);

  //Handle currency change
  const [currencieFormat, setCurrencieFormat] = useState("");
  const handleCurrencySelected = (data) => {
    setCurrencieFormat(data.label);
    setCriptoTransaction({ ...criptoTransaction, pay_currency: data.value });
  };

  const handleInputForm = (e) => {
    const { name, value } = e.target;
    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const handleChangePackage = ({name, broker, pay_amount, position_broker})=>{
    setDataOrder({
      name: `${name}`,
      package: `${name}`,
      description:`Examen - ${name} - ${broker}`,
      price: pay_amount,
      broker: broker,
    });

    localStorage.setItem(
      "to_pay",
      JSON.stringify({
        pay_amount: pay_amount,
        name: `${name}`,
        description:`Examen - ${name} - ${broker}`,
        broker: `${broker}`,
        position_broker:`${position_broker}`
      })
    );
  }

  const handleSelectCountry = (e) => {
    setCustomerInfo({ ...customerInfo, country: e.target.value });
  };

  //Send payment

  const setShowModal = (value) => {
    setShowPopup(value);
  };

  const handleFormPayment = async () => {
    if (prices_packages.indexOf(dataOrder.price) < 0) {
      return navigate("/", { replace: true });
    }

    if (sendActive) return;

    //Check if the inputs customers is full
    if (
      customerInfo.country === ""
    ) {
      return alert("Por favor llenes todos los campos obligatorios");
    }

    /*if (!customerInfo.email || !/^\S+@\S+\.\S+$/.test(customerInfo.email)) {
      return alert("Por favor ingrese una direccion de correo valida.");
    }*/
    // Check cripto terms
    if (criptoTransaction.pay_currency === "") {
      return alert("Por favor seleccione la moneda de pago");
    }


    //Check id
    setSendActive(true);

    try {

      const res = await fetch("https://api.nowpayments.io/v1/payment", {
        method: "POST",
        headers: {
          "x-api-key": API_KEY_NOWPAYMENTS,
          "Content-Type": "application/json",
        },


        
        body: JSON.stringify({
          ...criptoTransaction,
          price_amount: dataOrder.price,
          is_fee_paid_by_user: false,
          order_id: `${dataOrder.name} - ${dataOrder.broker} - ${localStorage.getItem("email") || ''}`,
          order_description: `${dataOrder.name} - ${dataOrder.broker} - ${localStorage.getItem("email") || ''}`,
        }),
      });

      if (!res.ok) {
        setSendActive(false);
        setSendEmail(false);
        alert("Error al crear la transaccion, intenta con otra moneda");
        return console.log(
          "Error al crear la transaccion, intenta con otra moneda"
        );
      }
      setSendEmail(false);
      setSendActive(false);
      const response = await res.json();
      setPaymentCrypto(response);
      setShowPopup(true);
    } catch (err) {
      setSendActive(false);
      alert("Error al crear la transaccion, intenta con otra moneda");
    }
  };

  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(true);
      } else {
        setUser(false);
      }
    });
  }, []);

  return (
    <>
      <PopupCheckout
        customerInfo={customerInfo}
        paymentId={paymentCrypto.payment_id}
        timeStart={paymentCrypto.created_at}
        timeEnd={paymentCrypto.expiration_estimate_date}
        orderDescription={paymentCrypto.order_description}
        priceAmount={dataOrder.price}
        status={paymentCrypto.payment_status}
        payAmount={paymentCrypto.pay_amount}
        criptoCurrency={paymentCrypto.pay_currency}
        priceCurrency={paymentCrypto.price_currency}
        payAddress={paymentCrypto.pay_address}
        payFormatCurrencie={currencieFormat}
        popup={showPopup}
        toBroker={dataOrder.broker}
        packageSelected={dataOrder.name.split(".").join("_")}
        sendEmail={sendEmail}
        sendMarketingMessage={acceptTerms.second}
        sentEmail={() => {
          setSendEmail(true);
        }}
        setPopup={() => setShowModal(false)}
      />
    <div className="content-information-checkout">
    <h3 className=""> <FlagIcon/> Configura tus requerimientos y comienza tu Examen de binaryfunded</h3>

    </div>
      <section className="section-checkout">
        
        <div className="content-billing">
          {/*user ? (
            ""
          ) : (
            <div className="content-login-label">
              <p className="">¿Ya eres cliente?</p>
              <Link className="" to="/login">
                Haga clic aquí para iniciar sesión
              </Link>
            </div>
          )*/}

          

          <form className="form-billing">
            <CheckoutChoosePackages handleChangePackage={(info)=>{
              handleChangePackage({name:info.name,broker:info.broker,pay_amount:info.pay_amount, position_broker:info.position_broker})
              }}/>
            
          </form>
          
        </div>

        <div className="container-order-data">
          <div className="container-order-content">
            <form>
              <h3 className="title">Su pedido</h3>
              <div className="w-full mt-1">
                <p
                  className="text-order"
                  style={{ color: "white", fontWeight: "600" }}
                >
                 {dataOrder.description} <span>${dataOrder.price} </span>
                </p>
              
              </div>
            </form>
          </div>

          <div className="content-login-label content-coupon-label d-flex flex-column w-100">
            <div className="d-flex flex-column  align-items-start w-100">
              <p className="mb-2" style={{ fontWeight: "600" }}>
                ¿Tiene un cupón?
              </p>
              <div className="w-100 flex-wrap flex-lg-wrap flex-xl-nowrap gap-2 justify-content-between d-flex  align-items-center">
                <input
                  className="input-coupon col-12 col-lg-12 col-xl-7"
                  type="text"
                  placeholder="Escribe aquí tu código..."
                />
                <button
                  type="button"
                  onClick={() => {
                    alert("El cupón introducido no es valido!");
                  }}
                  className="button-send  m-auto m-sm-0 m-md-0 mt-2 mt-md-0 col-12 justify-content-center col-md-4 col-lg-12 col-xl-3"
                  style={{
                    height: "40px",
                    paddingTop: "10px",
                  }}
                >
                  Validar cupón
                </button>
              </div>
            </div>
          </div>

          <div className="container-order-content">
          <div className="container-input-field">
              <label
                style={{ color: "white", fontWeight: "semibold" }}
                htmlFor="country-region"
              >
                País/Región <span style={{ color: "#fa5745" }}>*</span>
              </label>
              <select
                id="country-region"
                name="country-region"
                className="select-inputs"
                defaultValue={customerInfo.country}
                onChange={(e) => {
                  handleSelectCountry(e);
                }}
              >
                <option style={{ color: "white" }} value="">
                  Selecciona una opcion
                </option>
                <option style={{ color: "white" }} value="GBR - United Kingdom">
                  United Kingdom
                </option>
                <option style={{ color: "white" }} value="AFG - Afghanistan">
                  Afghanistan
                </option>
                <option style={{ color: "white" }} value="ALA - Aland Islands">
                  Aland Islands
                </option>
                <option style={{ color: "white" }} value="ALB - Albania">
                  Albania
                </option>
                <option style={{ color: "white" }} value="DZA - Algeria">
                  Algeria
                </option>
                <option style={{ color: "white" }} value="ASM - American Samoa">
                  American Samoa
                </option>
                <option style={{ color: "white" }} value="AND - Andorra">
                  Andorra
                </option>
                <option style={{ color: "white" }} value="AGO - Angola">
                  Angola
                </option>
                <option style={{ color: "white" }} value="AIA - Anguilla">
                  Anguilla
                </option>
                <option style={{ color: "white" }} value="ATA - Antarctica">
                  Antarctica
                </option>
                <option
                  style={{ color: "white" }}
                  value="ATG - Antigua And Barbuda"
                >
                  Antigua And Barbuda
                </option>
                <option style={{ color: "white" }} value="ARG - Argentina">
                  Argentina
                </option>
                <option style={{ color: "white" }} value="ARM - Armenia">
                  Armenia
                </option>
                <option style={{ color: "white" }} value="ABW - Aruba">
                  Aruba
                </option>
                <option style={{ color: "white" }} value="AUS - Australia">
                  Australia
                </option>
                <option style={{ color: "white" }} value="AUT - Austria">
                  Austria
                </option>
                <option style={{ color: "white" }} value="AZE - Azerbaijan">
                  Azerbaijan
                </option>
                <option style={{ color: "white" }} value="BHS - Bahamas">
                  Bahamas
                </option>
                <option style={{ color: "white" }} value="BHR - Bahrain">
                  Bahrain
                </option>
                <option style={{ color: "white" }} value="BGD - Bangladesh">
                  Bangladesh
                </option>
                <option style={{ color: "white" }} value="BRB - Barbados">
                  Barbados
                </option>
                <option style={{ color: "white" }} value="BLR - Belarus">
                  Belarus
                </option>
                <option style={{ color: "white" }} value="BEL - Belgium">
                  Belgium
                </option>
                <option style={{ color: "white" }} value="BLZ - Belize">
                  Belize
                </option>
                <option style={{ color: "white" }} value="BEN - Benin">
                  Benin
                </option>
                <option style={{ color: "white" }} value="BMU - Bermuda">
                  Bermuda
                </option>
                <option style={{ color: "white" }} value="BTN - Bhutan">
                  Bhutan
                </option>
                <option style={{ color: "white" }} value="BOL - Bolivia">
                  Bolivia
                </option>
                <option
                  style={{ color: "white" }}
                  value="BIH - Bosnia_Herzegovina"
                >
                  Bosnia-Herzegovina
                </option>
                <option style={{ color: "white" }} value="BWA - Botswana">
                  Botswana
                </option>
                <option style={{ color: "white" }} value="BVT - Bouvet Island">
                  Bouvet Island
                </option>
                <option style={{ color: "white" }} value="BRA - Brazil">
                  Brazil
                </option>
                <option
                  style={{ color: "white" }}
                  value="VGB - British Virgin Islands"
                >
                  British Virgin Islands
                </option>
                <option
                  style={{ color: "white" }}
                  value="BRN - Brunei Darussalam"
                >
                  Brunei Darussalam
                </option>
                <option style={{ color: "white" }} value="BGR - Bulgaria">
                  Bulgaria
                </option>
                <option style={{ color: "white" }} value="BFA - Burkina Faso">
                  Burkina Faso
                </option>
                <option style={{ color: "white" }} value="BDI - Burundi">
                  Burundi
                </option>
                <option style={{ color: "white" }} value="KHM - Cambodia">
                  Cambodia
                </option>
                <option style={{ color: "white" }} value="CMR - Cameroon">
                  Cameroon
                </option>
                <option style={{ color: "white" }} value="CAN - Canada">
                  Canada
                </option>
                <option
                  style={{ color: "white" }}
                  value="CPV - Cape Verde Islands"
                >
                  Cape Verde Islands
                </option>
                <option style={{ color: "white" }} value="CYM - Cayman Islands">
                  Cayman Islands
                </option>
                <option
                  style={{ color: "white" }}
                  value="CAF - Central African Republic"
                >
                  Central African Republic
                </option>
                <option style={{ color: "white" }} value="TCD - Chad">
                  Chad
                </option>
                <option style={{ color: "white" }} value="CHL - Chile">
                  Chile
                </option>
                <option style={{ color: "white" }} value="CHN - China (PR)">
                  China (PR)
                </option>
                <option
                  style={{ color: "white" }}
                  value="CXR - Christmas Island"
                >
                  Christmas Island
                </option>
                <option
                  style={{ color: "white" }}
                  value="CCK - Cocos (Keeling) Islands"
                >
                  Cocos (Keeling) Islands
                </option>
                <option style={{ color: "white" }} value="COL - Colombia">
                  Colombia
                </option>
                <option style={{ color: "white" }} value="COM - Comoros">
                  Comoros
                </option>
                <option
                  style={{ color: "white" }}
                  value="COD - Congo, Democratic Republic of"
                >
                  Congo, Democratic Republic of
                </option>
                <option
                  style={{ color: "white" }}
                  value="COG - Congo, Republic of"
                >
                  Congo, Republic of
                </option>
                <option style={{ color: "white" }} value="COK- Cook Islands">
                  Cook Islands
                </option>
                <option style={{ color: "white" }} value="CRI - Costa Rica">
                  Costa Rica
                </option>
                <option style={{ color: "white" }} value="HRV - Croatia">
                  Croatia
                </option>
                <option style={{ color: "white" }} value="CUB - Cuba">
                  Cuba
                </option>
                <option style={{ color: "white" }} value="CYP - Cyprus">
                  Cyprus
                </option>
                <option style={{ color: "white" }} value="CZE - Czech Republic">
                  Czech Republic
                </option>
                <option style={{ color: "white" }} value="DNK - Denmark">
                  Denmark
                </option>
                <option style={{ color: "white" }} value="DJI - Djibouti">
                  Djibouti
                </option>
                <option style={{ color: "white" }} value="DMA - Dominica">
                  Dominica
                </option>
                <option
                  style={{ color: "white" }}
                  value="DOM - Dominican Republic"
                >
                  Dominican Republic
                </option>
                <option style={{ color: "white" }} value="ECU - Ecuador">
                  Ecuador
                </option>
                <option style={{ color: "white" }} value="EGY - Egypt">
                  Egypt
                </option>
                <option style={{ color: "white" }} value="SLV - El Salvador">
                  El Salvador
                </option>
                <option
                  style={{ color: "white" }}
                  value="GNQ - Equatorial Guinea"
                >
                  Equatorial Guinea
                </option>
                <option style={{ color: "white" }} value="ERI - Eritrea">
                  Eritrea
                </option>
                <option style={{ color: "white" }} value="EST - Estonia">
                  Estonia
                </option>
                <option style={{ color: "white" }} value="ETH - Ethiopia">
                  Ethiopia
                </option>
                <option
                  style={{ color: "white" }}
                  value="FLK - Falkland Islands"
                >
                  Falkland Islands
                </option>
                <option style={{ color: "white" }} value="FRO - Faroe Islands">
                  Faroe Islands
                </option>
                <option style={{ color: "white" }} value="FJI - Fiji">
                  Fiji
                </option>
                <option style={{ color: "white" }} value="FIN - Finland">
                  Finland
                </option>
                <option style={{ color: "white" }} value="FRA - France">
                  France
                </option>
                <option style={{ color: "white" }} value="GUF - French Guyana">
                  French Guyana
                </option>
                <option
                  style={{ color: "white" }}
                  value="PYF - French Polynesia"
                >
                  French Polynesia
                </option>
                <option
                  style={{ color: "white" }}
                  value="ATF - French Southern Territories"
                >
                  French Southern Territories
                </option>
                <option style={{ color: "white" }} value="GAB - Gabon">
                  Gabon
                </option>
                <option style={{ color: "white" }} value="GMB - Gambia">
                  Gambia
                </option>
                <option style={{ color: "white" }} value="GEO - Georgia">
                  Georgia
                </option>
                <option style={{ color: "white" }} value="DEU - Germany">
                  Germany
                </option>
                <option style={{ color: "white" }} value="GHA - Ghana">
                  Ghana
                </option>
                <option style={{ color: "white" }} value="GIB - Gibraltar">
                  Gibraltar
                </option>
                <option style={{ color: "white" }} value="GRC - Greece">
                  Greece
                </option>
                <option style={{ color: "white" }} value="GRL - Greenland">
                  Greenland
                </option>
                <option style={{ color: "white" }} value="GRD - Grenada">
                  Grenada
                </option>
                <option style={{ color: "white" }} value="GLP - Guadeloupe">
                  Guadeloupe
                </option>
                <option style={{ color: "white" }} value="GUM - Guam">
                  Guam
                </option>
                <option style={{ color: "white" }} value="GTM - Guatemala">
                  Guatemala
                </option>
                <option style={{ color: "white" }} value="GGY - Guernsey">
                  Guernsey
                </option>
                <option style={{ color: "white" }} value="GIN - Guinea">
                  Guinea
                </option>
                <option style={{ color: "white" }} value="GNB - Guinea Bissau">
                  Guinea-Bissau
                </option>
                <option style={{ color: "white" }} value="GUY - Guyana">
                  Guyana
                </option>
                <option style={{ color: "white" }} value="HTI - Haiti">
                  Haiti
                </option>
                <option
                  style={{ color: "white" }}
                  value="HMD - Heard Island and McDonald Islands"
                >
                  Heard Island and McDonald Islands
                </option>
                <option
                  style={{ color: "white" }}
                  value="VAT - Holy See (Vatican City State)"
                >
                  Holy See (Vatican City State)
                </option>
                <option style={{ color: "white" }} value="HND - Honduras">
                  Honduras
                </option>
                <option style={{ color: "white" }} value="HKG - Hong Kong">
                  Hong Kong
                </option>
                <option style={{ color: "white" }} value="HUN - Hungary">
                  Hungary
                </option>
                <option style={{ color: "white" }} value="ISL - Iceland">
                  Iceland
                </option>
                <option style={{ color: "white" }} value="IND - India">
                  India
                </option>
                <option style={{ color: "white" }} value="IDN - Indonesia">
                  Indonesia
                </option>
                <option style={{ color: "white" }} value="IRN - Iran">
                  Iran
                </option>
                <option style={{ color: "white" }} value="IRQ - Iraq">
                  Iraq
                </option>
                <option
                  style={{ color: "white" }}
                  value="IRL - Ireland (Rep. Of)"
                >
                  Ireland (Rep. Of)
                </option>
                <option style={{ color: "white" }} value="IMN - sle of Man">
                  Isle of Man
                </option>
                <option style={{ color: "white" }} value="ISR - Israel">
                  Israel
                </option>
                <option style={{ color: "white" }} value="ITA - Italy">
                  Italy
                </option>
                <option style={{ color: "white" }} value="CIV - Ivory Coast">
                  Ivory Coast
                </option>
                <option style={{ color: "white" }} value="JAM - Jamaica">
                  Jamaica
                </option>
                <option style={{ color: "white" }} value="JPN - Japan">
                  Japan
                </option>
                <option style={{ color: "white" }} value="JEY - Jersey">
                  Jersey
                </option>
                <option style={{ color: "white" }} value="JOR - Jordan">
                  Jordan
                </option>
                <option style={{ color: "white" }} value="KAZ - Kazakhstan">
                  Kazakhstan
                </option>
                <option style={{ color: "white" }} value="KEN - Kenya">
                  Kenya
                </option>
                <option style={{ color: "white" }} value="KIR - Kiribati">
                  Kiribati
                </option>
                <option style={{ color: "white" }} value="PRK - Korea (North)">
                  Korea (North)
                </option>
                <option style={{ color: "white" }} value="KOR - Korea (South)">
                  Korea (South)
                </option>
                <option style={{ color: "white" }} value="KWT - Kuwait">
                  Kuwait
                </option>
                <option style={{ color: "white" }} value="KGZ - Kyrgyzstan">
                  Kyrgyzstan
                </option>
                <option style={{ color: "white" }} value="LAO - Laos">
                  Laos
                </option>
                <option style={{ color: "white" }} value="LVA - Latvia">
                  Latvia
                </option>
                <option style={{ color: "white" }} value="LBN - Lebano">
                  Lebanon
                </option>
                <option style={{ color: "white" }} value="LSO - Lesotho">
                  Lesotho
                </option>
                <option style={{ color: "white" }} value="LBR - Liberia">
                  Liberia
                </option>
                <option style={{ color: "white" }} value="LBY - Libya">
                  Libya
                </option>
                <option style={{ color: "white" }} value="LIE - Liechtenstein">
                  Liechtenstein
                </option>
                <option style={{ color: "white" }} value="LTU - Lithuania">
                  Lithuania
                </option>
                <option style={{ color: "white" }} value="LUX - Luxembourg">
                  Luxembourg
                </option>
                <option style={{ color: "white" }} value="MAC - Macao">
                  Macao
                </option>
                <option
                  style={{ color: "white" }}
                  value="MKD - Macedonia, the Former Yugoslav Republic of"
                >
                  Macedonia, the Former Yugoslav Republic of
                </option>
                <option style={{ color: "white" }} value="MDG - Madagascar">
                  Madagascar
                </option>
                <option style={{ color: "white" }} value="MWI - Malawi">
                  Malawi
                </option>
                <option style={{ color: "white" }} value="MYS - Malaysia">
                  Malaysia
                </option>
                <option style={{ color: "white" }} value="MDV - Maldives">
                  Maldives
                </option>
                <option style={{ color: "white" }} value="MLI - Mali">
                  Mali
                </option>
                <option style={{ color: "white" }} value="MLT - Malta">
                  Malta
                </option>
                <option
                  style={{ color: "white" }}
                  value="MHL - Marshall Islands"
                >
                  Marshall Islands
                </option>
                <option style={{ color: "white" }} value="MTQ - Martinique">
                  Martinique
                </option>
                <option style={{ color: "white" }} value="MRT - Mauritania">
                  Mauritania
                </option>
                <option style={{ color: "white" }} value="MUS - Mauritius">
                  Mauritius
                </option>
                <option style={{ color: "white" }} value="MYT - Mayotte">
                  Mayotte
                </option>
                <option style={{ color: "white" }} value="MEX - Mexico">
                  Mexico
                </option>
                <option
                  style={{ color: "white" }}
                  value="FSM - Micronesia, Federated States of"
                >
                  Micronesia, Federated States of
                </option>
                <option style={{ color: "white" }} value="MDA - Moldova">
                  Moldova
                </option>
                <option style={{ color: "white" }} value="MCO - Monaco">
                  Monaco
                </option>
                <option style={{ color: "white" }} value="MNG - Mongolia">
                  Mongolia
                </option>
                <option style={{ color: "white" }} value="MNE - Montenegro">
                  Montenegro
                </option>
                <option style={{ color: "white" }} value="MSR - Montserrat">
                  Montserrat
                </option>
                <option style={{ color: "white" }} value="MAR - Morocco">
                  Morocco
                </option>
                <option style={{ color: "white" }} value="MOZ - Mozambique">
                  Mozambique
                </option>
                <option style={{ color: "white" }} value="MMR - Myanmar">
                  Myanmar
                </option>
                <option style={{ color: "white" }} value="NAM - Namibia">
                  Namibia
                </option>
                <option style={{ color: "white" }} value="NPL - Nepal">
                  Nepal
                </option>
                <option style={{ color: "white" }} value="NLD - Netherlands">
                  Netherlands
                </option>
                <option
                  style={{ color: "white" }}
                  value="ANT - Netherlands Antilles"
                >
                  Netherlands Antilles
                </option>
                <option style={{ color: "white" }} value="NCL - New Caledonia">
                  New Caledonia
                </option>
                <option style={{ color: "white" }} value="NZL - New Zealand">
                  New Zealand
                </option>
                <option style={{ color: "white" }} value="NIC - Nicaragua">
                  Nicaragua
                </option>
                <option style={{ color: "white" }} value="NER - Niger">
                  Niger
                </option>
                <option style={{ color: "white" }} value="NGA - Nigeria">
                  Nigeria
                </option>
                <option style={{ color: "white" }} value="NIU - Niue">
                  Niue
                </option>
                <option style={{ color: "white" }} value="NFK - Norfolk Island">
                  Norfolk Island
                </option>
                <option
                  style={{ color: "white" }}
                  value="MNP - Northern Mariana Islands"
                >
                  Northern Mariana Islands
                </option>
                <option style={{ color: "white" }} value="NOR - Norway">
                  Norway
                </option>
                <option style={{ color: "white" }} value="OMN - Oman">
                  Oman
                </option>
                <option style={{ color: "white" }} value="PAK - Pakistan">
                  Pakistan
                </option>
                <option style={{ color: "white" }} value="PLW - Palau">
                  Palau
                </option>
                <option
                  style={{ color: "white" }}
                  value="PSE - Palestinian territory"
                >
                  Palestinian territory
                </option>
                <option style={{ color: "white" }} value="PAN - Panama">
                  Panama
                </option>
                <option
                  style={{ color: "white" }}
                  value="PNG - Papua New Guinea"
                >
                  Papua New Guinea
                </option>
                <option style={{ color: "white" }} value="PRY - Paraguay">
                  Paraguay
                </option>
                <option style={{ color: "white" }} value="PER - Peru">
                  Peru
                </option>
                <option style={{ color: "white" }} value="PHL - Philippines">
                  Philippines
                </option>
                <option style={{ color: "white" }} value="PCN - Pitcairn">
                  Pitcairn
                </option>
                <option style={{ color: "white" }} value="POL - Poland">
                  Poland
                </option>
                <option style={{ color: "white" }} value="PRT - Portugal">
                  Portugal
                </option>
                <option style={{ color: "white" }} value="PRI - Puerto Rico">
                  Puerto Rico
                </option>
                <option style={{ color: "white" }} value="QAT - Qatar">
                  Qatar
                </option>
                <option style={{ color: "white" }} value="REU - Reunion">
                  Reunion
                </option>
                <option style={{ color: "white" }} value="ROU - Romania">
                  Romania
                </option>
                <option style={{ color: "white" }} value="RUS - Russia">
                  Russia
                </option>
                <option style={{ color: "white" }} value="RWA - Rwanda">
                  Rwanda
                </option>
                <option style={{ color: "white" }} value="SHN - Saint Helena">
                  Saint Helena
                </option>
                <option
                  style={{ color: "white" }}
                  value="SPM - Saint Pierre and Miquelon"
                >
                  Saint Pierre and Miquelon
                </option>
                <option style={{ color: "white" }} value="SMR - San Marino">
                  San Marino
                </option>
                <option
                  style={{ color: "white" }}
                  value="STP - Sao Tome E Principe"
                >
                  Sao Tome E Principe
                </option>
                <option style={{ color: "white" }} value="SAU - Saudi Arabia">
                  Saudi Arabia
                </option>
                <option style={{ color: "white" }} value="SEN - Senegal">
                  Senegal
                </option>
                <option style={{ color: "white" }} value="SRB - Senegal">
                  Serbia
                </option>
                <option style={{ color: "white" }} value="SYC - Seychelles">
                  Seychelles
                </option>
                <option style={{ color: "white" }} value="SLE - Sierra Leone">
                  Sierra Leone
                </option>
                <option style={{ color: "white" }} value="SGP - Singapore">
                  Singapore
                </option>
                <option style={{ color: "white" }} value="SVK - Slovakia">
                  Slovakia
                </option>
                <option style={{ color: "white" }} value="SVN - Slovenia">
                  Slovenia
                </option>
                <option
                  style={{ color: "white" }}
                  value="SLB - Solomon Islands"
                >
                  Solomon Islands
                </option>
                <option style={{ color: "white" }} value="SOM - Somalia">
                  Somalia
                </option>
                <option style={{ color: "white" }} value="ZAF - South Africa">
                  South Africa
                </option>
                <option
                  style={{ color: "white" }}
                  value="SGS - South Georgia and the South Sandwich Islands"
                >
                  South Georgia and the South Sandwich Islands
                </option>
                <option style={{ color: "white" }} value="ESP - Spain">
                  Spain
                </option>
                <option style={{ color: "white" }} value="LKA - Sri Lanka">
                  Sri Lanka
                </option>
                <option
                  style={{ color: "white" }}
                  value="KNA - St. Kitts And Nevis"
                >
                  St. Kitts And Nevis
                </option>
                <option style={{ color: "white" }} value="LCA - St. Lucia">
                  St. Lucia
                </option>
                <option
                  style={{ color: "white" }}
                  value="VCT - St. Vincent and the Grenadines"
                >
                  St. Vincent and the Grenadines
                </option>
                <option style={{ color: "white" }} value="SDN - Sudan">
                  Sudan
                </option>
                <option style={{ color: "white" }} value="SUR - Suriname">
                  Suriname
                </option>
                <option
                  style={{ color: "white" }}
                  value="SJM - Svalbard and Jan Mayen"
                >
                  Svalbard and Jan Mayen
                </option>
                <option style={{ color: "white" }} value="SWZ - Swaziland">
                  Swaziland
                </option>
                <option style={{ color: "white" }} value="SWE - Sweden">
                  Sweden
                </option>
                <option style={{ color: "white" }} value="CHE - Switzerland">
                  Switzerland
                </option>
                <option style={{ color: "white" }} value="SYR - Syria">
                  Syria
                </option>
                <option style={{ color: "white" }} value="TWN - Taiwan (ROC)">
                  Taiwan (ROC)
                </option>
                <option style={{ color: "white" }} value="TJK - Tajikistan">
                  Tajikistan
                </option>
                <option style={{ color: "white" }} value="TZA - Tanzania">
                  Tanzania
                </option>
                <option style={{ color: "white" }} value="THA - Thailand">
                  Thailand
                </option>
                <option style={{ color: "white" }} value="TLS - Timor-Leste">
                  Timor-Leste
                </option>
                <option style={{ color: "white" }} value="TGO - Togo">
                  Togo
                </option>
                <option style={{ color: "white" }} value="TKL - Togo">
                  Tokelau
                </option>
                <option style={{ color: "white" }} value="TON - Tonga">
                  Tonga
                </option>
                <option
                  style={{ color: "white" }}
                  value="TTO - Trinidad And Tobago"
                >
                  Trinidad And Tobago
                </option>
                <option style={{ color: "white" }} value="TUN - Tunisia">
                  Tunisia
                </option>
                <option style={{ color: "white" }} value="TUR - Turkey">
                  Turkey
                </option>
                <option style={{ color: "white" }} value="TKM - Turkmenistan">
                  Turkmenistan
                </option>
                <option
                  style={{ color: "white" }}
                  value="TCA - Turks and Caicos Islands"
                >
                  Turks and Caicos Islands
                </option>
                <option style={{ color: "white" }} value="TUV - Tuvalu">
                  Tuvalu
                </option>
                <option
                  style={{ color: "white" }}
                  value="VIR - US Virgin Islands"
                >
                  US Virgin Islands
                </option>
                <option style={{ color: "white" }} value="UGA - Uganda">
                  Uganda
                </option>
                <option style={{ color: "white" }} value="UKR - Ukraine">
                  Ukraine
                </option>
                <option
                  style={{ color: "white" }}
                  value="ARE - United Arab Emirates"
                >
                  United Arab Emirates
                </option>
                <option
                  style={{ color: "white" }}
                  value="USA - United States of America"
                >
                  United States of America
                </option>
                <option style={{ color: "white" }} value="URY - Uruguay">
                  Uruguay
                </option>
                <option style={{ color: "white" }} value="UZB - Uzbekistan">
                  Uzbekistan
                </option>
                <option style={{ color: "white" }} value="VUT - Vanuatu">
                  Vanuatu
                </option>
                <option style={{ color: "white" }} value="VEN - Venezuela">
                  Venezuela
                </option>
                <option style={{ color: "white" }} value="VNM - Vietnam">
                  Vietnam
                </option>
                <option
                  style={{ color: "white" }}
                  value="WLF - Wallis and Futuna"
                >
                  Wallis and Futuna
                </option>
                <option style={{ color: "white" }} value="ESH - Western Sahara">
                  Western Sahara
                </option>
                <option style={{ color: "white" }} value="WSM - Western Samoa">
                  Western Samoa
                </option>
                <option style={{ color: "white" }} value="YEM - Yemen">
                  Yemen
                </option>
                <option style={{ color: "white" }} value="ZMB - Zambia">
                  Zambia
                </option>
                <option style={{ color: "white" }} value="ZWE - Zimbabwe">
                  Zimbabwe
                </option>
              </select>
            </div>

            <div className="container-paymethods">
              <h4 className="">Métodos de pago</h4>
              <Select
                options={payOptions}
                styles={selectStylePayments}
                onChange={(e) => {
                  //console.log(e);
                  if(e.disabled){ 
                    return
                  }
                  setPaymentMethod(e.value);
                }}
                defaultValue={paymentMethod}
                components={{ Option: IconOption, SingleValue: ValueOption }}
                placeholder={
                  <div>
                    {payOptions[0].label}{" "}
                    {payOptions[0].icons
                      ? payOptions[0].icons.map((el,index) => {
                          return (
                            <img
                              key={index}
                              src={el}
                              style={{
                                objectFit: "contain",
                                height: 22,
                                marginLeft: "7px",
                                marginTop: "-3px",
                              }}
                              alt={payOptions[0].label}
                            />
                          );
                        })
                      : ""}
                  </div>
                }
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    //after select dropdown option
                    primary50: "white",

                    //Text color
                    neutral80: "#F4FFFD",
                  },
                })}
              />
              {paymentMethod === "card" && <CardsCheckout country={customerInfo.country}/>}

              {paymentMethod === "cripto-currency" && (
                <div className="form-crypto">
                  <div className="container-input-field">
                    <label className="" htmlFor="price-currency">
                      Moneda de pago
                    </label>

                    <Select
                      options={currencies}
                      styles={selectStyle}
                      onChange={handleCurrencySelected}
                      defaultValue={criptoTransaction.pay_currency}
                      placeholder={<div>Elige una moneda</div>}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          //after select dropdown option
                          primary50: "white",

                          //Text color
                          neutral80: "#F4FFFD",
                        },
                      })}
                    />
                  </div>
                </div>
              )}



              {paymentMethod === "cripto-currency" && (
                <button
                  type="button"
                  onClick={() => {
                    handleFormPayment();
                  }}
                  className="button-send"
                >
                  Realizar pedido
                </button>
              )}

              {paymentMethod === "skrill" && (
                <div className="form-skrill">
                  <SkrillCheckout
                    acceptTerms={acceptTerms}
                    price={dataOrder.price}
                    customerInfo={customerInfo}
                    toPackage={dataOrder.package.split(".").join("_")}
                    toBroker={dataOrder.broker}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckoutPage;
