import * as React from "react"
const ContactIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={26}
    fill="none"
    {...props}
  >
    <path
      fill="#b0ef96"
      d="M11.623 21.707H7.372c-.564 0-1.032-.452-1.032-.997 0-.545.468-.997 1.032-.997h4.251c.564 0 1.032.452 1.032.997 0 .545-.468.997-1.032.997Z"
    />
    <path
      fill="#b0ef96"
      d="M14.707 26H4.293C1.926 26 0 24.152 0 21.866V4.147C0 1.861 1.926 0 4.293 0h10.415C17.073 0 19 1.86 19 4.147v17.706c0 2.3-1.926 4.147-4.293 4.147ZM4.293 2.658c-.853 0-1.541.665-1.541 1.49v17.705c0 .824.688 1.488 1.54 1.488h10.416c.852 0 1.54-.664 1.54-1.488V4.147c0-.824-.701-1.489-1.54-1.489H4.293Z"
    />
  </svg>
)
export default ContactIcon