import { useEffect,useState,useRef } from "react";
import { STATUS_ACCOUNT } from "../../utils/index";
import ModalAccountInfo from "../Modals/ModalAccountInfo";
import "../AccountInformation/account-information.css";
import { Link } from "react-router-dom";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import { Line } from "react-chartjs-2";
import HouseIcon from "../icons/HouseIcon";
import CrossIcon from "../../assets/icons/cross.png";
import CheckIcon from "../../assets/icons/accept.png";
import KeyIcon from "../../assets/icons/key.png";
import WithdrawIcon from "../icons/WithdrawIcon";
import { color, isArray } from "chart.js/helpers";
import { onAuthStateChanged } from "firebase/auth";
import {TYPE_ACCOUNT_FUNDED} from "../../utils/index"

import AccountWithdraw from "./AccountWithdraw";
const AccountReal = (props) =>{
defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.color = "white";

useEffect(()=>{
  
    console.log(props.account)
},[props])

const [quotexField,setQuotexField] = useState({
  created:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.created : true,
  active:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.active : true,
  amount:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.amount: true,
  direction:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.direction : true,
  status:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.status : true,
  expired:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.expired : true,
  balance:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.balance : true
})
const toAsterisk = (value) =>{
  return value.substring(0, Math.min(value.length, 10)).split("").map(function(char) {
    return char === " " ? " " : "*";
  }).join("")
}
const changeField = (field) =>{
setQuotexField({...quotexField,[field]:!quotexField[field]})
}

useEffect(()=>{
  localStorage.setItem("quotexField",JSON.stringify(quotexField))
},[quotexField])


const formatDate = (date) => {
  //format the date to show in the table operations iqoption
  return `${new Date(date * 1000).getFullYear()}/${
    new Date(date * 1000).getMonth() + 1 <= 9
      ? "0" + (new Date(date * 1000).getMonth() + 1)
      : new Date(date * 1000).getMonth() + 1
  }/${
    new Date(date * 1000).getUTCDate() <= 9
      ? "0" + new Date(date * 1000).getUTCDate()
      : new Date(date * 1000).getUTCDate()
  } - ${
    new Date(date * 1000).getHours() <= 9
      ? "0" + new Date(date * 1000).getHours()
      : new Date(date * 1000).getHours()
  }:${
    new Date(date * 1000).getMinutes() <= 9
      ? "0" + new Date(date * 1000).getMinutes()
      : new Date(date * 1000).getMinutes()
  }`;
};



    const [dataChart,setDataChart] = useState([])

    const openModal = () => {
      setShowMod(true);
    };
  
    const closeModal = () => {
      setShowMod(false);
    };
  
  
    const chartRef = useRef(null);
    const [showMod, setShowMod] = useState(false);
    const [load, setLoad] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [activateButton, setActivateButton] = useState(false);
  
    const [modalInfo, setModalInfo] = useState({
      title: "",
      email: ``,
      password: "",
    });
  
    const [offSetList, setoffSetList] = useState(0);
    const [uid, setUid] = useState("");
  

  
    //Calculate the min balance in the chart
    const calculateMin = () =>{
      let min = Math.round((dataChart.reduce((min, obj) => {
        return obj.balance < min ? obj.balance : min;
      }, Infinity) - 2000) / 1000) * 1000
      return min
    }
  
  
    //Calculate the max balance in the chart
    const calculateMax = () =>{
      let max = Math.round((dataChart.reduce((max, obj) => {
        return obj.balance > max ? obj.balance : max;
      }, -Infinity) + 2000) / 1000) * 1000
      return max
    }
  
    
    const calculateGraphic = (history_operations) => {
        if(!props.account.created_at) return
        let initialFunds = parseFloat(TYPE_ACCOUNT_FUNDED[props.account.package]);
        let balance = initialFunds
        const operations_accounts = [...history_operations]
        let operations_withdraw = [...props.account.history_withdraw]
        
       
        
        let array_data = []
        //Add the initial balance
        array_data.push(({label: new Date(props.account.created_at).toISOString().split("T").join("@").split(".")[0], balance: initialFunds}))
        if(props.account.server === "quotex"){
            //Operations withdraw formatted to quotex
        operations_withdraw = operations_withdraw.map((el)=>{
            return{
                amount: el.amount,
                close_date: el.date,
                id:el.id,
                status:el.status,
                address:el.address,
                is_withdraw: true
            }
        })
        const arrayOperations = operations_accounts.concat(operations_withdraw)
        
          arrayOperations.sort((elm)=>{
            return new Date(elm.close_date) - new Date(elm.close_date)
          }).forEach((operation,i) => {
            if(operation.is_withdraw){
                //Show the amount after of the withdraw
                array_data.push({ label: operation.close_date.split(" ").join("@"), balance: Number(balance - operation.amount) });
                return
            }
            if(operation.income && Number(operation.income) === 0){
               balance = balance - parseFloat(operation.amount);
            }
    
            if(operation.income && Number(operation.income) > 0){
              if(props.account.order_id === "54697213575"){ // for static data
                balance = balance + parseFloat(operation.income);
              }else{
                balance = (balance - parseFloat(operation.amount)) + parseFloat(operation.income);
              }
            }
            // Add the balance to the array
            array_data.push({ label: operation.close_date.split(" ").join("@"), balance: balance });
          })
          if(arrayOperations.length === 0){
          array_data.push({label: new Date(props.account.updated_at).toISOString().split("T").join("@").split(".")[0], balance: parseFloat(props.account.balance_actual_real)})
          }
          setDataChart(array_data)
        }
    
        // Set the data for the chart in iqoption
        if(props.account.server === "iqoption"){
               //Operations withdraw formatted to iqoption
        operations_withdraw = operations_withdraw.map((el)=>{
            return{
                amount: el.amount,
                expired: new Date(el.date).getTime() / 1000,
                id:el.id,
                status:el.status,
                address:el.address,
                is_withdraw: true
            }
        })
        const arrayOperations = operations_accounts.concat(operations_withdraw)
        arrayOperations.map((elm)=>{
            return {...elm, expired:formatDate(elm.expired)}
        }).sort((elm)=>{
            return new Date(elm.expired) - new Date(elm.expired)
        }).reverse().forEach((operation) => {
            if(operation.is_withdraw){
                array_data.push({label:  operation.expired, balance: balance})
                return
            }
            if(props.account.order_id === "54697213575"){ // for static data
              balance = (operation.win === "win" ? balance + parseFloat(operation.win_amount) : balance - parseFloat(operation.amount))
            }else{
              balance = (operation.win === "win" ? ((balance - parseFloat(operation.amount)) + parseFloat(operation.win_amount)) : balance - parseFloat(operation.amount))
            }
            
              array_data.push({label: operation.expired, balance: balance})
          })
    
          //array_data.push({label: new Date().toISOString().split("T").join("@").split(".")[0], balance: parseFloat(props.account.balance_actual_real)})
    
          setDataChart(array_data)
        }
    
    }

    const [offSetListWithdraw, setoffSetListWithdraw] = useState(0);
    
    const handleNextWithdraw = () => {
        if (offSetListWithdraw + 10 < props.account.history_withdraw.length) {
            setoffSetListWithdraw(offSetListWithdraw + 10);
        }
      };
    
      const handlePrevWithdraw = () => {
        if (offSetListWithdraw > 0) {
            setoffSetListWithdraw(offSetListWithdraw - 10);
        }
      };
    

    useEffect(() => {
        calculateGraphic(props.account.history_all);
        
      }, [props.account]);
    
      
    
      const handleNext = () => {
        if (offSetList + 10 < props.account.history_all.length) {
          setoffSetList(offSetList + 10);
        }
      };
    
      const handlePrev = () => {
        if (offSetList > 0) {
          setoffSetList(offSetList - 10);
        }
      };
    
    
      return (
        <>
          <ModalAccountInfo
            show={showMod}
            user={modalInfo}
            handleClose={closeModal}
          />
          <div className="d-flex flex-column container-content-app container-account-details">
          { !props.isAdmin ? <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">
                  <HouseIcon /> Inicio
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/dashboard">Mis cuentas fondeadas</Link>
              </li>
              <li className="breadcrumb-item active">
                Cuenta - #{props.account.order_id}
              </li>
            </ul>
          :
            <ul className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to="#">
                    <HouseIcon /> Inicio
                </Link>
                </li>
                <li className="breadcrumb-item">Soporte</li>
                <li className="breadcrumb-item">
                    <Link to={'/admin/users'}>Usuarios</Link></li>
                    <li className="breadcrumb-item">
                    <Link to={'/admin/user/' + props.isAdmin}>#{props.isAdmin}</Link></li>
                <li className="breadcrumb-item active">Cuenta - #{props.account.order_id}</li>
            </ul> }
      
              <>
                <div className="d-flex flex-column flex-lg-row justify-content-lg-between mb-3">
                  <div className="col-12 col-lg-5">
                    <p className="m-0 w-100 text-white">
                      Cuenta{" "}
                      <span>
                        {
                          props.account.package || ''
                        }
                      </span>{" "}
                      -{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {props.account.server}
                      </span>{" "}
                      - #{props.account.order_id}
                    </p>
                  </div>
                  <div className="ms-3 ms-lg-0 mt-2 mt-lg-0 col-12 col-lg-7  d-flex flex-wrap align-items-center justify-content-lg-end gap-3">
                    <span className={`status account-${props.account.status}`}>
                      {STATUS_ACCOUNT[props.account.status]}
                    </span>
                    <button
                      className="btn-credencials"
                      type="button"
                      onClick={() => {
                        openModal();
                        setModalInfo({
                          title: "Informacion de la cuenta",
                          email: `${props.account.email}`,
                          password: `${props.account.password}`,
                          server: `${props.account.server}`,
                        });
                      }}
                    >
                      <img src={KeyIcon} className="icon-btn" alt="key-icon" />
                      Credenciales
                    </button>
                    
                    {props.account.status === "active" ? <Link style={{opacity: activateButton ? '1' : '0.6'}} className="btn-credencials"  to="#">
                    <WithdrawIcon  style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "3px",
                        }}/>
                    Retirar
                    </Link> : ''} 
                    
                  </div>
                </div>
                <div className="row flex-column-reverse flex-lg-row gap-3 gap-lg-0">
                  <div className="col-lg-8 col-12">
                    <section>
                        <AccountWithdraw offSetList={offSetListWithdraw} withdraws={props.account.history_withdraw || []} handlePrev={handlePrevWithdraw} handleNext={handleNextWithdraw}/>
                    </section>
                  </div>
    
                  <div className="col-lg-4 col-12 p-lg-0">
                    <div className="d-flex flex-column align-items-start text-white container-account">
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Tamaño de la cuenta</strong>
                          </p>
                          <p className="m-0">
                            $
                            {Number(TYPE_ACCOUNT_FUNDED[props.account.package]).toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </p>
                        </div>
                      </div>
    
                      
    
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Tipo de cuenta</strong>
                          </p>
                          <p className="m-0" style={{ textTransform: "capitalize" }}>{props.account.server}</p>
                        </div>
                      </div>

                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Balance actual <button style={{border:'none', background:'transparent', outline:'0'}} onClick={()=>{changeField('balance')}} type="button">{quotexField.balance ? <FaEye style={{fill:'white'}}/>: <FaEyeSlash style={{fill:'white'}}/>}</button> </strong>
                          </p>
                          
                          <p className="m-0">
                           {quotexField.balance ? '$' +
                            Number(props.account.balance_actual_real)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".") : toAsterisk(String(props.account.balance_actual_real)) }
                          </p>
                        </div>
                      </div>
    
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Fecha de creacion</strong>
                          </p>
                          <p className="m-0">
                            {new Date(props.account.created_at).toString() !==
                            "Invalid Date"
                              ? new Date(props.account.created_at)
                                  .toISOString()
                                  .slice(0, 10)
                                  .replaceAll("-", "/")
                              : ""}
                          </p>
                        </div>
                      </div>
    
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Ultima actualizacion</strong>
                          </p>
                          <p className="m-0">
                            {props.account.updated_at
                              ? new Date(props.account.updated_at).toString() !==
                                "Invalid Date"
                                ? new Date(props.account.updated_at)
                                    .toISOString()
                                    .slice(0, 10)
                                    .replaceAll("-", "/")
                                : ""
                              : ""}
    
                            {!props.account.updated_at
                              ? new Date(props.account.created_at).toString() !==
                                "Invalid Date"
                                ? new Date(props.account.created_at)
                                    .toISOString()
                                    .slice(0, 10)
                                    .replaceAll("-", "/")
                                : ""
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
              { dataChart.length !== 0 ? <> <h3 className="title">Estado del balance</h3>
                <div
                  className="container-chart mt-2"
                  style={{
                    height: "380px !important",
                    minHeight: "380px",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <Line
                    data={{
                      labels: dataChart.map((data) => data.label),
                      datasets: [
                        {
                          label: "",
                          data: Array.from({ length: dataChart.length }, (_, i) => parseFloat(TYPE_ACCOUNT_FUNDED[props.account.package])),
                          backgroundColor: "rgba(176,239,150,0.9)",
                          borderColor: "rgba(176,239,150,1)",
                          pointStyle: "rectRounded",
                          radius: 0,
                        },
                        {
                          label: "Balance",
                          data: dataChart.map((data) => data.balance),
                          backgroundColor: "rgba(75,192,192,0.9)",
                          borderColor: "rgba(75,192,192,1)",
                          radius:5
                        },
                        
                      ],
                    }}
                    options={{
                      color: "white",
                      plugins: {
                        legend: {
                          color: "#fff",
                          labels: {
                            filter: (legendItem, data, ticks) => {
                              return (
                                data.datasets[legendItem.datasetIndex].label !== ""
                              );
                            },
                            color: "#fff",
                            padding: 0,
                            font: {
                              size: 15,
                            },
                          },
                        },
                        labels: {
                          filter: (legendItem, data, ticks) => {
                            return (
                              data.datasets[legendItem.datasetIndex].label !== ""
                            );
                          },
                          color: "#fff",
                        },
                      },
     
                      scales: {
                        y: {
                          title: {
                            color: "#fff",
                          },
                          max: calculateMax(), //  max balance registered
                          min: calculateMin(), //  min balance registered
                          ticks: {
                            stepSize: 0,
                            padding: 5,
                            precision:3,
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                              return "$" + value.toFixed(0);
                            },
                            font: {
                              size: 14,
                            },
                          },
                        },
                        x: {
                          ticks: {
                            minRotation: 30,
                            maxTicksLimit: 100,
                            backdropPadding: 5,
                            padding: 5,
    
                            font: {
                              size: 11,
                            },
                          },
                        },
                      },
                    }}
                    ref={chartRef}
                  />
                </div> </>: ''}
    
                { props.account.history_all.length > 0 ?
                <div className="d-flex flex-column">
                  <h3 className="title">Historico de operaciones</h3>
                  <div className="container-orders">

                    <div className={`orders-label ${props.account.server === 'quotex' ? 'label-quotex' : ''}`} >
                      <p >Fecha inicio <button type="button" onClick={()=>{changeField('created')}}>{quotexField.created ? <FaEye/> : <FaEyeSlash/>}</button></p>
                      <p >Activo <button onClick={()=>{changeField('active')}} type="button">{quotexField.active ? <FaEye/>: <FaEyeSlash/>}</button> </p>
                      <p>Monto<button type="button" onClick={()=>{changeField('amount')}}>{quotexField.amount ? <FaEye/> : <FaEyeSlash/>}</button></p>
                      {props.account.server === "quotex" ? <p >Direccion <button type="button" onClick={()=>{changeField('direction')}}>{quotexField.direction ? <FaEye/> : <FaEyeSlash/>}</button></p> : ''}
                      <p>Estado <button type="button" onClick={()=>{changeField('status')}}>{quotexField.status ? <FaEye/> : <FaEyeSlash/>}</button></p>
                      <p >Fecha cierre <button type="button" onClick={()=>{changeField('expired')}}>{quotexField.expired ? <FaEye/> : <FaEyeSlash/>}</button></p>
                    </div>
                    <div className="orders-data">
                      {props.account.server === "iqoption" ? props.account.history_all?.slice(offSetList, offSetList + 10).map((data, index) => {
                          return (
                            <div className="orders-row" key={index}>
                              <p className="data-field data-initial"  >
                                <span className="label-title">
                                  Fecha de inicio: <button type="button" onClick={()=>{changeField('created')}}>{quotexField.created ? <FaEye/> : <FaEyeSlash/>}</button>
                                </span>
                                {quotexField.created ? formatDate(data.created) : toAsterisk(formatDate(data.created))}
                              </p>
                              <p className="data-active" >
                                <span className="label-title">Activo: <button onClick={()=>{changeField('active')}} type="button">{quotexField.active ? <FaEye/>: <FaEyeSlash/>}</button></span>
                                {quotexField.active ? data.active : toAsterisk(data.active)}
                              </p>
                              <p className="data-amount">
                                <span className="label-title">Monto: <button type="button" onClick={()=>{changeField('amount')}}>{quotexField.amount ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                <span className="ind">{quotexField.amount ? '$'+data.amount : toAsterisk(String(data.amount))}</span>
                              </p>
                              <p className={`${data.win} data-win`}>
                                <span className="label-title">Estado: <button type="button" onClick={()=>{changeField('status')}}>{quotexField.status ? <FaEye/> : <FaEyeSlash/>}</button></span>
                               { quotexField.status ? <span className="ind">
                                  {data.win === "win" ? (
                                    <div className="icon-check position-relative">
                                      <span className="bg-aux"></span>
                                      <img src={CheckIcon} alt="check" />
                                    </div>
                                  ) : (
                                    <div className="icon-check position-relative">
                                      <span className="bg-aux"></span>
                                      <img src={CrossIcon} alt="cross" />
                                    </div>
                                  )}
                                </span> : <span style={{color:'white'}}>*</span>}
                              </p>
                              <p className="data-field data-end" >
                                <span className="label-title">Fecha cierre: <button type="button" onClick={()=>{changeField('expired')}}>{quotexField.expired ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                {quotexField.expired ? formatDate(data.expired) : toAsterisk(formatDate(data.expired))}
                              </p>
                            </div>
                          );
                        }) : ''}
    
                    {props.account.server === "quotex" ? props.account.history_all?.slice(offSetList, offSetList + 10).map((data, index) => {
                          return (
                            <div className="orders-row order-row-quotex"  key={index}>
                              <p className="data-field data-initial" >
                                <span>
                                    <span className="label-title" >
                                    Fecha de inicio:<button type="button" onClick={()=>{changeField('created')}}>{quotexField.created ? <FaEye/> : <FaEyeSlash/>}</button>
                                    </span>
                                    {quotexField.created ? data.open_date : toAsterisk(data.open_date)}
                                </span>
                              </p>
                              <p className="data-active" >
                                <span>
                                    <span className="label-title">Activo:<button type="button" onClick={()=>{changeField('active')}}>{quotexField.active ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                    {quotexField.active ? data.asset : toAsterisk(data.asset)}
                                </span>
                              </p>
                              <p className="data-amount">
                                <span>
                                  <span className="label-title" >Monto:<button type="button" onClick={()=>{changeField('amount')}}>{quotexField.amount ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                  <span className="ind">{quotexField.amount ? '$'+data.amount : toAsterisk(data.amount)}</span>
                                </span>
                              </p>
                              <p className="data-field data-direction" >
                                <span>
                                <span className="label-title">Direccion:<button type="button" onClick={()=>{changeField('direction')}}>{quotexField.direction ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                {quotexField.direction ? data.direction.toLowerCase() === "down" || data.direction.toLowerCase() === "abajo" ?  <span className="arrow-down"></span> : '' :'' } 
                                {quotexField.direction ? data.direction.toLowerCase() === "arriba" || data.direction.toLowerCase() === "up" ? <span className="arrow-up"></span> : '' : ''} 
                                {!quotexField.direction ? "*" : ''}
                                </span>
                              </p>
                              <p className={`${data.income === 0 ? 'loose' : 'win'} data-win`}>
                                <span>
                                <span className="label-title" >Estado:<button type="button" onClick={()=>{changeField('status')}}>{quotexField.status ? <FaEye/> : <FaEyeSlash/>}</button></span>
                               { quotexField.status ? <span className="ind">
                                  {data.income > 0  ? (
                                    <div className="icon-check position-relative">
                                      <span className="bg-aux"></span>
                                      <img src={CheckIcon} alt="check" />
                                    </div>
                                  ) : (
                                    <div className="icon-check position-relative">
                                      <span className="bg-aux"></span>
                                      <img src={CrossIcon} alt="cross" />
                                    </div>
                                  )}
                                </span>  : <span style={{color:'white'}}>*</span>}
                                </span>
                              </p>
                              <p className="data-field data-end" >
                                <span>
                                <span className="label-title">Fecha cierre:<button type="button" onClick={()=>{changeField('expired')}}><FaEye/></button></span>
                                {quotexField.expired ? data.close_date : toAsterisk(data.close_date)}
                                </span>
                              </p>
                            </div>
                          );
                        }) : ''}
                    </div>
                  </div>
                  <p className="text-white mt-1 mb-2">
                    Cantidad de operaciones registradas:{" "}
                    {props.account.history_all.length}
                  </p>
                  <div className="d-flex align-items-center gap-2 mt-2 justify-content-between">
                    <button
                      className="button-send-transparent"
                      onClick={() => {
                        handlePrev();
                      }}
                      style={{
                        textTransform: "capitalize",
                        padding: "10px 20px",
                        width: "max-content",
                      }}
                    >
                      Anterior
                    </button>
                    <button
                      className="button-send-transparent"
                      onClick={() => {
                        handleNext();
                      }}
                      style={{
                        textTransform: "capitalize",
                        padding: "10px 20px",
                        width: "max-content",
                      }}
                    >
                      Siguiente
                    </button>
                  </div>
                </div> : <div className="d-flex flex-column">
                <h3 className="title">Historico de operaciones</h3>
                <p className="text-white">Actualmente no hay operaciones registradas, intente mas tarde</p>
                </div>
                }
              </>
   
    
            
          </div>
        </>
      );
}

export default AccountReal