import * as React from "react"
const BuyExamIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.163 5.43c.626 0 1.139-.506 1.139-1.124V1.124c0-.618-.513-1.124-1.14-1.124H1.14C.513 0 0 .506 0 1.124v13.142c0 .618.513 1.124 1.14 1.124h19.023c.626 0 1.139-.506 1.139-1.124 0-.619-.513-1.124-1.14-1.124H2.279V2.248h16.746v2.058c0 .618.5 1.124 1.139 1.124Z"
    />
    <path
      fill="currentColor"
      d="m7.802 6.059-3.952 3.9 1.207 1.192 2.745-2.698 2.142 2.114 5.217-5.149-1.207-1.191-4.01 3.957-2.142-2.125ZM20.163 13.14c-4.317 0-7.837 3.474-7.837 7.735 0 .618.513 1.124 1.14 1.124.626 0 1.138-.506 1.138-1.124 0-3.024 2.495-5.486 5.56-5.486 3.064 0 5.558 2.462 5.558 5.486 0 .618.513 1.124 1.14 1.124.626 0 1.139-.506 1.139-1.124v-.124c-.08-4.215-3.566-7.61-7.838-7.61Z"
    />
    <path
      fill="currentColor"
      d="M25.199 8.162c0-2.743-2.267-4.98-5.047-4.98s-5.035 2.225-5.035 4.98c0 2.754 2.267 4.98 5.047 4.98s5.035-2.237 5.035-4.98Zm-5.035 2.731c-1.527 0-2.769-1.225-2.769-2.731 0-1.507 1.242-2.732 2.769-2.732 1.526 0 2.768 1.225 2.768 2.732 0 1.506-1.253 2.731-2.768 2.731Z"
    />
  </svg>
)
export default BuyExamIcon
