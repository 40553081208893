import * as React from "react"
const TimerIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={26}
    height={26}
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M276.193 58.507V40.389h14.578c11.153 0 20.194-9.042 20.194-20.194S301.923 0 290.771 0h-69.544c-11.153 0-20.194 9.042-20.194 20.194s9.042 20.194 20.194 20.194h14.578v18.118C119.952 68.76 28.799 166.327 28.799 284.799 28.799 410.078 130.721 512 256 512s227.201-101.922 227.201-227.201C483.2 166.327 392.046 68.76 276.193 58.507zm0 412.009v-20.124c0-11.153-9.042-20.194-20.194-20.194-11.153 0-20.194 9.042-20.194 20.194v20.124c-86.91-9.385-156.137-78.614-165.522-165.522h20.124c11.153 0 20.194-9.042 20.194-20.194s-9.042-20.194-20.194-20.194H70.282c9.385-86.91 78.614-156.137 165.522-165.523v20.124c0 11.153 9.042 20.194 20.194 20.194 11.153 0 20.194-9.042 20.194-20.194V99.081c86.91 9.385 156.137 78.614 165.522 165.523H421.59c-11.153 0-20.194 9.042-20.194 20.194s9.042 20.194 20.194 20.194h20.126c-9.385 86.911-78.613 156.14-165.523 165.524z" />
    <path d="m317.248 194.99-58.179 58.18a32.121 32.121 0 0 0-3.071-.151c-17.552 0-31.779 14.229-31.779 31.779 0 17.552 14.228 31.779 31.779 31.779s31.779-14.229 31.779-31.779c0-1.037-.054-2.06-.151-3.07l58.178-58.18c7.887-7.885 7.887-20.672 0-28.559-7.882-7.886-20.669-7.886-28.556.001z" />
  </svg>
)
export default TimerIcon