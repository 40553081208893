import { Link } from "react-router-dom";
import CheckoutPage from "../components/CheckoutPage/CheckoutPage";
import HouseIcon from "../components/icons/HouseIcon";
const Dashboard = () => {
  return (
    <div className="d-flex flex-column container-content-app">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <Link to="/dashboard"><HouseIcon /> Inicio</Link>
        </li>
        <li class="breadcrumb-item"><Link to="/dashboard">Mis cuentas fondeadas</Link></li>
        <li class="breadcrumb-item active">Empezar nuevo examen</li>
      </ul>
      <CheckoutPage />
    </div>
  );
};

export default Dashboard;
