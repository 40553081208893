import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import  Sidebar  from "../components/Dashboard/Sidebar";
//import {verifyToken} from '../API'
const ProtectedRoute = (props) => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const navigate = useNavigate()
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(true);
      } else {
        setUser(false);
        navigate('/login')
      }
    });
  }, [navigate]);

  return user ? (
    <>
      <div className="container-fluid d-flex flex-column flex-lg-row p-0 container-app-view">
        <Sidebar pathname={pathname}/>
        <Outlet />
      </div>
    </>
  ) : (
    <div className="d-flex flex-column container-content-app">
      <div className="d-flex flex-column align-items-center justify-content-center mb-4 mb-lg-0 m-auto">
        <div
          className="lds-dual-ring loader-mod"
          style={{ position: "absolute", top: "140px" }}
        ></div>
      </div>
    </div>
  );
};

export default ProtectedRoute;