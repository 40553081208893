import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import HouseIcon from "../components/icons/HouseIcon";
import Tickets from "../components/Support/Tickets";
import { onAuthStateChanged } from "firebase/auth";
import { auth, getUserDetails } from "../firebase/firebase";
import '../components/Support/support.css'
import '../components/Support/tickets-admin.css'
import { useNavigate } from "react-router-dom";
const SupportTickets = () =>{
    const navigate = useNavigate()
    const [uid, setUid] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const getDataUser = async () =>{
        const data = await getUserDetails()
        if(!data.admin){
          navigate('/dashboard')
        }
        setIsAdmin(data.admin)

    }
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
        if (user) {
            setUid(user.uid);
        } 
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    useEffect(()=>{
        getDataUser()
    },[uid])

    return(
      <div className="d-flex flex-column container-content-app">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="#">
              <HouseIcon /> Inicio
            </Link>
          </li>
          <li className="breadcrumb-item">Soporte</li>
          <li className="breadcrumb-item active">Tickets</li>
        </ul>

        <div className="container-admin-tickets">
            <section className="section-tickets-admin container-page-support">
               {isAdmin ?  <Tickets user={true} isAdmin={isAdmin}/> : '' }
            </section>
        </div>
    </div>
    )
}

export default SupportTickets;