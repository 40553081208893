import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import tutorialVideoQuotex from "../../assets/videos/tutorial.mp4";
function ModalWithdrawDetail(props) {
  // This component is a modal that shows the information of the account
  const [show, setShow] = useState(props.show);
  const [PAGEURL] = useState({
    quotex: "https://qxbroker.com/",
    iqoption: "",
    binomo: "",
  });
  // This function is executed when the component is rendered
  useEffect(() => {
    setShow(props.show);
  }, [props]);

  return (
    <>
      <Modal
        className="modal-custom-accounts"
        show={show}
        onHide={props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Informacion del retiro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column">
              <h3>Identificador de retiro</h3>
              <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                {props.withdraw.id}
              </p>
            </div>
            <div className="d-flex flex-column">
              <h3>Fecha de retiro</h3>
              <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                {props.withdraw.date}
              </p>
            </div>
            <div className="d-flex flex-column">
              <h3>Cantidad de retiro</h3>
              <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                {props.withdraw.amount}
              </p>
            </div>
            <div className="d-flex flex-column">
              <h3>Estado del retiro</h3>
              <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                {props.withdraw.status}
              </p>
            </div>
            <div className="d-flex flex-column">
              <h3>Direccion del retiro</h3>
              <p style={{ fontSize: "14px", wordBreak: "break-all" }}>
                {props.withdraw.address}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalWithdrawDetail;
