import { useState, useEffect } from "react";
import "./card-checkout.css";
import "react-phone-number-input/style.css";
import { redirectDocument } from "react-router-dom";

import PhoneInputWithCountry, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import {cardPayment} from "../API/cards";
import Modal from "react-bootstrap/Modal";
import valid from "card-validator";
import { getUserDetails } from "../../firebase/firebase";
const CardCheckout = (props) => {
  //Modal form payment
  const [productInfo, setProductInfo] = useState({
    broker: "",
    description: "",
    name: "",
    pay_amount: 0,
    position_broker: "",
  });

  const [userInfo,setUserInfo] = useState({
    first_name:"",
    last_name:""
  })

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("to_pay"));
    setProductInfo(localData);
    setCustomerInfo({
      ...customerInfo,
      email: localStorage.getItem("email") || "",
    });
  }, [props]);

  const [show, setShow] = useState(false);
  const [uid,setUid] = useState("")
  //Data from the client to send
  const [customerInfo, setCustomerInfo] = useState({
    bill_address: "",
    bill_address_2: "",
    phone_number: "",
    city: "",
    email: "",
    card_customer_type: "CC",
    postcode: "",
    card_holdername: "",
    card_number: "",
    card_expiration_date: "2024-06",
    card_cvv: "",
  });

  useEffect(()=>{
    const request = async () => {
      const userDetails = await getUserDetails()
      setUserInfo(userDetails)
      setUid(userDetails.userId)
  }
  request()
  },[])
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [formError, setFormErrors] = useState(false);
  const [onError, setOnError] = useState({
    bill_address: false,
    bill_address_2: false,
    phone_number: false,
    city: false,
    email: false,
    postcode: false,
    card_holdername: false,
    card_number: false,
    card_expiration_date: false,
    card_cvv: false,
  });

  const [onErrorMessage, setOnErrorMessage] = useState({
    bill_address: "",
    phone_number: "",
    city: "",
    email: "",
    postcode: "",
    card_holdername: "",
    card_number: "",
    card_expiration_date: "",
    card_cvv: "",
  });

  const handleCloseModal = () => {
    setShow(false);
  };

  const handleOpenModal = () => {
    if (props.country === "") return alert("Seleccione su pais de residencia");
    setShow(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  //Handle change from form in modal

  const handleInputForm = (e) => {
    const { name, value } = e.target;
    setCustomerInfo({ ...customerInfo, [name]: value });
    console.log(name, value);
  };
//Fetch data to send
  const handleFetchCard = async () => {
    try{
      if(isSubmitting) return
      setIsSubmitting(true)
      const data = {
      first_name_user:userInfo.first_name,
      last_name_user:userInfo.last_name,
      bill_amt: String(productInfo.pay_amount),
      product_name: productInfo.description.split("-")[0].trim(),
      package_name: productInfo.description.split("-")[1].trim(),
      broker_name:productInfo.description.split("-")[2].trim(),
      fullname: customerInfo.card_holdername,
      email: customerInfo.email,
      bill_address: customerInfo.bill_address,
      bill_city: customerInfo.city,
      bill_country: props.country.split("-")[0].trim(),
      bill_zip: customerInfo.postcode,
      bill_phone: customerInfo.phone_number,
      card_ccno: customerInfo.card_number,
      card_ccvv: customerInfo.card_cvv,
      card_type_customer:customerInfo.card_customer_type,
      card_month: customerInfo.card_expiration_date.split("-")[1],
      card_year: customerInfo.card_expiration_date.split("-")[0].slice(2, 4),
      uid:uid
    };
    console.log(data)
    const res = await cardPayment(data)
    if(res.status === 200){
      console.log(res)
      setIsSubmitting(false)
      window.location.href = res.results.authurl
    }else{
      setIsSubmitting(false)
      alert("Error al enviar la informacion, verifique los datos, intente de nuevo")
    }
  }catch(e){
    setIsSubmitting(false)
    console.log(e)
    alert("Error al enviar la informacion, verifique los datos, intente de nuevo")
  }

  };

  //Handle submit data
  const handleSubmitCard = async (e) => {
    try {
      setOnError({
        bill_address: false,
        phone_number: false,
        city: false,
        email: false,
        postcode: false,
        card_number: false,
        card_holdername: false,
        card_expiration_date: false,
        card_cvv: false,
      });

      let noErrors = true;
      setFormErrors(false); // Set if show errors messages
      e.preventDefault();

      // Validation user

      if (customerInfo.bill_address === "") {
        setOnError({ ...onError, bill_address: true });
        setOnErrorMessage({
          ...onErrorMessage,
          bill_address: "El campo no puede estar vacio",
        });
        if (noErrors) noErrors = false;
      }

      if (customerInfo.city === "") {
        setOnError({ ...onError, city: true });
        setOnErrorMessage({
          ...onErrorMessage,
          city: "El campo no puede estar vacio",
        });
        if (noErrors) noErrors = false;
      }


      if (customerInfo.postcode === "") {
        setOnError({ ...onError, postcode: true });
        setOnErrorMessage({
          ...onErrorMessage,
          postcode: "El campo no puede estar vacio",
        });
        if (noErrors) noErrors = false;
      }

      if (customerInfo.email === "" || !validateEmail(customerInfo.email)) {
        setOnError({ ...onError, email: true });
        setOnErrorMessage({
          ...onErrorMessage,
          email: "Introduzca un email valido",
        });
        if (noErrors) noErrors = false;
      }

      //Check phone number
      const phoneNumber = isValidPhoneNumber(customerInfo.phone_number);
      if (!phoneNumber) {
        setOnError({ ...onError, phone_number: true });
        setOnErrorMessage({
          ...onErrorMessage,
          phone_number: "El numero de telefono no es valido",
        });
        if (noErrors) noErrors = false;
      }
      //Validate data card
      const cardHoldernameValidation = valid.cardholderName(
        customerInfo.card_holdername
      );
      const numberCardValidation = valid.number(customerInfo.card_number);
      const expirationValidation = valid.expirationDate(
        customerInfo.card_expiration_date
      );
      const cvvValidation = valid.cvv(customerInfo.card_cvv);

      //Validation information card

      if (!cardHoldernameValidation.isValid) {
        setOnError({ ...onError, card_holdername: true });
        setOnErrorMessage({
          ...onErrorMessage,
          card_holdername: "El nombre del titular no es valido",
        });
        if (noErrors) noErrors = false;
      }

      if (!numberCardValidation.isValid) {
        setOnError({ ...onError, card_number: true });
        setOnErrorMessage({
          ...onErrorMessage,
          card_number: "El numero de la tarjeta no es valido",
        });
        if (noErrors) noErrors = false;
      }

      if (!expirationValidation.isValid) {
        setOnError({ ...onError, card_expiration_date: true });
        setOnErrorMessage({
          ...onErrorMessage,
          card_expiration_date: "La fecha de expiracion no es valido",
        });
        if (noErrors) noErrors = false;
      }

      if (!cvvValidation.isValid) {
        setOnError({ ...onError, card_cvv: true });
        setOnErrorMessage({
          ...onErrorMessage,
          card_cvv: "El CVV no es valido",
        });
        if (noErrors) noErrors = false;
      }
      if (!noErrors) {

        return setFormErrors(true);
      }

      handleFetchCard()
    } catch (e) {
      console.log(e);
      alert("Error al enviar la informacion, intente de nuevo");
    }
  };

 

  return (
    <>
      <button
        className="button-send"
        onClick={handleOpenModal}
        type="button"
        style={{ marginTop: "2px" }}
      >
        Realizar pedido
      </button>

      <Modal
        className="modal-custom-accounts modal-card-checkout"
        show={show}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Informacion de tarjeta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="flex flex-column col-12" onSubmit={handleSubmitCard}>
            <div class="flex flex-column col-12">
              <div className="container-input-field">
                <label
                  style={{
                    color: "white",
                    fontWeight: "600",
                    marginBottom: "7px",
                  }}
                  htmlFor="email"
                  value={customerInfo.email}
                >
                  Email <span style={{ color: "#fa5745" }}>*</span>
                </label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  className="form-inputs"
                  onChange={(e) => {
                    handleInputForm(e);
                  }}
                  value={customerInfo.email}
                  required
                />
                <small
                  className={`${
                    formError && onError.email
                      ? "error-message"
                      : "hide-message"
                  }`}
                >
                  {onErrorMessage.email}
                </small>
              </div>
              <div className="container-input-field">
                <label
                  style={{
                    color: "white",
                    fontWeight: "600",
                    marginBottom: "7px",
                  }}
                  htmlFor="bill_address"
                  value={customerInfo.bill_address}
                >
                  Dirección <span style={{ color: "#fa5745" }}>*</span>
                </label>
                <input
                  id="bill_address"
                  name="bill_address"
                  type="text"
                  className="form-inputs"
                  onChange={(e) => {
                    handleInputForm(e);
                  }}
                  value={customerInfo.bill_address}
                  required
                />
                <small
                  className={`${
                    formError && onError.bill_address
                      ? "error-message"
                      : "hide-message"
                  }`}
                >
                  {onErrorMessage.bill_address}
                </small>
              </div>
              <div className="row">
             

                <div className="container-input-field col-12 col-md-6">
                  <label
                    style={{
                      color: "white",
                      fontWeight: "600",
                      marginBottom: "7px",
                    }}
                    htmlFor="town-city"
                  >
                    Ciudad <span style={{ color: "#fa5745" }}>*</span>
                  </label>
                  <input
                    id="town-city"
                    name="city"
                    type="text"
                    className="form-inputs"
                    onChange={(e) => {
                      handleInputForm(e);
                    }}
                    value={customerInfo.city}
                    required
                  />
                  <small
                    className={`${
                      formError && onError.city
                        ? "error-message"
                        : "hide-message"
                    }`}
                  >
                    {onErrorMessage.firstname}
                  </small>
                </div>

                <div className="container-input-field col-12 col-md-6">
                  <label
                    htmlFor="postcode-zip"
                    style={{
                      color: "white",
                      fontWeight: "600",
                      marginBottom: "7px",
                    }}
                  >
                    Código postal <span style={{ color: "#fa5745" }}>*</span>
                  </label>
                  <input
                    id="postcode-zip"
                    name="postcode"
                    type="text"
                    className="form-inputs"
                    onChange={(e) => {
                      handleInputForm(e);
                    }}
                    value={customerInfo.postcode}
                    required
                  />
                  <small
                    className={`${
                      formError && onError.postcode
                        ? "error-message"
                        : "hide-message"
                    }`}
                  >
                    {onErrorMessage.postcode}
                  </small>
                </div>
              </div>
              <div className="container-input-field">
                <label
                  style={{
                    color: "white",
                    fontWeight: "600",
                    marginBottom: "7px",
                  }}
                  htmlFor="phone_number"
                >
                  Numero de telefono <span style={{ color: "#fa5745" }}>*</span>
                </label>
                <PhoneInputWithCountry
                  placeholder="Introduce tu numero de telefono"
                  value={customerInfo.value}
                  rules={{ required: true }}
                  onChange={(value) => {
                    setCustomerInfo({ ...customerInfo, phone_number: value });
                  }}
                />
                <small
                  className={`${
                    formError && onError.phone_number
                      ? "error-message"
                      : "hide-message"
                  }`}
                >
                  {onErrorMessage.phone_number}
                </small>
              </div>
              <div className="row">
                <div className="container-input-field col-12 col-md-6">
                  <label
                    htmlFor="card_holdername"
                    style={{
                      color: "white",
                      fontWeight: "600",
                      marginBottom: "7px",
                    }}
                  >
                    Titular de la tarjeta{" "}
                    <span style={{ color: "#fa5745" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="card_holdername"
                    className="form-inputs"
                    name="card_holdername"
                    placeholder=""
                    onChange={(e) => {
                      if (e.target.value.length > 70) {
                        return;
                      }
                      handleInputForm(e);
                    }}
                    value={customerInfo.card_holdername}
                    required
                  />
                  <small
                    className={`${
                      formError && onError.card_holdername
                        ? "error-message"
                        : "hide-message"
                    }`}
                  >
                    {onErrorMessage.card_holdername}
                  </small>
                </div>

                <div className="container-input-field col-12 col-md-6">
                  <label
                    htmlFor="card_customer_type"
                    style={{
                      color: "white",
                      fontWeight: "600",
                      marginBottom: "7px",
                    }}
                  >
                    Tipo de tarjeta <span style={{ color: "#fa5745" }}>*</span>
                  </label>
                  <select
                    className="form-inputs"
                    onChange={(e) => {
                      setCustomerInfo({
                        ...customerInfo,
                        card_customer_type: e.target.value,
                      });
                    }}
                    id="card_customer_type"
                    defaultValue={customerInfo.card_customer_type}
                  >
                    <option value="CC">Credito</option>
                    <option value="DC">Debito</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="container-input-field col-md-4">
                  <label
                    htmlFor="card_number"
                    style={{
                      color: "white",
                      fontWeight: "600",
                      marginBottom: "7px",
                    }}
                  >
                    Numero de la tarjeta{" "}
                    <span style={{ color: "#fa5745" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="card_number"
                    className="form-inputs"
                    name="card_number"
                    placeholder=""
                    onChange={(e) => {
                      if (isNaN(e.target.value) || e.target.value.length > 25) {
                        return
                      }
                      handleInputForm(e);
                    }}
                    value={customerInfo.card_number}
                    required
                  />
                  <small
                    className={`${
                      formError && onError.card_number
                        ? "error-message"
                        : "hide-message"
                    }`}
                  >
                    {onErrorMessage.card_number}
                  </small>
                </div>

                <div className="container-input-field col-md-4">
                  <label
                    htmlFor="card_expiration_date"
                    style={{
                      color: "white",
                      fontWeight: "600",
                      marginBottom: "7px",
                    }}
                  >
                    Fecha de expiracion{" "}
                    <span style={{ color: "#fa5745" }}>*</span>
                  </label>
                  <input
                    type="month"
                    className="form-inputs"
                    placeholder="MMYY"
                    id="card_expiration_date"
                    name="card_expiration_date"
                    value={customerInfo.card_expiration_date}
                    onChange={(e) => {
                      handleInputForm(e);
                    }}
                    required
                  />
                  <small
                    className={`${
                      formError && onError.card_expiration_date
                        ? "error-message"
                        : "hide-message"
                    }`}
                  >
                    {onErrorMessage.card_expiration_date}
                  </small>
                </div>

                <div className="container-input-field col-md-4">
                  <label
                    htmlFor="card_cvv"
                    style={{
                      color: "white",
                      fontWeight: "600",
                      marginBottom: "7px",
                    }}
                  >
                    CVV <span style={{ color: "#fa5745" }}>*</span>
                  </label>
                  <input
                    type="number"
                    className="form-inputs"
                    name="card_cvv"
                    id="card_cvv"
                    placeholder="CVV"
                    onChange={(e) => {
                      if(e.target.value.length > 6) return
                        handleInputForm(e);
                    }}
                    value={customerInfo.card_cvv}
                    required
                  />
                  <small
                    className={`${
                      formError && onError.card_cvv
                        ? "error-message"
                        : "hide-message"
                    }`}
                  >
                    {onErrorMessage.card_cvv}
                  </small>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="button-send"
                  style={{ margin: "20px auto auto auto" }}
                >
                  Pagar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default CardCheckout;
