import "../Withdraw/withdraw.css"
import { useState,useEffect } from "react"
import ModalWithdrawDetail from "../Modals/ModalWithdrawDetail"
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
const AccountWithdraw = (props) => {
    const [showModal,setShowModal] = useState(false)
    const [withdrawFields,setWithdrawFields] = useState({
        id:localStorage.getItem("withdrawFields") ? JSON.parse(localStorage.getItem("withdrawFields"))?.id : true,
        date:localStorage.getItem("withdrawFields") ? JSON.parse(localStorage.getItem("withdrawFields"))?.date : true,
        amount:localStorage.getItem("withdrawFields") ? JSON.parse(localStorage.getItem("withdrawFields"))?.amount : true,
        status:localStorage.getItem("withdrawFields") ? JSON.parse(localStorage.getItem("withdrawFields"))?.status : true
    })

    useEffect(()=>{
        localStorage.setItem("withdrawFields",JSON.stringify(withdrawFields))
    },[withdrawFields])
    
    const [withdrawInfo,setWithdrawInfo] = useState({
        id:'',
        date:'',
        amount:'',
        status:'',
        address:''
    })

    const STATUS_WITHDRAW = {
        PENDING: "Pendiente",
        COMPLETED: "Completado",
        NEW: "Nuevo",
      }
    return(
        <>
        <ModalWithdrawDetail show={showModal} handleClose={()=>{setShowModal(false)}} withdraw={withdrawInfo}/>
        <div className="container-funded-withdraw funded-withdraw-account">
            <div className="container-info-withdraw pb-4">
        <div className="d-flex flex-column flex-lg-row flex-wrap align-items-start pt-2 pb-2 mb-1 justify-content-between">
          <h3 style={{margin:'0'}} className="title-content">Historial de retiros</h3>
          
          <p style={{margin:'0'}}>Cantidad de retiros realizadas: {props.withdraws.length || '0'}</p>
        </div>
        <p style={{display:'none'}} className={`${Object.values(withdrawFields).filter((el)=> el === false).length > 0 ? 'show-text-desk' : 'hidden-text-desk'}`} >Campos ocultos</p>
       
            <div className="container-table">
                <div><p>Identificador <button type="button" onClick={()=>{setWithdrawFields({...withdrawFields,id:!withdrawFields.id})}}> {withdrawFields.id ? <FaEye style={{width:'16px', height:'16px', fill:'white'}}/> : <FaEyeSlash style={{width:'16px', height:'16px', fill:'white'}}/>}</button></p></div>
                <div ><p>Fecha<button type="button" onClick={()=>{setWithdrawFields({...withdrawFields,date:!withdrawFields.date})}}>{withdrawFields.date ? <FaEye style={{width:'16px', height:'16px', fill:'white'}}/> : <FaEyeSlash style={{width:'16px', height:'16px', fill:'white'}}/>}</button></p></div>
                <div ><p>Cantidad<button onClick={()=>{setWithdrawFields({...withdrawFields,amount:!withdrawFields.amount})}} type="button">{withdrawFields.amount ? <FaEye style={{width:'16px', height:'16px', fill:'white'}}/> : <FaEyeSlash style={{width:'16px', height:'16px', fill:'white'}}/>}</button></p></div>
                <div ><p>Estado<button type="button" onClick={()=>{setWithdrawFields({...withdrawFields,status:!withdrawFields.status})}}>{withdrawFields.status ? <FaEye style={{width:'16px', height:'16px', fill:'white'}}/> : <FaEyeSlash style={{width:'16px', height:'16px', fill:'white'}}/>}</button></p></div>
            </div>
            {props.withdraws.length === 0 ? <p className='text-center'>No hay retiros registrados</p> :  ''}

            {props.withdraws.length > 0 ? props.withdraws.slice(props.offSetList, props.offSetList + 10).map((withdraw,index)=>{
                return(
                    <div className="container-table-content"  key={index} role="button" onClick={()=>{
                        setWithdrawInfo({
                            id:withdraw.id,
                            date:new Date(withdraw.date).toISOString().slice(0, 10).replaceAll("-", "/"),
                            amount:withdraw.amount,
                            status:STATUS_WITHDRAW[withdraw.status.toUpperCase()] || '',
                            address:withdraw.address
                        })
                        setShowModal(true)
                    }}>
                        <div><p>{withdrawFields.id ? withdraw.id : withdraw.id.substring(0, Math.min(withdraw.id.length, 10)).split("").map(function(char) {
  return char === " " ? " " : "*";
}).join("") }</p></div>
                        <div >{withdrawFields.date ? <p>{new Date(withdraw.date).toISOString().slice(0, 10).replaceAll("-", "/")}</p> : <p>{new Date(withdraw.date).toISOString().slice(0, 10).replaceAll("-", "/").substring(0, Math.min(withdraw.date.length, 10)).split("").map(function(char) {
  return char === " " ? " " : "*";
}).join("")}</p>}</div>
                        <div ><p>{`${withdrawFields.amount ? '$' + withdraw.amount : String(withdraw.amount).substring(0, Math.min(String(withdraw.amount).length, 10)).split("").map(function(char) {
  return char === " " ? " " : "*";
}).join("")  }`}</p></div>
                        <div ><p>{withdrawFields.status ? STATUS_WITHDRAW[withdraw.status.toUpperCase()] : STATUS_WITHDRAW['new'.toUpperCase()].substring(0, Math.min(STATUS_WITHDRAW['new'.toUpperCase()].length, 10)).split("").map(function(char) {
  return char === " " ? " " : "*";
}).join("") }</p></div>
                    </div>
                )
            }):''}
           

            <div className="container-table-mobile">
            {props.withdraws.length > 0 ? props.withdraws.slice(props.offSetList, props.offSetList + 10).map((withdraw,index)=>{
              return(
              <div className="content-withdraw" key={index} role="button" >
                <div className="id-withdraw">
                    <span>Identificador  <button style={{background:'none',border:'none', outline:'0'}} type="button" onClick={()=>{setWithdrawFields({...withdrawFields,id:!withdrawFields.id})}}>{withdrawFields.id ? <FaEye style={{width:'16px', height:'16px', fill:'white'}}/> : <FaEyeSlash style={{width:'16px', height:'16px', fill:'white'}}/>}</button></span><p>{withdrawFields.id ? withdraw.id : withdraw.id.substring(0, Math.min(withdraw.id.length, 10)).split("").map(function(char) {
  return char === " " ? " " : "*";
}).join("") }</p>
                </div>

                <div className="date-withdraw" >
                    <span>Fecha <button style={{background:'none',border:'none', outline:'0'}} type="button" onClick={()=>{setWithdrawFields({...withdrawFields,date:!withdrawFields.date})}}>{withdrawFields.date ? <FaEye style={{width:'16px', height:'16px', fill:'white'}}/> : <FaEyeSlash style={{width:'16px', height:'16px', fill:'white'}}/>}</button></span>{withdrawFields.date ? <p>{new Date(withdraw.date).toISOString().slice(0, 10).replaceAll("-", "/")}</p> : <p>{new Date(withdraw.date).toISOString().slice(0, 10).replaceAll("-", "/").substring(0, Math.min(withdraw.date.length, 10)).split("").map(function(char) {
  return char === " " ? " " : "*";
}).join("")}</p>}
                </div>
                <div className="amount-withdraw" >
                    <span>Cantidad <button style={{background:'none',border:'none', outline:'0'}}  onClick={()=>{setWithdrawFields({...withdrawFields,amount:!withdrawFields.amount})}} type="button">{withdrawFields.amount ? <FaEye style={{width:'16px', height:'16px', fill:'white'}}/> : <FaEyeSlash style={{width:'16px', height:'16px', fill:'white'}}/>}</button></span><p>{`${withdrawFields.amount ? '$' + withdraw.amount : String(withdraw.amount).substring(0, Math.min(String(withdraw.amount).length, 10)).split("").map(function(char) {
  return char === " " ? " " : "*";
}).join("")  }`}</p>
                    
                </div>
                <div className="status-withdraw">
                    <span>Estado <button type="button" style={{background:'none',border:'none', outline:'0'}} onClick={()=>{setWithdrawFields({...withdrawFields,status:!withdrawFields.status})}}>{withdrawFields.status ? <FaEye style={{width:'16px', height:'16px', fill:'white'}}/> : <FaEyeSlash style={{width:'16px', height:'16px', fill:'white'}}/>}</button></span>
                    <p>{withdrawFields.status ? STATUS_WITHDRAW[withdraw.status.toUpperCase()] : STATUS_WITHDRAW['new'.toUpperCase()].substring(0, Math.min(STATUS_WITHDRAW['new'.toUpperCase()].length, 10)).split("").map(function(char) {
  return char === " " ? " " : "*";
}).join("") }</p>
                </div>
                <div className="address-withdraw">
                    <span>Direccion del retiro <button type="button" style={{background:'none',border:'none', outline:'0'}} onClick={()=>{setWithdrawFields({...withdrawFields,address:!withdrawFields.address})}}>{withdrawFields.address ? <FaEye style={{width:'16px', height:'16px', fill:'white'}}/> : <FaEyeSlash style={{width:'16px', height:'16px', fill:'white'}}/>}</button></span>
                    <p>{withdrawFields.address ? withdraw.address : withdraw.address.substring(0, Math.min(withdraw.address.length, 10)).split("").map(function(char) {
  return char === " " ? " " : "*";
}).join("") }</p>
                </div>
              </div>)}) : ''}
            </div>

            {props.withdraws.length > 10 ? 
                        <div className="d-flex align-items-center gap-2 mt-3 justify-content-between">
                            <button
                            className="button-send-transparent"
                            onClick={() => {
                                props.handlePrev();
                            }}
                            style={{
                                textTransform: "capitalize",
                                padding: "10px 20px",
                                width: "max-content",
                            }}
                            >
                            Anterior
                            </button>
                            <button
                            className="button-send-transparent"
                            onClick={() => {
                                props.handleNext();
                            }}
                            style={{
                                textTransform: "capitalize",
                                padding: "10px 20px",
                                width: "max-content",
                            }}
                            >
                            Siguiente
                            </button>
                        </div>: ''}
      </div>
        </div>
        </>
    )
}

export default AccountWithdraw;