import { useEffect,useState,useRef } from "react";
import { STATUS_ACCOUNT } from "../../utils/index";
import ModalAccountInfo from "../Modals/ModalAccountInfo";
import "../AccountInformation/account-information.css";
import { Link } from "react-router-dom";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import HouseIcon from "../icons/HouseIcon";
import CrossIcon from "../../assets/icons/cross.png";
import CheckIcon from "../../assets/icons/accept.png";
import KeyIcon from "../../assets/icons/key.png";
import BookIcon from "../icons/BookIcon";
import StarIcon from "../icons/StarIcon";
import WithdrawIcon from "../icons/WithdrawIcon";
import { color, isArray } from "chart.js/helpers";
import { onAuthStateChanged } from "firebase/auth";
import {TYPE_ACCOUNT_FUNDED} from "../../utils/index"
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import {
    getDatabase,
    ref,
    query,
    orderByChild,
    update,
    get,
    equalTo,
  } from "firebase/database";
  import { app, auth } from "../../firebase/firebase";
const AccountDemo = (props) =>{
defaults.maintainAspectRatio = false;
defaults.responsive = true;
defaults.color = "white";

const [quotexField,setQuotexField] = useState({
  created:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.created : true,
  active:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.active : true,
  amount:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.amount: true,
  direction:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.direction : true,
  status:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.status : true,
  expired:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.expired : true,
  balance:localStorage.getItem("quotexField") ? JSON.parse(localStorage.getItem("quotexField"))?.balance : true

})

const toAsterisk = (value) =>{
  return value.substring(0, Math.min(value.length, 10)).split("").map(function(char) {
    return char === " " ? " " : "*";
  }).join("")
}
const changeField = (field) =>{
setQuotexField({...quotexField,[field]:!quotexField[field]})
}

useEffect(()=>{
  localStorage.setItem("quotexField",JSON.stringify(quotexField))
},[quotexField])

const formatDate = (date) => {
  //format the date to show in the table operations iqoption
  return `${new Date(date * 1000).getFullYear()}/${
    new Date(date * 1000).getMonth() + 1 <= 9
      ? "0" + (new Date(date * 1000).getMonth() + 1)
      : new Date(date * 1000).getMonth() + 1
  }/${
    new Date(date * 1000).getUTCDate() <= 9
      ? "0" + new Date(date * 1000).getUTCDate()
      : new Date(date * 1000).getUTCDate()
  } - ${
    new Date(date * 1000).getHours() <= 9
      ? "0" + new Date(date * 1000).getHours()
      : new Date(date * 1000).getHours()
  }:${
    new Date(date * 1000).getMinutes() <= 9
      ? "0" + new Date(date * 1000).getMinutes()
      : new Date(date * 1000).getMinutes()
  }`;
};

const RowInfo = ({ title, description, value, passing, index, text }) => {
  //This component is a row of the rules of the account
  return (
    <div
      className={`position-relative row-container ${
        index % 2 === 0 ? "row-active" : "row-inactive"
      }`}
    >
      <div
        class="rows-information rows-contents collapsed"
        data-bs-toggle="collapse"
        data-bs-target={`#collapseOneItem-${index}`}
        aria-expanded="true"
        aria-controls={`collapseOneItem-${index}`}
      >
        <div>
          <h3 class="row-text">{title}</h3>
        </div>
        <div class="content">
          <h3 class="title-content position-relative">
            <span class="fade-content fade-active">{text ? '-' : value}</span>
          </h3>
        </div>
        <div class="content">
          <h3 class="title-content position-relative d-flex align-items-center justify-content-center justify-content-lg-start" style={{paddingLeft:'10px'}}>
            {text ? (
              <span class="fade-content fade-active text-center text-lg-start d-flex align-items-center" >
                {text}
              </span>
            ) : (
              <span class="fade-content fade-active  d-flex flex-column flex-sm-row align-items-center">
                <div className="icon-check position-relative">
                  <span className="bg-aux"></span>
                  <img src={passing ? CheckIcon : CrossIcon} alt="cross" />
                </div>
                {passing ? "Aprueba" : "No aprueba"}
              </span>
            )}
          </h3>
        </div>
      </div>
      <div class="accordion-item-list">
        <div
          id={`collapseOneItem-${index}`}
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div>{description}</div>
        </div>
      </div>
    </div>
  );
};

    const [dataChart,setDataChart] = useState([])

    const openModal = () => {
      setShowMod(true);
    };
  
    const closeModal = () => {
      setShowMod(false);
    };
  
  
    const chartRef = useRef(null);
  
    const [showMod, setShowMod] = useState(false);
    const [load, setLoad] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [activateButton, setActivateButton] = useState(false);
    const [profiTarget, setProfitTarget] = useState({
      value: "0%",
      passing: false,
    });
  
    const [totalLoss, setTotalLoss] = useState({
      value: "0%",
      passing: false,
    });
  
    const [operationMax, setOperationMax] = useState({
      value: "0%",
      passing: true,
    });
  
    const [diaryLoss, setDiaryLoss] = useState({
      lossValue: 0,
      percentageLoss: 0,
    });
  
   
  
    const [modalInfo, setModalInfo] = useState({
      title: "",
      email: ``,
      password: "",
    });
  
    const [offSetList, setoffSetList] = useState(0);
    const [uid, setUid] = useState("");
  
    useEffect(()=>{
      if(props.account.server === "quotex"){
        if(totalLoss.passing && profiTarget.passing && diaryLoss.percentageLoss <= 4 ){
         // setActivateButton(true)
      }
    }
  
    if(props.account.server === "iqoption"){
      if(totalLoss.passing && profiTarget.passing && diaryLoss.percentageLoss <= 4 && operationMax.passing){
        //setActivateButton(true)
     }
  }
    },[props.account,totalLoss,diaryLoss,profiTarget,operationMax])
  
    const calculateDiaryLoss = (oldBalance, balanceActual) => {
      if (balanceActual >= oldBalance) {
        setDiaryLoss({
          lossValue: 0,
          percentageLoss: 0,
        });
        return;
      }
      // Calculate the loss
      const loss = Math.round(oldBalance - props.account.balance_actual);
      // Calculate the percentage of loss
      const percentageLoss = ((oldBalance - props.account.balance_actual) / oldBalance) * 100;
  
      // Return an object with results
      setDiaryLoss({
        lossValue: loss,
        percentageLoss: Math.round(percentageLoss), // Round to 2 decimal places
      });
    };
  
    //Calculate the min balance in the chart
    const calculateMin = () =>{
      let min = Math.round((dataChart.reduce((min, obj) => {
        return obj.balance < min ? obj.balance : min;
      }, Infinity) - 2000) / 1000) * 1000
      return min
    }
  
  
    //Calculate the max balance in the chart
    const calculateMax = () =>{
      let max = Math.round((dataChart.reduce((max, obj) => {
        return obj.balance > max ? obj.balance : max;
      }, -Infinity) + 2000) / 1000) * 1000
      return max
    }
  
    const calculateProfit = (value) => {
      //Caclulate the profit target
      const initial_funds = parseFloat(props.account.initial_funds.split(".").join(""));
  
      const percent = 0.12; // 12%
      // The actual value of the props.account
      const total = parseFloat(Number(value).toFixed(2));
  
      //The icrement of the initial funds expected
      const initialIncrement = Number(initial_funds) * percent;
  
      //The estimated value of the props.account
      const estimated = Number(initial_funds) + initialIncrement;
  
      const indicator = Number(((value - initial_funds) / initial_funds) * 100);
  
      if(indicator < 0){
        return setProfitTarget({
          value: '0% / 0$',
          passing: false
        })
      }
  
      // Verify if the total is greater than or equal to the estimated
      if (total >= estimated) {
        setProfitTarget({
          value: `${indicator <= 0 ? 0 : Math.round(indicator)}% / ${
            (total - initial_funds).toFixed(2) === '0.00' ? 0 : (total - initial_funds).toFixed(2) 
          }$`,
          passing: true,
        });
      } else {
        setProfitTarget({
          value: `${indicator <= 0 ? 0 : Math.round(indicator)}% / ${
            (total - initial_funds).toFixed(2) === '0.00' ? 0 : (total - initial_funds).toFixed(2) 
          }$`,
          passing: false,
        });
      }
    };
    const setCalculateTotalLoss = ()=>{
        //Set the total loss to show in the view
        const initial_funds = parseFloat(props.account.initial_funds.split(".").join(""));
        const indicator = Number((props.account.total_max_loss / initial_funds)) * 100;
    
        const maxPercentage = 8; // 8%
        if (indicator <= maxPercentage) {
          setTotalLoss({
            value: `${indicator < 0 ? 0 : Math.round(indicator)}% / ${
              Math.round(props.account.total_max_loss) < 0
                ? "0"
                : Math.round(props.account.total_max_loss)
            }$`,
            passing: true,
          });
        } else {
          setTotalLoss({
            value: `${indicator < 0 ? 0 : Math.round(indicator)}% / ${
              Math.round(props.account.total_max_loss)
            }$`,
            passing: false,
          });
        }
      }
    
      const calculateTotalLoss = async (value) => {
        // Calculate the total loss of the props.account
        const initial_funds = parseFloat(props.account.initial_funds.split(".").join(""));
    
        const total = Number(value);
    
        // Verify if the missing percentage is less than or equal to 8%.
        const totalLossAmount =  Math.round(initial_funds - total)
        if(totalLossAmount > props.account.total_max_loss){
          //Save data in the account
          const db = getDatabase(app);
          const dataUserAccounts = ref(db, `users/${uid}/accounts`);
          const queryAccount = query(
            dataUserAccounts,
            orderByChild("order_id"),
            equalTo(String(props.accountId))
          );
    
          const getAccount = await get(queryAccount);
          if (!getAccount.exists()) {
            return setShowAccount(false);
          }
    
          //formating data
          const key = Object.keys(getAccount.val())[0];
    
          const dataUserAccountsUpdate = ref(db, `users/${uid}/accounts/${key}`);
          const updateAccount = {
            total_max_loss: totalLossAmount,
          };
    
          await update(dataUserAccountsUpdate, updateAccount);
          props.setAccount({...props.account, total_max_loss: totalLossAmount})
          
        }
    
        setCalculateTotalLoss()
        
      };
    
      const getPreviousDayObject = (data) => {
        if(!data){ 
          return calculateDiaryLoss(props.account.balance_actual, props.account.balance_actual)
        }
    
        // Get the balance from the day before
        const previousDay = new Date(Date.now() - 86400000); // 86400000 ms = 1 day
        const previousDayStr = previousDay.toISOString().slice(0, 10);
      
        let closestObject = null;
        let closestTime = null;
      
        for (const obj of data) {
          const objDateTime = new Date(obj.updated_at);
          const objDay = objDateTime.toISOString().slice(0, 10);
      
          if (objDay === previousDayStr) {
            if (closestObject === null || objDateTime > closestTime) {
              closestObject = obj;
              closestTime = objDateTime;
            }
          }
        }
    
        if(closestObject){
          calculateDiaryLoss(closestObject.balance, props.account.balance_actual)
        }else{
          calculateDiaryLoss(props.account.balance_actual, props.account.balance_actual)
        }
        return closestObject
      }
    
      const calculateGraphic = (history_operations) => {
        if(!props.account.created_at) return
        let initialFunds = parseFloat(props.account.initial_funds.split(".").join(""));
        let balance = initialFunds
        const operations_accounts = [...history_operations]
        console.log(operations_accounts)
        let array_data = []
        //Add the initial balance
        array_data.push(({label: new Date(props.account.created_at).toISOString().split("T").join("@").split(".")[0], balance: initialFunds}))
        if(props.account.server === "quotex"){
          // Set the data for the chart of the account
          operations_accounts.reverse().forEach((operation,i) => {
            console.log('operation',Number(operation.income))
            if(Number(operation.income) === 0){
               balance = balance - parseFloat(operation.amount);
            }
    
            if(Number(operation.income) > 0){
              balance = (balance - parseFloat(operation.amount)) + parseFloat(operation.income);
            }
            // Add the balance to the array
            array_data.push({ label: operation.close_date.split(" ").join("@"), balance: balance });
          })
    
          array_data.push({label: new Date().toISOString().split("T").join("@").split(".")[0], balance: parseFloat(props.account.balance_actual)})
          setDataChart(array_data)
        }
    
        // Set the data for the chart in iqoption
        if(props.account.server === "iqoption"){
          operations_accounts.reverse().forEach((operation) => {
    
         
              balance = (operation.win === "win" ? (balance - parseFloat(operation.amount)) + parseFloat(operation.win_amount) : balance - parseFloat(operation.amount))
    
              console.log(balance)
            
              array_data.push({label: formatDate(operation.expired), balance: balance})
          })
    
          array_data.push({label: new Date().toISOString().split("T").join("@").split(".")[0], balance: parseFloat(props.account.balance_actual)})
    
          setDataChart(array_data)
        }
    
      }
    
      const calculateMaxOperations = (history_operations) => {
        let operations
        if(props.account.server === "iqoption"){
          operations = history_operations.map((operation) => {
            return { count: operation.count, amount: operation.amount };
          });
        }
    
        if(props.account.server === "quotex"){
          operations = history_operations.map((operation) => {
            return { count: operation.count, amount: operation.amount, income: operation.income };
          });
        }
    
        const initial_funds = parseFloat(props.account.initial_funds.split(".").join(""));
        const percentage1 = 0.01; // 1%
        const percentage2 = 0.03; // 3%
    
        //Evaluates whether the amount and the transaction count do not exceed the allowed percentage
        let amountMax = 0;
        let countOperations = 0;
    
        if(props.account.server === "iqoption"){
            for (const operation of operations) {
              if (amountMax <= operation.amount) {
                amountMax = operation.amount;
                countOperations = operation.count;
              }
            }
        }
    
        if(props.account.server === "quotex"){
          let date_prev = ""
          let amoutMaxOperationSameDate = 0
          let countOperationsQuotex = 0
          // Evaluate if the amount and the transaction count do not exceed the allowed percentage
          for (const operation of operations) {
            if(date_prev === operation.close_date){
              amoutMaxOperationSameDate += parseFloat(operation.amount);
              countOperationsQuotex += 1
            }else{
              if(amountMax <= amoutMaxOperationSameDate){
                amountMax = amoutMaxOperationSameDate;
                amoutMaxOperationSameDate = 0
                countOperations = countOperationsQuotex
                countOperationsQuotex = 0
              }
            }
            // Evaluate if the amount is greater than the previous one
            if (amountMax <= parseFloat(operation.amount)) {
              countOperationsQuotex = 1
              countOperations = 1
              amountMax = parseFloat(operation.amount);
              
            }
    
            date_prev = operation.close_date
        }
      }
    
        if (countOperations === 1) {
          // Evalue if operation is equal to 1 and if the amount is more than 1%
          if (amountMax > initial_funds * percentage1) {
            const porcentaje = (amountMax / initial_funds) * 100;
            const porcentajeSuperado = Math.round(porcentaje);
            const cantidadSobrepasada = amountMax - initial_funds * percentage1;
            
            setOperationMax({
              value: `${porcentajeSuperado}% / ${cantidadSobrepasada}$`,
              passing: porcentajeSuperado <= 1 ? true : false,
            });
    
          }
        }
    
        if (countOperations >= 2) {
          // Evalue if the operations is more than 3 and if the amount is more than 3%
          if (amountMax > initial_funds * percentage2) {
            const porcentaje = (amountMax / initial_funds) * 100;
            const porcentajeSuperado = Math.round(porcentaje);
            const cantidadSobrepasada = amountMax - initial_funds * percentage2;
            setOperationMax({
              value: `${porcentajeSuperado}% / ${cantidadSobrepasada}$`,
              passing: porcentajeSuperado >= 4 ? false : true,
            });
    
          }
        }
    
      };

    useEffect(() => {
        calculateProfit(props.account.balance_actual); // Balance actual
        calculateTotalLoss(props.account.balance_actual); // Balance actual
        getPreviousDayObject(props.account.history_balance); // Balance history
        calculateMaxOperations(props.account.history_all); // History transactions
        calculateGraphic(props.account.history_all);
        
      }, [props.account]);
    
      
    
      const handleNext = () => {
        if (offSetList + 10 < props.account.history_all.length) {
          setoffSetList(offSetList + 10);
        }
      };
    
      const handlePrev = () => {
        if (offSetList > 0) {
          setoffSetList(offSetList - 10);
        }
      };
    
    
      return (
        <>
          <ModalAccountInfo
            show={showMod}
            user={modalInfo}
            handleClose={closeModal}
          />
          <div className="d-flex flex-column container-content-app container-account-details">
           { !props.isAdmin ? <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">
                  <HouseIcon /> Inicio
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/dashboard">Mis cuentas fondeadas</Link>
              </li>
              <li className="breadcrumb-item active">
                Examen - #{props.account.order_id}
              </li>
            </ul>
          :
            <ul className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to="#">
                    <HouseIcon /> Inicio
                </Link>
                </li>
                <li className="breadcrumb-item">Soporte</li>
                <li className="breadcrumb-item">
                    <Link to={'/admin/users'}>Usuarios</Link></li>
                    <li className="breadcrumb-item">
                    <Link to={'/admin/user/' + props.isAdmin}>#{props.isAdmin}</Link></li>
                <li className="breadcrumb-item active">Examen - #{props.account.order_id}</li>
            </ul> }
      
              <>
                <div className="d-flex flex-column flex-lg-row justify-content-lg-between mb-3">
                  <div className="col-12 col-lg-5">
                    <p className="m-0 w-100 text-white">
                      Examen{" "}
                      <span>
                        {
                          props.account.package || ''
                        }
                      </span>{" "}
                      -{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {props.account.server}
                      </span>{" "}
                      - #{props.account.order_id}
                    </p>
                  </div>
                  <div className="ms-3 ms-lg-0 mt-2 mt-lg-0 col-12 col-lg-7  d-flex flex-wrap align-items-center justify-content-lg-end gap-3">
                    <span className={`status account-${props.account.status}`}>
                      {STATUS_ACCOUNT[props.account.status]}
                    </span>
                    <button
                      className="btn-credencials"
                      type="button"
                      onClick={() => {
                        openModal();
                        setModalInfo({
                          title: "Informacion de la cuenta",
                          email: `${props.account.email}`,
                          password: `${props.account.password}`,
                          server: `${props.account.server}`,
                        });
                      }}
                    >
                      <img src={KeyIcon} className="icon-btn" alt="key-icon" />
                      Credenciales
                    </button>
                    <Link to="/#faqs" className="btn-credencials" type="button">
                      <BookIcon
                        style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "3px",
                        }}
                      />{" "}
                      Reglas
                    </Link>
                   {
                   props.account.status === "process" ?
                    <button className="btn-credencials" type="button" style={{opacity: activateButton ? '1' : '0.6'}}>
                      <StarIcon
                        style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "3px",
                        }}
                      />{" "}
                      Ascender
                    </button> : ''} 
                    
                    {props.account.status === "active" ? <Link style={{opacity: activateButton ? '1' : '0.6'}} className="btn-credencials"  to="#">
                    <WithdrawIcon  style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "3px",
                        }}/>
                    Retirar
                    </Link> : ''} 
                    
                  </div>
                </div>
                <div className="row flex-column-reverse flex-lg-row gap-3 gap-lg-0">
                  <div className="col-lg-8 col-12">
                    <section className="section-account-details">
                      <div className="row p-2">
                        <div className="d-flex flex-column overflow-hidden container-rules">
                          <div className="position-relative">
                            <div
                              class="rows-information row-active"
                              data-bs-toggle="collapse"
                              aria-expanded="true"
                            >
                              <div>
                                <h3 class="row-text">Objetivo del examen</h3>
                              </div>
                              <div class="content">
                                <h3 class="title-content position-relative">
                                  <span class="fade-content fade-active">
                                    Resultado actual
                                  </span>
                                </h3>
                              </div>
                              <div class="content">
                                <h3 class="title-content position-relative">
                                  <span class="fade-content fade-active">
                                    Resumen
                                  </span>
                                </h3>
                              </div>
                            </div>
                          </div>
    
                          {/* <!-- Information --> */}
                          <RowInfo
                            title="Objetivo de ganancia"
                            value={profiTarget["value"]}
                            passing={profiTarget["passing"]}
                            description="Para lograr superar el examen, el saldo de la cuenta deberá estar un 12% en positivo."
                            index={1}
                          />
                          <RowInfo
                            title="Perdida maxima diaria"
                            value={`${diaryLoss.percentageLoss}% / ${diaryLoss.lossValue}$ `}
                            passing={diaryLoss.percentageLoss >= 5 ? false : true}
                            description="Si el saldo de la cuenta al final del día baja un 5% con respecto a como inició el día, entonces no se aprueba el examen."
                            index={2}
                          />
                          <RowInfo
                            title="Perdida maxima total"
                            value={totalLoss["value"]}
                            passing={totalLoss["passing"]}
                            description="Si el saldo de la cuenta baja más del 8% no se aprueba el examen"
                            index={3}
                          />
    
                          <RowInfo
                            title="Monto por operación máximo"
                            value={operationMax["value"]}
                            passing={operationMax["passing"]}
                            text={props.account.history_all.length <= 0 ? 'Actualmente no disponible, intente mas tarde': ''}
                            description="El 1% del tamaño de su cuenta es el monto máximo permitido por cada operación en el examen y se permite un máximo de un 3% entre todas las operaciones abiertas al mismo tiempo"
                            index={4}
                          />
                        </div>
                      </div>
                    </section>
                  </div>
    
                  <div className="col-lg-4 col-12 p-lg-0">
                    <div className="d-flex flex-column align-items-start text-white container-account">
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Tamaño de la cuenta</strong>
                          </p>
                          <p className="m-0">
                            $
                            {Number(TYPE_ACCOUNT_FUNDED[props.account.package]).toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </p>
                        </div>
                      </div>
    
                      
    
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Tipo de cuenta</strong>
                          </p>
                          <p className="m-0" style={{ textTransform: "capitalize" }}>{props.account.server}</p>
                        </div>
                      </div>

                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                          <strong>Balance del examen <button style={{border:'none', background:'transparent', outline:'0'}} onClick={()=>{changeField('balance')}} type="button">{quotexField.balance ? <FaEye style={{fill:'white'}}/>: <FaEyeSlash style={{fill:'white'}}/>}</button> </strong>
                          </p>
                          <p className="m-0">
                           {quotexField.balance ? '$' +
                            Number(props.account.balance_actual)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".") : toAsterisk(String(props.account.balance_actual)) }
                          </p>
                        </div>
                      </div>

                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Balance inicial del examen</strong>
                          </p>
                          <p className="m-0">
                            $
                            {props.account.initial_funds.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </p>
                        </div>
                      </div>
    
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Fecha de creacion</strong>
                          </p>
                          <p className="m-0">
                            {new Date(props.account.created_at).toString() !==
                            "Invalid Date"
                              ? new Date(props.account.created_at)
                                  .toISOString()
                                  .slice(0, 10)
                                  .replaceAll("-", "/")
                              : ""}
                          </p>
                        </div>
                      </div>
    
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Ultima actualizacion</strong>
                          </p>
                          <p className="m-0">
                            {props.account.updated_at
                              ? new Date(props.account.updated_at).toString() !==
                                "Invalid Date"
                                ? new Date(props.account.updated_at)
                                    .toISOString()
                                    .slice(0, 10)
                                    .replaceAll("-", "/")
                                : ""
                              : ""}
    
                            {!props.account.updated_at
                              ? new Date(props.account.created_at).toString() !==
                                "Invalid Date"
                                ? new Date(props.account.created_at)
                                    .toISOString()
                                    .slice(0, 10)
                                    .replaceAll("-", "/")
                                : ""
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
              { dataChart.length !== 0 ? <> <h3 className="title">Estado del balance</h3>
                <div
                  className="container-chart mt-2"
                  style={{
                    height: "380px !important",
                    minHeight: "380px",
                    padding: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <Line
                    data={{
                      labels: dataChart.map((data) => data.label),
                      datasets: [
                        {
                          label: "",
                          data: Array.from({ length: dataChart.length }, (_, i) => parseFloat(props.account.initial_funds.split(".").join(""))),
                          backgroundColor: "rgba(176,239,150,0.9)",
                          borderColor: "rgba(176,239,150,1)",
                          pointStyle: "rectRounded",
                          radius: 0,
                        },
                        {
                          label: "Balance",
                          data: dataChart.map((data) => data.balance),
                          backgroundColor: "rgba(75,192,192,0.9)",
                          borderColor: "rgba(75,192,192,1)",
                          radius:5
                        },
                        
                      ],
                    }}
                    options={{
                      color: "white",
                      plugins: {
                        legend: {
                          color: "#fff",
                          labels: {
                            filter: (legendItem, data, ticks) => {
                              return (
                                data.datasets[legendItem.datasetIndex].label !== ""
                              );
                            },
                            color: "#fff",
                            padding: 0,
                            font: {
                              size: 15,
                            },
                          },
                        },
                        labels: {
                          filter: (legendItem, data, ticks) => {
                            return (
                              data.datasets[legendItem.datasetIndex].label !== ""
                            );
                          },
                          color: "#fff",
                        },
                      },
     
                      scales: {
                        y: {
                          title: {
                            color: "#fff",
                          },
                          max: calculateMax(), //  max balance registered
                          min: calculateMin(), //  min balance registered
                          ticks: {
                            stepSize: 0,
                            padding: 5,
                            precision:3,
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                              return "$" + value.toFixed(0);
                            },
                            font: {
                              size: 14,
                            },
                          },
                        },
                        x: {
                          ticks: {
                            minRotation: 30,
                            maxTicksLimit: 100,
                            backdropPadding: 5,
                            padding: 5,
    
                            font: {
                              size: 11,
                            },
                          },
                        },
                      },
                    }}
                    ref={chartRef}
                  />
                </div> </>: ''}
    
                { props.account.history_all.length > 0 ?
                <div className="d-flex flex-column">
                  <h3 className="title">Historico de operaciones</h3>
                  <div className="container-orders">

                    <div className={`orders-label ${props.account.server === 'quotex' ? 'label-quotex' : ''}`} >
                      <p >Fecha inicio <button type="button" onClick={()=>{changeField('created')}}>{quotexField.created ? <FaEye/> : <FaEyeSlash/>}</button></p>
                      <p >Activo <button onClick={()=>{changeField('active')}} type="button">{quotexField.active ? <FaEye/>: <FaEyeSlash/>}</button> </p>
                      <p>Monto<button type="button" onClick={()=>{changeField('amount')}}>{quotexField.amount ? <FaEye/> : <FaEyeSlash/>}</button></p>
                      {props.account.server === "quotex" ? <p >Direccion <button type="button" onClick={()=>{changeField('direction')}}>{quotexField.direction ? <FaEye/> : <FaEyeSlash/>}</button></p> : ''}
                      <p>Estado <button type="button" onClick={()=>{changeField('status')}}>{quotexField.status ? <FaEye/> : <FaEyeSlash/>}</button></p>
                      <p >Fecha cierre <button type="button" onClick={()=>{changeField('expired')}}>{quotexField.expired ? <FaEye/> : <FaEyeSlash/>}</button></p>
                    </div>
                    <div className="orders-data">
                      {props.account.server === "iqoption" ? props.account.history_all?.slice(offSetList, offSetList + 10).map((data, index) => {
                          return (
                            <div className="orders-row" key={index}>
                              <p className="data-field data-initial"  >
                                <span className="label-title">
                                  Fecha de inicio: <button type="button" onClick={()=>{changeField('created')}}>{quotexField.created ? <FaEye/> : <FaEyeSlash/>}</button>
                                </span>
                                {quotexField.created ? formatDate(data.created) : toAsterisk(formatDate(data.created))}
                              </p>
                              <p className="data-active" >
                                <span className="label-title">Activo: <button onClick={()=>{changeField('active')}} type="button">{quotexField.active ? <FaEye/>: <FaEyeSlash/>}</button></span>
                                {quotexField.active ? data.active : toAsterisk(data.active)}
                              </p>
                              <p className="data-amount">
                                <span className="label-title">Monto: <button type="button" onClick={()=>{changeField('amount')}}>{quotexField.amount ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                <span className="ind">{quotexField.amount ? '$'+data.amount : toAsterisk(String(data.amount))}</span>
                              </p>
                              <p className={`${data.win} data-win`}>
                                <span className="label-title">Estado: <button type="button" onClick={()=>{changeField('status')}}>{quotexField.status ? <FaEye/> : <FaEyeSlash/>}</button></span>
                               { quotexField.status ? <span className="ind">
                                  {data.win === "win" ? (
                                    <div className="icon-check position-relative">
                                      <span className="bg-aux"></span>
                                      <img src={CheckIcon} alt="check" />
                                    </div>
                                  ) : (
                                    <div className="icon-check position-relative">
                                      <span className="bg-aux"></span>
                                      <img src={CrossIcon} alt="cross" />
                                    </div>
                                  )}
                                </span> : <span style={{color:'white'}}>*</span>}
                              </p>
                              <p className="data-field data-end" >
                                <span className="label-title">Fecha cierre: <button type="button" onClick={()=>{changeField('expired')}}>{quotexField.expired ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                {quotexField.expired ? formatDate(data.expired) : toAsterisk(formatDate(data.expired))}
                              </p>
                            </div>
                          );
                        }) : ''}
    
                    {props.account.server === "quotex" ? props.account.history_all?.slice(offSetList, offSetList + 10).map((data, index) => {
                          return (
                            <div className="orders-row order-row-quotex"  key={index}>
                              <p className="data-field data-initial" >
                                <span>
                                    <span className="label-title" >
                                    Fecha de inicio:<button type="button" onClick={()=>{changeField('created')}}>{quotexField.created ? <FaEye/> : <FaEyeSlash/>}</button>
                                    </span>
                                    {quotexField.created ? data.open_date : toAsterisk(data.open_date)}
                                </span>
                              </p>
                              <p className="data-active" >
                                <span>
                                    <span className="label-title">Activo:<button type="button" onClick={()=>{changeField('active')}}>{quotexField.active ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                    {quotexField.active ? data.asset : toAsterisk(data.asset)}
                                </span>
                              </p>
                              <p className="data-amount">
                                <span>
                                  <span className="label-title" >Monto:<button type="button" onClick={()=>{changeField('amount')}}>{quotexField.amount ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                  <span className="ind">{quotexField.amount ? '$'+data.amount : toAsterisk(data.amount)}</span>
                                </span>
                              </p>
                              <p className="data-field data-direction" >
                                <span>
                                <span className="label-title">Direccion:<button type="button" onClick={()=>{changeField('direction')}}>{quotexField.direction ? <FaEye/> : <FaEyeSlash/>}</button></span>
                                {quotexField.direction ? data.direction.toLowerCase() === "down" || data.direction.toLowerCase() === "abajo" ?  <span className="arrow-down"></span> : '' :'' } 
                                {quotexField.direction ? data.direction.toLowerCase() === "arriba" || data.direction.toLowerCase() === "up" ? <span className="arrow-up"></span> : '' : ''} 
                                {!quotexField.direction ? "*" : ''}
                                </span>
                              </p>
                              <p className={`${data.income === 0 ? 'loose' : 'win'} data-win`}>
                                <span>
                                <span className="label-title" >Estado:<button type="button" onClick={()=>{changeField('status')}}>{quotexField.status ? <FaEye/> : <FaEyeSlash/>}</button></span>
                               { quotexField.status ? <span className="ind">
                                  {data.income > 0  ? (
                                    <div className="icon-check position-relative">
                                      <span className="bg-aux"></span>
                                      <img src={CheckIcon} alt="check" />
                                    </div>
                                  ) : (
                                    <div className="icon-check position-relative">
                                      <span className="bg-aux"></span>
                                      <img src={CrossIcon} alt="cross" />
                                    </div>
                                  )}
                                </span>  : <span style={{color:'white'}}>*</span>}
                                </span>
                              </p>
                              <p className="data-field data-end" >
                                <span>
                                <span className="label-title">Fecha cierre:<button type="button" onClick={()=>{changeField('expired')}}><FaEye/></button></span>
                                {quotexField.expired ? data.close_date : toAsterisk(data.close_date)}
                                </span>
                              </p>
                            </div>
                          );
                        }) : ''}
                    </div>
                  </div>
                  <p className="text-white mt-1 mb-2">
                    Cantidad de operaciones registradas:{" "}
                    {props.account.history_all.length}
                  </p>
                  <div className="d-flex align-items-center gap-2 mt-2 justify-content-between">
                    <button
                      className="button-send-transparent"
                      onClick={() => {
                        handlePrev();
                      }}
                      style={{
                        textTransform: "capitalize",
                        padding: "10px 20px",
                        width: "max-content",
                      }}
                    >
                      Anterior
                    </button>
                    <button
                      className="button-send-transparent"
                      onClick={() => {
                        handleNext();
                      }}
                      style={{
                        textTransform: "capitalize",
                        padding: "10px 20px",
                        width: "max-content",
                      }}
                    >
                      Siguiente
                    </button>
                  </div>
                </div> : <div className="d-flex flex-column">
                <h3 className="title">Historico de operaciones</h3>
                <p className="text-white">Actualmente no hay operaciones registradas, intente mas tarde</p>
                </div>
                }
              </>
   
    
            
          </div>
        </>
      );
}

export default AccountDemo