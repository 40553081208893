// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'


import {
    getDatabase,
    ref,
    query,
    get,
  } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBCfMFXav3uHLfTGwGQzIZtEF7GChe-Tdc",
  authDomain: "binaryfunded-3ce11.firebaseapp.com",
  projectId: "binaryfunded-3ce11",
  databaseUrl: "https://binaryfunded-3ce11-default-rtdb.firebaseio.com/",
  storageBucket: "binaryfunded-3ce11.appspot.com",
  messagingSenderId: "633007911958",
  appId: "1:633007911958:web:8fc528345d32c20b3d3137",
  measurementId: "G-W0R5FFFK29"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

 // Retrieve user details
const getUserDetails = async () => {
 try{
  const db = getDatabase(app);
  if(!auth){
    return
  }
  const userId = auth.currentUser.uid;
  const dataRequest = ref(db, `users/${userId}`)
  const userQuery = query(
      dataRequest
  );

    const data_previous = await get(userQuery);
    if(data_previous.exists()){
      
      const userIdData = Object.keys(data_previous.val())[0];
      const dataUser = data_previous.val()[userIdData]
      return {...dataUser, admin:data_previous.val().admin || false}
    }
 }catch(err){

  console.log(err)
  return
}
};

export {auth, app, getUserDetails};