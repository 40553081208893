import { useState,useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import HouseIcon from "../../components/icons/HouseIcon";
import { useNavigate } from "react-router-dom";
import { app} from "../../firebase/firebase";
import "../../components/Users/users.css";
import AccountsUsers from "../../components/Dashboard/AccountsUsers";
import ModalAccountAdmin from "../../components/Modals/ModalAccountAdmin"
import {
    getDatabase,
    ref,
    query,
    get,
  } from "firebase/database";

const UserDetail = (props) =>{

    const { userId } = useParams();
    const [user,setUser] = useState({
        first_name:'',
        last_name:'',
        created_at:'',
        email:'',
        isBuyer:0
    })
    const [load,setLoad] = useState(false)
    const [error,setOnError] = useState(true)
    const[refresh,setRefresh] = useState(false)
    const [showModal,setShowModal] = useState(false)

    const request = async ()=>{
        try{
            /// Request and show the data from the user
            const db = getDatabase(app);
            const ref_data = ref(db, `users/${userId}`);
            const data = await get(query(ref_data));
            if(data.exists()){
                const accounts = data.val()['accounts'] ? Object.keys( data.val()['accounts'])  : []
                const userKey = Object.keys(data.val())
                const userData = data.val()[userKey[0]]
                const isBuyer = accounts.length
                setUser({...userData, isBuyer})
            }
        }catch(e){
            console.log(e)
        }
    }

    const handleModalAdmin = (show) =>{
        setShowModal(show)
    }

    const handleRefreshData = (value) =>{
        setRefresh(value)
        setTimeout(()=>{
            setRefresh(false)
        },2000)
    }

    useEffect(()=>{
        request()
    },[])
    return(
        <>
        <ModalAccountAdmin created={true} handleRefreshData={handleRefreshData} edit={false} show={showModal} accountId={""} userId={user.userId} handleModalAdmin={handleModalAdmin}/>
        <div className="d-flex flex-column container-content-app">
            <ul className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to="#">
                    <HouseIcon /> Inicio
                </Link>
                </li>
                <li className="breadcrumb-item">Soporte</li>
                <li className="breadcrumb-item">
                    <Link to={'/admin/users'}>Usuarios</Link></li>
                <li className="breadcrumb-item active">{`${user.first_name} ${user.last_name}`} - #{user.userId}</li>
            </ul>
            <div className="container-admin-users container-admin-user mb-5">
                <section className="section-users-admin container-page-users  section-content-page ">
                    <div className="container">
                <h2 className="title-section title-dashboard-user text-center text-lg-start mb-lg-0" style={{    borderBottom: '2px solid #b0ef96',
paddingBottom: '5px'}}>Datos del usuario</h2>
                    <div className="content-info-user">
                    <div className="row mt-2">
                        <div className="col-12 col-lg-6  mt-1 mt-lg-0">
                            <label className="text-white fw-bolder">Nombre del usuario</label>
                            <p className="text-white m-0">{user.first_name}</p>
                        </div>
                        <div className="col-12 col-lg-6  mt-1 mt-lg-0">
                            <label className="text-white fw-bolder">Apellido del usuario</label>
                            <p className="text-white m-0">{user.last_name}</p>
                        </div>
                    </div>
                    <div className="row mt-0 mt-lg-3">
                        <div className="col-12 col-lg-6 mt-1 mt-lg-0">
                            <label className="text-white fw-bolder">Fecha de registro</label>
                            <p className="text-white m-0">{new Date(user.created_at).toString() !== "Invalid Date" ? new Date(user.created_at).toISOString().slice(0, 10).replaceAll("-", "/") : ''}</p>
                        </div>
                        <div className="col-12 col-lg-6  mt-1 mt-lg-0">
                            <label className="text-white fw-bolder">Tipo de usuario</label>
                            <p className="text-white m-0">{user.isBuyer > 0 ? 'Cliente' : 'Usuario'}</p>
                        </div>
                    </div>

                    <div className="row mt-0 mt-lg-3">
                        <div className="col-12 col-lg-12  mt-1 mt-lg-0">
                            <label className="text-white fw-bolder">Correo</label>
                            <p className="text-white m-0">{user.email}</p>
                        </div>

                    </div>

                    </div>
                    <div className="row mt-3 mb-2">
                        <div className="col-12 col-lg-5 d-flex align-items-center ">
                            <h3 className="title-section title-dashboard-user text-center text-lg-start mb-lg-0">Cuentas fondeadas</h3>
                        </div>
                        <div className="col-12 col-lg-7 d-flex justify-content-end">
                            <button className="button-send-transparent" onClick={()=>{handleModalAdmin(true)}}>Agregar cuenta</button>
                        </div>
                    </div>

                    <div className="container-accounts-users">
                        <AccountsUsers isAdmin={true} userId={userId} refresh={refresh} />
                    </div>
                    </div>
                </section>
            </div>
        </div>
        </>
    )
}

export default UserDetail