import * as React from "react"
const TopIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={44}
    fill="white"
    {...props}
  >
    <path
      fill="white"
      d="M37.913 0H2.087C.939 0 0 .92 0 2.044v10.444c0 .614.271 1.186.751 1.574l15.983 12.98-7.031 5.763c-.48.389-.752.961-.752 1.574v3.577c0 1.124.94 2.044 2.087 2.044h17.903c1.148 0 2.087-.92 2.087-2.044V34.38c0-.613-.272-1.185-.752-1.574l-7.031-5.764 15.983-12.979c.48-.388.751-.96.751-1.573V2.043C40 .92 39.061 0 37.913 0ZM19.99 24.384l-7.992-6.5V4.088h16.025v13.796l-8.033 6.5ZM4.173 4.088h3.652v10.404l-3.652-2.964v-7.44Zm22.702 31.824h-13.75v-.572l6.865-5.621 6.865 5.62v.573h.02Zm8.952-24.384-3.652 2.964V4.088h3.652v7.44Z"
    />
  </svg>
)
export default TopIcon
