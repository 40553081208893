import * as React from "react"
const FlagIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="#fff"
    viewBox="0 -960 960 960"
    {...props}
  >
    <path d="M200-120v-680h348.67l18 84H800v380H536l-18-83.33H266.67V-120H200Z" />
  </svg>
)
export default FlagIcon