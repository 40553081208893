import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../components/AccountInformation/account-information.css";
import { Link } from "react-router-dom";
import { color, isArray } from "chart.js/helpers";
import { onAuthStateChanged } from "firebase/auth";
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  get,
  equalTo,
} from "firebase/database";
import AccountDemo from "../components/AccountInformation/AccountDemo";
import { app, auth } from "../firebase/firebase";
import AccountReal from "../components/AccountInformation/AccountReal";
import AccountSuspense from "../components/AccountInformation/AccountSuspense";

const AccountInformation = () => {
  const { userId, accountId } = useParams();
  const [uid, setUid] = useState("");
  const navigate = useNavigate()
  const[typeAccount, setTypeAccount] = useState("");
  const [showAccount, setShowAccount] = useState(false);
  const [load, setLoad] = useState(false);
  const [account, setAccount] = useState({
    order_id: "",
    email: "",
    password: "",
    server: "",
    package:"",
    initial_funds: "0",
    status: "",
    total_max_loss:0,
    balance_actual:0,
    balance_actual_real:0,
    history_all: [],
    history_balance: [],
    history_withdraw:[],
    history_balance_real:[],
  });
  const requestAccount = useCallback(async () => {
    try {
      setLoad(false);
      setShowAccount(false);
      const db = getDatabase(app);
      const dataUserAccounts = ref(db, `users/${uid}/accounts`);
      const queryAccount = query(
        dataUserAccounts,
        orderByChild("order_id"),
        equalTo(String(accountId))
      );
      const getAccount = await get(queryAccount);
      if (!getAccount.exists()) {
        return setShowAccount(false);
      }

      //formating data
      const key = Object.keys(getAccount.val())[0];
      const data = getAccount.val()[key];
      //Set the data from server
      if(!data.testing_account){
        navigate('/dashboard')
      }

      let history_operations = []

      if(data.history_operations && data.status === "process"){
        history_operations = data.history_operations
      }

      if(data.status === "active" && data.history_operations_real){
        history_operations = data.history_operations_real
      }
 
      if(data.server === "iqoption"){
        setAccount({
          ...data,
          history_all: history_operations ,
          balance_actual: data.balance_actual || parseFloat(data.initial_funds.split(".").join("")),
          total_max_loss: data.total_max_loss || 0,
          balance_actual_real: data.balance_actual_real || 0
        });
      }
  
      if(data.server === "quotex"){
        setAccount({
          ...data,
          history_all: history_operations ,
          balance_actual: data.balance_actual || parseFloat(data.initial_funds.split(".").join("")),
          total_max_loss: data.total_max_loss || 0,
          balance_actual_real: data.balance_actual_real || 0
        })
      }

      setTypeAccount(data.status)

      // history balance pratice
      let history_balance_data = []
      if(data.history_balance){
        const history_balance_keys = isArray(data.history_balance) === false ? Object.keys(data.history_balance) : data.history_balance
        const history_balance = isArray(data.history_balance) === false ? history_balance_keys.map((key_balance) => {
          return data.history_balance[key_balance];
        }) : data.history_balance
        history_balance_data = history_balance

   }

      //history balance real
      let history_balance_real_data = []
      if(data.history_balance_real){
        const history_balance_real_keys = isArray(data.history_balance_real) === false ? Object.keys(data.history_balance_real) : data.history_balance_real
        const history_balance = isArray(data.history_balance_real) === false ? history_balance_real_keys.map((key_balance) => {
          return data.history_balance_real[key_balance];
        }) : data.history_balance_real
        history_balance_real_data = history_balance

   }

      let history_withdraw_data = []
        if(data.history_withdraw){
          //Set history withdraw
          const history_withdraw_key = isArray(data.history_withdraw) === false ? Object.keys(data.history_withdraw) : data.history_withdraw
          const history_withdraw = isArray(data.history_withdraw) === false ? history_withdraw_key.map((key_balance) => {
            return data.history_withdraw[key_balance];
          }) : data.history_withdraw
          history_withdraw_data = history_withdraw
        }

        setAccount({...data, 
          history_all: history_operations ,
          balance_actual: data.balance_actual || parseFloat(data.initial_funds.split(".").join("")),
          total_max_loss: data.total_max_loss || 0, 
          history_balance: history_balance_data,
          history_withdraw: history_withdraw_data,
         history_balance_real: history_balance_real_data,
         balance_actual_real: data.balance_actual_real || 0
        })

      setLoad(true);
      setShowAccount(true);


    } catch (e) {
      setLoad(true);
      setShowAccount(false);
    }
  }, [uid, accountId]);

  useEffect(() => {
    if(!userId){ // if exists userId admin allow request the data
    onAuthStateChanged(auth, (user) => {
      setUid(user.uid);
    });
  }else{
    setUid(userId)
  }
  }, []);

  useEffect(() => {
    if (uid) {
      requestAccount();
    }
  }, [requestAccount, uid]);

  return(
    <>
    {!load ? (
      <div className="d-flex flex-column justify-content-center align-items-center m-auto">
        <div className="d-flex flex-column align-items-center justify-content-center mb-4 mb-lg-0">
          <div className="lds-dual-ring"></div>
        </div>
      </div>
    ) : (
      ""
    )}

        {load && showAccount === false ? (
              <>
                <div className="advice-account-not-found p-4 text-center text-white">
                  <h2>Ocurrio un error al intentar cargar la cuenta</h2>
                  <p>Puede intentar lo siguiente</p>
                  <div className="d-flex flex-wrap justify-content-center gap-3">
                    <button
                      className="btn"
                      onClick={() => {
                        requestAccount();
                      }}
                    >
                      Recargar
                    </button>
                    <Link className="btn d-flex align-items-center" to="/dashboard">
                      Volver a mis cuentas fondeadas
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}


          {
            load && typeAccount === "process" ? (<>
            <AccountDemo account={account} isAdmin={userId || false} accountId={accountId} setAccount={setAccount}/>
            </>) : ''
          }

{
            load && typeAccount === "active" ? (<>
            <AccountReal account={account} isAdmin={userId || false}  accountId={accountId} setAccount={setAccount}/>
            </>) : ''
          }

          {
            load && typeAccount === "suspense" ? (
            <>
            <AccountSuspense account={account} isAdmin={userId || false}  accountId={accountId} setAccount={setAccount}/>
            </>) : ''
          }
    </>
  )
};

export default AccountInformation;
