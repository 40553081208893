import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, getUserDetails } from '../../firebase/firebase';
import './Navbar.css';
import Logo from '../../assets/Logo-01.png';
import { FaRegUserCircle } from "react-icons/fa";
const NavbarWhenUserLogin = () => {
  const [user, setUser] = useState(null);
  const [uid,setUid] = useState("")
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [username,setUsename] = useState("")
  const [admin,setIsAdmin] = useState(null)
  const getDataUser = async () =>{
    try{
      const data = await getUserDetails()
      setUsename(data.first_name + ' ' + data.last_name)
      setIsAdmin(data.admin)
      localStorage.setItem(
        "username",
        `${data.first_name} ${data.last_name}`
      );

      localStorage.setItem(
        "email",
        `${data.email}`
      );
    }catch(err){
      console.log(err)
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        
      } else {
        setUser(null);
        navigate("/Login");
      }
    });
    return () => unsubscribe();
  }, [navigate]);


  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      setUid(user.uid)
    });
  },[])

  useEffect(()=>{
    if(uid){
      getDataUser()
    }
  },[uid])


  const location = useLocation();
  const lastHash = useRef('');

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className='container'>
      <nav className={`navbar navbar-session fixed-top navbar-expand-lg navbar-dark ${isMenuOpen ? 'show' : ''}`}>
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="logo" className='logo' />
          </a>
          <button className={`navbar-toggler ${isMenuOpen ? '' : 'collapsed'}`} type="button" onClick={toggleMenu} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen ? 'true' : 'false'} aria-label="Toggle navigation">
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
          {user && <Link style={{textDecoration:'none'}} to="/dashboard" className='start-btn text-white mb-0 small  user-email d-lg-none'>{username || ''} <FaRegUserCircle /></Link>}
          
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <div className='container-navbar-mobile'>
            <ul className="navbar-nav ul-bg ms-lg-auto mb-2 mb-lg-0">
            <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/" onClick={handleMenuItemClick}>Inicio</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#faqs"onClick={handleMenuItemClick}>Examen y <span style={{margin: '0 1px'}}>R</span>eglas</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#community" onClick={handleMenuItemClick}>Comunidad</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/support" onClick={handleMenuItemClick}>Soporte</Link>
                </li>
              </ul>
              <div className='nav-btns align-items-center'>
                <button className="logout-btn d-lg-block " type="button" onClick={handleLogout}>Cerrar sesion</button>
                {user && <Link style={{textDecoration:'none'}} to={`${admin === null ? '#' : admin === true ? '/admin/support/tickets' : '/dashboard'}`}><p className='start-btn text-white d-none d-lg-flex mb-0 small user-email user-email-inner'>{username || ''} <FaRegUserCircle /></p></Link>}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default NavbarWhenUserLogin;
