import axios from "axios"
//const URL = 'http://localhost:8000'
export const cardPayment = async (payment) =>{
    try{
        const response = await axios.post(`/payments/cards/generate`,payment)
        const data = await response.data
        return {...data, status:response.status}
    }catch(e){
        console.log(e)
        return {"status":e.response?.status || 500,"message":"No se pudo efectuar el pago, verifique los datos"}
    }
}

export const cardPaymentCheck = async (reference,user_id) =>{
    try{
        const response = await axios.post(`/payments/cards/verify/${reference}/user/${user_id}`)
        const data = await response.data
        return {...data, status:response.status}
    }catch(e){
        console.log(e)
        return {"status":e.response?.status || 500,"message":"No se pudo verificar el pago"}
    }
}