import React, { useState } from 'react';
import PlusIcon from '../../assets/icons/plus-icon.png'
import MinusIcon from '../../assets/icons/minus.png'
const PriceRanger = () => {
 // const [profit, setProfit] = useState(5000); // Initial profit value
 const [positionProfit,setPositionProfit] = useState(0)
  const [percentage, setPercentage] = useState(0.1); // Initial percentage range value
  const LIST = {
    0: {price:'2k', amount:2000},
    1:  {price:'5k', amount:5000},
    2: {price:'10k',amount:10000},
    3: {price:'25k',amount:25000},
    4:  {price:'50k',amount:50000},
    5:  {price:'100k',amount:100000}
}
const handleIncreaseProfit = (value) =>{
  const optionsLength = Object.keys(LIST).length
  console.log(optionsLength)
  if(value === true && positionProfit < optionsLength - 1){
    setPositionProfit((prev)=> prev + 1)
  }

  if(value === false && positionProfit > 0){
    setPositionProfit((prev)=> prev - 1)
  }

}
/*
  const handleIncreaseProfit = () => {
    if (profit < 1500000) {
      setProfit(prevProfit => prevProfit + 5000);
    }
  };

  const handleDecreaseProfit = () => {
    if (profit > 5000) {
      setProfit(prevProfit => prevProfit - 5000);
    }
  };
*/
  const handlePercentageChange = (e) => {
    setPercentage(parseFloat(e.target.value));
  };

  const calculatedProfit = Math.round((LIST[positionProfit].amount * percentage) / 100);

  return (
    <>
    <h5 className='estimate-profit-text fw-bold'>¡Estime sus ganancias!</h5>
    <div className="price-ranger">
      <div className="profit-buttons">
        <button onClick={()=>{handleIncreaseProfit(false)}} className='subtract-btnn' disabled={positionProfit === 0}><img style={{width:'14px',height:'14px', objectFit:'contain', filter: 'invert(1)'}} alt="minus-icon" src={MinusIcon} /></button>
        <div className="profit-value"><span>${LIST[positionProfit].amount} </span><span style={{fontSize: '18px', color: '#21262a', fontWeight: 400}}><span className="div-bar">/</span>  USD en fondos</span></div>
        <button onClick={()=>{handleIncreaseProfit(true)}} className='add-btnn'><img style={{width:'14px',height:'14px', objectFit:'contain', filter: 'invert(1)'}} src={PlusIcon} alt="plus-icon"/></button>
      </div>
      <div className="percentage-range">
      <div className='text-center text-white fs-3 fw-bold'>{percentage}%</div>
        <input type="range" min="0.1" max="12" step="0.1" value={percentage} className='range-field' onChange={handlePercentageChange} />
      </div>
      <div className="calculated-profit">
        <span className='profit-text'>${calculatedProfit}</span><span className='fs-5'><span style={{    fontSize: '40px'}}>/</span> al mes en ganancias</span></div>
    </div>
    </>

  );
};

export default PriceRanger;
