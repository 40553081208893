import {useState, useRef, useLayoutEffect, useEffect} from "react"
import { useNavigate } from "react-router-dom";

import { PRICE_TO_PAY, LIST_BROKER } from "../../utils";

import '../Home/list_price.css'
import './choose-packages.css'
const CheckoutChoosePackages = (props) =>{
    const navigate = useNavigate();
    const [priceSelected, setPriceSelected] = useState(JSON.parse(localStorage.getItem("to_pay")) ? JSON.parse(localStorage.getItem("to_pay")).name : "50k");
    const [brokerSelected, setBrokerSelected] = useState(JSON.parse(localStorage.getItem("to_pay")) ? Number(JSON.parse(localStorage.getItem("to_pay")).position_broker) : 1  );
   // const [interactivePrice,setInteractivePrice] = useState(899)
    const ref = useRef(null);
    let sliderRef = useRef(null);
  
    //Listen when windows resize
    const useWindowSize = () => {
      const [size, setSize] = useState([0, 0]);
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
  
        return () => window.removeEventListener("resize", updateSize);
      }, []);
      return size;
    };
  
    const [width, height] = useWindowSize();

  
    const LIST_PRICES = Object.keys(PRICE_TO_PAY);
    const POPULARS_PRICE = ["50k"];

    const selectBroker = (index) => {
        setBrokerSelected(index);
      };

      const selectPrice = (price) => {
        setPriceSelected(price || "50k");
        const index = LIST_PRICES.findIndex((el) => el === price) || 0;
        props.handleChangePackage({name:`${price}`, broker:LIST_BROKER[brokerSelected].broker, pay_amount:PRICE_TO_PAY[price].pay, position_broker:brokerSelected})
        //sliderRef.slickGoTo(index);
      };

    useEffect(()=>{
      props.handleChangePackage({name:`${priceSelected}`, broker:LIST_BROKER[brokerSelected].broker, pay_amount:PRICE_TO_PAY[priceSelected].pay, position_broker:brokerSelected})
    },[brokerSelected])

    useEffect(()=>{
        const dataPay = JSON.parse(localStorage.getItem("to_pay")) || false
        if(!dataPay || !dataPay.pay_amount || !dataPay.broker || !dataPay.position_broker){
            return props.handleChangePackage({name:`${priceSelected}`, broker:LIST_BROKER[brokerSelected].broker, position_broker:brokerSelected, pay_amount:PRICE_TO_PAY[priceSelected].pay})
        }

        setPriceSelected(dataPay.name || "50k");
        props.handleChangePackage({name:`${dataPay.name}`, broker:LIST_BROKER[dataPay.position_broker].broker, position_broker:dataPay.position_broker || 1, pay_amount:dataPay.pay_amount})
    },[])
    return (
        <div className="flex flex-column section-price w-100 choose-package-section">
          <h3>Elige en qué broker</h3>
             <ul className="list-trade">
                {LIST_BROKER.map((elm, index) => {
                return (
                    <li key={index}>
                    <button
                    type="button"
                        onClick={() => {
                        if(elm.active){ selectBroker(index)}
                        }}
                        className={`button-traders ${
                        elm.active ? "trader-active" : "trader-not-active"
                        }   ${brokerSelected === index ? "selected-trader" : ""}`}
                    >
                        <img
                        className="logo-trader"
                        src={elm.logo}
                        alt={elm.broker}
                        ></img>
                        <span
                        className={`${
                            elm.active && brokerSelected === index
                            ? "name-active"
                            : "name-inactive"
                        } name`}
                        >
                        {elm.broker}
                        </span>
                    </button>
                    </li>
                );
                })}
            </ul>
          <h3>Escoge el tamaño de tu cuenta</h3>
            <ul className="list-price list-desktop">
          {LIST_PRICES.map((el, index) => {
            return (
              <li key={index}>
                <button
                  onClick={() => {
                    selectPrice(el);
                  }}
                  type="button"
                  className={`button-price ${
                    priceSelected === el
                      ? "button-price-active"
                      : "button-price-inactive"
                  } `}
                >
                  {el}
                  {POPULARS_PRICE.includes(el) ? (
                    <span className="badge">Popular</span>
                  ) : (
                    ""
                  )}
                </button>
              </li>
            );
          })}
        </ul>

        <div className="col-12 justify-content-center ">
          <div className="table-list-trader table-list-packages" ref={ref}>
            <div className="rows-active">
              <div></div>
              <div className="d-flex flex-column justify-content-center">
                <h3 className="rows-title">Examen</h3>
              </div>
              <div className="d-flex flex-column justify-content-center">
                <h3 className="rows-title">Trader Fondeado</h3>
              </div>
            </div>
            <div className="rows-inactive collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOneItem" aria-expanded="true" aria-controls="collapseOneItem">
              <div>
                <h3 className="row-text">Objetivo de ganancias</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list">

                <div id="collapseOneItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div >
                  Para lograr superar el examen, el saldo de la cuenta deberá estar un 12% en positivo.
                  </div>
                </div>
            </div>

            <div className="rows-active collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwoItem" aria-expanded="true" aria-controls="collapseTwoItem">
              <div>
                <h3 className="row-text">Mercados otc</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                </h3>
              </div>
            </div>
              <div class="accordion-item-list accordion-item-list-two">

                <div id="collapseTwoItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div >
                  Puede operar todos los dias incluidos fin de semanas gracias a que aceptamos operaciones en mercado OTC
                  </div>
                </div>
              </div>
            <div className="rows-inactive collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThreeItem" aria-expanded="true" aria-controls="collapseThreeItem">
              <div>
                <h3 className="row-text">Pérdida máxima diaria</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list">
              <div id="collapseThreeItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                Si el saldo de la cuenta al final del día baja un 5% con respecto a como inició el día, entonces no se aprueba el examen.
                </div>
              </div>
            </div>
            <div className="rows-active collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFourItem" aria-expanded="true" aria-controls="collapseFourItem">
              <div>
                <h3 className="row-text">Pérdida máxima total</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list accordion-item-list-two">
              <div id="collapseFourItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                Si el saldo de la cuenta baja más del 8% no se aprueba el examen
                </div>
              </div>
            </div>
            <div className="rows-inactive collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFiveItem" aria-expanded="true" aria-controls="collapseFiveItem">
              <div>
                <h3 className="row-text">Tiempo para cumplir el objetivo</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                   ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list">
              <div id="collapseFiveItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                No tiene un tiempo máximo para aprobar el examen, hágalo a su ritmo y sin presión.
                </div>
              </div>
            </div>
        <div className="rows-active collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSixItem" aria-expanded="true" aria-controls="collapseSixItem">
          <div>
            <h3 className="row-text">Monto por operación máximo</h3>
          </div>
          <div className="content">
            <h3 className="title-content position-relative">
            <span className={`fade-content ${priceSelected !== '100k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '50k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '25k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '10k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '5k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '2k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
            </h3>
          </div>
          <div className="content">
            <h3 className="title-content position-relative">
            <span className={`fade-content ${priceSelected !== '100k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '50k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '25k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '10k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '5k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '2k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
            </h3>
          </div>
        </div>
        <div class="accordion-item-list accordion-item-list-two">
              <div id="collapseSixItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                El 1% del tamaño de su cuenta es el monto máximo permitido por cada operación en el examen y se permite un máximo de un 3% entre todas las operaciones abiertas al mismo tiempo.
                </div>
              </div>
            </div>
            <div className="rows-inactive collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSevenItem" aria-expanded="true" aria-controls="collapseSevenItem">
              <div>
                <h3 className="row-text">Reparto de ganancias</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list">
              <div id="collapseSevenItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                Cuando usted supere el examen y genere sus primeras ganancias, entonces podrá quedarse con el 90% de todas las ganancias.
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    )
}

export default CheckoutChoosePackages