import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import separatorImage from "../../assets/gradient-img.png";
import { auth } from "../../firebase/firebase";
import "./list_price.css";
import { onAuthStateChanged } from "firebase/auth";
import { PRICE_TO_PAY, LIST_BROKER } from "../../utils";
const ListPrice = (props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [priceSelected, setPriceSelected] = useState("50k");
  const [brokerSelected, setBrokerSelected] = useState(1);
 // const [interactivePrice,setInteractivePrice] = useState(899)
  const ref = useRef(null);

  //Listen when windows resize
  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(true);
      } else {
        setUser(false);
      }
    });
  }, []);

  const [width] = useWindowSize();
  const handleChange = () => {
    let position = document.querySelector('.section-price').getBoundingClientRect();
            // scrolls to 20px above element
    window.scrollTo(position.left, position.top + window.scrollY + 250)

    if(width < 600){
      window.scrollTo(position.left, position.top + window.scrollY + 360)
    }
  };

  const handleChangeDashboard = () => {
    const element = document.querySelector(".table-list-trader");
    console.log(element);
    element.scrollIntoView({ behavior: "smooth" });
  };

  const LIST_PRICES = Object.keys(PRICE_TO_PAY);
  const POPULARS_PRICE = ["50k"];


  const selectPrice = (price) => {
    console.log(LIST_PRICES)
    setPriceSelected(price || "50k");
   props.isDashboard ? handleChangeDashboard() : handleChange();
  };

  useEffect(() => {
      localStorage.setItem(
        "to_pay",
        JSON.stringify({
          pay_amount: PRICE_TO_PAY[priceSelected].pay,
          name: `${priceSelected}`,
          broker: `${LIST_BROKER[brokerSelected].broker}`,
          position_broker: `${brokerSelected}`,
        })
      );
  }, [priceSelected, brokerSelected]);


/*

  useEffect(() => {
    const intervalId = setInterval(() => {
        if(PRICE_TO_PAY[priceSelected].pay > interactivePrice){
                setInteractivePrice((prev)=> prev + 1)
        } 
        else if(PRICE_TO_PAY[priceSelected].pay < interactivePrice){
            setInteractivePrice((prev)=> prev - 1)
    }else {
        clearInterval(intervalId);
      }
    }, 1);

    return () => clearInterval(intervalId);
  }, [priceSelected, interactivePrice]);
*/
  const selectTrader = (index) => {
    setBrokerSelected(index);
    console.log(index)
  };
  return (
    <section className={`${props.isDashboard ? 'section-price-account' : 'section-price'}`}>
     {props.isDashboard ? '' :  <img
        src={separatorImage}
        className="separator"
        alt="separator"
        style={{
          width: "110%",
          height: "40px",
          objectFit: "fill",
          marginTop: "-30px",
          opacity: 0.5,
          marginBottom: "57px",
        }}
      />}
  { !props.isDashboard ? <>  <h2 className="title">
        {" "}
        Escoge el balance de tu{" "}
        <span style={{ color: "#b0ef96" }}>cuenta fondeada.</span>
      </h2>
      <p className="text">
      Tras realizar el pago se te proporcionará una cuenta en el bróker seleccionado para realizar el Examen. Una vez completado tendrás acceso a una cuenta fondeada con el capital escogido!
      </p> </> : <h3
        className="title"
        style={{ textAlign: "center", marginBottom: "25px" }}
      >
        Configura tus requerimientos y comienza tu Examen de binaryfunded
      </h3>}
      <ul className="list-trade">
        {LIST_BROKER.map((elm, index) => {
          return (
            <li key={index}>
              <button
                onClick={() => {
                  if(elm.active){ 
                    selectTrader(index)

                  } ;
                }}
                className={`button-traders ${
                  elm.active ? "trader-active" : "trader-not-active"
                }   ${brokerSelected === index ? "selected-trader" : ""}`}
              >
                <img
                  className="logo-trader"
                  src={elm.logo}
                  alt={elm.broker}
                ></img>
                <span
                  className={`${
                    elm.active && brokerSelected === index
                      ? "name-active"
                      : "name-inactive"
                  } name`}
                >
                  {elm.broker}
                </span>
              </button>
            </li>
          );
        })}
      </ul>

        <ul className="list-price list-desktop">
          {LIST_PRICES.map((el, index) => {
            return (
              <li key={index}>
                <button
                  onClick={() => {
                    selectPrice(el);
                  }}
                  className={`button-price ${
                    priceSelected === el
                      ? "button-price-active"
                      : "button-price-inactive"
                  } `}
                >
                  {el}
                  {POPULARS_PRICE.includes(el) ? (
                    <span className="badge">Popular</span>
                  ) : (
                    ""
                  )}
                </button>
              </li>
            );
          })}
        </ul>
    
       
   {/*
        <ul className="list-price list-price-mobile" style={{ width: "100%" ,display:width <= 488 ? 'block' : 'none' }}>
          <Slider
          className="slider-prices"
            {...carouselOptions}
            ref={slider => {
                sliderRef = slider;
              }}
          >
            {LIST_PRICES.map((el, index) => {
              return (
                <li key={index}>
                  <button
                    className={`button-price ${
                      priceSelected === el
                        ? "button-price-active"
                        : "button-price-inactive"
                    } `}
                  >
                    {el}
                    {POPULARS_PRICE.includes(el) ? (
                      <span className="badge">Popular</span>
                    ) : (
                      ""
                    )}
                  </button>
                </li>
              );
            })}
          </Slider>
        </ul>
      */ }

      <div className="table-list-trader" ref={ref}>
      <div className="rows-active">
              <div></div>
              <div className="d-flex flex-column justify-content-center">
                <h3 className="rows-title">Examen</h3>
              </div>
              <div className="d-flex flex-column justify-content-center">
                <h3 className="rows-title">Trader Fondeado</h3>
              </div>
            </div>
            <div className="rows-inactive collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOneItem" aria-expanded="true" aria-controls="collapseOneItem">
              <div>
                <h3 className="row-text">Objetivo de ganancias</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    12%
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list accordion-item-list-one">

                <div id="collapseOneItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div >
                  Para lograr superar el examen, el saldo de la cuenta deberá estar un 12% en positivo.
                  </div>
                </div>
            </div>

            <div className="rows-active collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwoItem" aria-expanded="true" aria-controls="collapseTwoItem">
              <div>
                <h3 className="row-text">Mercados otc</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Si
                  </span>
                </h3>
              </div>
            </div>
              <div class="accordion-item-list accordion-item-list-two">

                <div id="collapseTwoItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div >
                  Puede operar todos los dias incluidos fin de semanas gracias a que aceptamos operaciones en mercado OTC
                  </div>
                </div>
              </div>
            <div className="rows-inactive collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThreeItem" aria-expanded="true" aria-controls="collapseThreeItem">
              <div>
                <h3 className="row-text">Pérdida máxima diaria</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    5%
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list">
              <div id="collapseThreeItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                Si el saldo de la cuenta al final del día baja un 5% con respecto a como inició el día, entonces no se aprueba el examen.
                </div>
              </div>
            </div>
            <div className="rows-active collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFourItem" aria-expanded="true" aria-controls="collapseFourItem">
              <div>
                <h3 className="row-text">Pérdida máxima total</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    8%
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    20%
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list accordion-item-list-two">
              <div id="collapseFourItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                Si el saldo de la cuenta baja más del 8% no se aprueba el examen
                </div>
              </div>
            </div>
            <div className="rows-inactive collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFiveItem" aria-expanded="true" aria-controls="collapseFiveItem">
              <div>
                <h3 className="row-text">Tiempo para cumplir el objetivo</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                   ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    ilimitado
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list">
              <div id="collapseFiveItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                No tiene un tiempo máximo para aprobar el examen, hágalo a su ritmo y sin presión.
                </div>
              </div>
            </div>
        <div className="rows-active collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSixItem" aria-expanded="true" aria-controls="collapseSixItem">
          <div>
            <h3 className="row-text">Monto por operación máximo</h3>
          </div>
          <div className="content">
            <h3 className="title-content position-relative">
            <span className={`fade-content ${priceSelected !== '100k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '50k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '25k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '10k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '5k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
              <span className={`fade-content ${priceSelected !== '2k' ? 'fade-inactive' : 'fade-active'}`}>1%</span>
            </h3>
          </div>
          <div className="content">
            <h3 className="title-content position-relative">
            <span className={`fade-content ${priceSelected !== '100k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '50k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '25k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '10k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '5k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
              <span className={`fade-content ${priceSelected !== '2k' ? 'fade-inactive' : 'fade-active'}`}>-</span>
            </h3>
          </div>
        </div>
        <div class="accordion-item-list accordion-item-list-two">
              <div id="collapseSixItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                El 1% del tamaño de su cuenta es el monto máximo permitido por cada operación en el examen y se permite un máximo de un 3% entre todas las operaciones abiertas al mismo tiempo.
                </div>
              </div>
            </div>
            <div className="rows-inactive collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSevenItem" aria-expanded="true" aria-controls="collapseSevenItem">
              <div>
                <h3 className="row-text">Reparto de ganancias</h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    -
                  </span>
                </h3>
              </div>
              <div className="content">
                <h3 className="title-content position-relative">
                  <span
                    className={`fade-content ${
                      priceSelected !== "100k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "50k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "25k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "10k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "5k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                  <span
                    className={`fade-content ${
                      priceSelected !== "2k" ? "fade-inactive" : "fade-active"
                    }`}
                  >
                    Hasta 90%
                  </span>
                </h3>
              </div>
            </div>
            <div class="accordion-item-list">
              <div id="collapseSevenItem" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div >
                Cuando usted supere el examen y genere sus primeras ganancias, entonces podrá quedarse con el 90% de todas las ganancias.
                </div>
              </div>
            </div>
            {false ? '' :  <div className="container-button">

          <p className="text position-relative">
          <span className={`fade-content ${priceSelected !== '100k' ? 'fade-inactive' : 'fade-active'}`}>${PRICE_TO_PAY['100k'].pay}</span>
              <span className={`fade-content ${priceSelected !== '50k' ? 'fade-inactive' : 'fade-active'}`}>${PRICE_TO_PAY['50k'].pay}</span>
              <span className={`fade-content ${priceSelected !== '25k' ? 'fade-inactive' : 'fade-active'}`}>${PRICE_TO_PAY['25k'].pay}</span>
              <span className={`fade-content ${priceSelected !== '10k' ? 'fade-inactive' : 'fade-active'}`}>${PRICE_TO_PAY['10k'].pay}</span>
              <span className={`fade-content ${priceSelected !== '5k' ? 'fade-inactive' : 'fade-active'}`}>${PRICE_TO_PAY['5k'].pay}</span>
              <span className={`fade-content ${priceSelected !== '2k' ? 'fade-inactive' : 'fade-active'}`}>${PRICE_TO_PAY['2k'].pay}</span>
            </p>
        { props.isDashboard ? '' : <button
            className="button-send"
            onClick={() => {
              localStorage.setItem(
                "to_pay",
                JSON.stringify({
                  pay_amount: PRICE_TO_PAY[priceSelected].pay,
                  name: `${priceSelected}`,
                  broker: `${LIST_BROKER[brokerSelected].broker}`,
                  position_broker:`${brokerSelected}`
                })
              );
              if(user){
              navigate("/start-test");
            }else{
              navigate("/login")
            }
            }}
          >
            Empezar ahora
          </button> }
        </div>}
       { false ? '' : <div className="flex flex-column text-center text-white justify-content-center w-100 p-2">
          <h3 style={{ color: "#B0EF96", fontSize: "20px" }}>Pago único</h3>
          <h4 style={{ fontSize: "16px", fontWeight: "600" }}>
            Reembolsable con la primera retirada
          </h4>
        </div>}
      </div>
    </section>
  );
};

export default ListPrice;
