import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    getDatabase,
    ref,
    orderByChild,
    equalTo,
    query,
    get,
    update,
  } from "firebase/database";
  import { app, getUserDetails } from "../../firebase/firebase";
import Swal from "sweetalert2";

function ModalAccountRegister(props) {
  // This component is a modal that shows the information of the account
  const [show, setShow] = useState(props.show);
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({email:'', password:''})
  // This function is executed when the component is rendered
  useEffect(() => {
    setShow(props.show);
  }, [props]);

  useEffect(()=>{
    
  },[])

  const sendCredentials = async (e)=>{
    //Send the credentials to firebase in the account user
    e.preventDefault()
    Swal.fire({
      title:"¿Estas seguro que las credenciales son correctas? ",
      text: "¡esta informacion no se podra modificar!",
      showDenyButton: true,
      confirmButtonText: "Guardar",
      denyButtonText: `Cancelar`
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try{
          const userDetails = await getUserDetails()
          
          const db = getDatabase(app);
          const dataUserAccounts = ref(db, `users/${userDetails.userId}/accounts`);
      
          // Obtain account if order_id matches the parameter
          const userQuery = query(
            dataUserAccounts,
            orderByChild("email"),
            equalTo(credentials.email)
          );
  
          const credentials_previous = await get(userQuery);
          if(credentials_previous.exists()){
              Swal.fire({
                title: "Error",
                text:"Ya esta en uso este correo electronico en algunas de tus credenciales",
                icon: "error"
              });
              return
          }
  
  
          const userAccount = ref(db, `users/${userDetails.userId}/accounts/${props.account.id}`);
  
          await update(userAccount, {email:credentials.email, password:credentials.password, user_credentials:true, start_date: new Date().toISOString(), initial_funds:'10.000' })
  
          Swal.fire({
            title: "Credenciales enviadas correctamente",
            icon: "success"
          });
          props.HandleModalCredentials(false)
          props.requestAccountsUser()
      
      }catch(el){
        Swal.fire({
          title: "Ocurrio un error al enviar las credenciales",
          icon: "error"
        });
      }
      } 
    });
    
    
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

  return (
    <>
      <Modal
        className="modal-custom-accounts modal-create-account"
        show={show}
        onHide={()=>{props.HandleModalCredentials(false)}}
      >
        <Modal.Header closeButton>
          <Modal.Title>Registro de cuenta - <span style={{textTransform:'capitalize'}}>{props.account.server}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="d-flex flex-column" onSubmit={sendCredentials}>
            <p>Introduzca las credenciales de {props.account.server} para realizar el examen</p>
            <div className="d-flex flex-column">
              <h3>Correo electronico</h3>
             <div className="d-flex">
                <input type="email" placeholder="Introduzca el correo electronico" onInput={(e)=>{
                    setCredentials({...credentials, email:e.target.value})
                }} value={credentials.email} required/>
             </div>
            </div>
            <div className="d-flex flex-column mt-2">
              <h3>Contraseña</h3>
             <div className="d-flex position-relative">
             {showPassword ? <FaEye onClick={togglePasswordVisibility} className='eye-icon' /> : <FaEyeSlash onClick={togglePasswordVisibility} className='eye-icon' />}
                <input type={showPassword ? 'text' : 'password'} value={credentials.password} onInput={(e)=>{
                    setCredentials({...credentials, password:e.target.value})
                }} placeholder="Introduzca la contraseña" />
             </div>
            </div>
            <div className="d-flex flex-column mt-2" style={{borderTop:'1px solid white', paddingTop:'10px'}}>
              <p style={{fontSize:'14px'}}>Es importante que estas credenciales coincidan con la cuenta de {props.account.server} que está utilizando para completar el examen, puede ser una cuenta nueva o antigua, y esta debe de estar desactivada la doble autenticación para que nuestro equipo pueda auditar la cuenta durante el examen.</p>
              <p style={{fontSize:'14px'}}>No te equivoques al escribir tu correo o contraseña, por qué no puede ser cambiado</p>
              {props.account.email || props.account.password ? <p style={{fontSize:'14px'}}>Este paso es totalmente gratuito y no tiene costo adicional, recibirá como regalo un código de descuento del 15% en su correo electrónico para su próxima compra de examen</p> : ''}
              </div>
            <div className="d-flex flex-column w-100 justify-content-center">
                <button type="submit" className="button-send m-auto mt-3">
                    Enviar
                </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{props.HandleModalCredentials(false)}}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalAccountRegister;
