import {Link} from "react-router-dom";
import HouseIcon from "../components/icons/HouseIcon";
import Advice from "../components/Dashboard/Advice";
const Affiliates = () => {
  return (
    <div className="d-flex flex-column container-content-app">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
        <Link to="/dashboard"><HouseIcon /> Inicio</Link>
        </li>
        <li class="breadcrumb-item active">Afiliados</li>
      </ul>
    <Advice/>
    </div>
  );
};

export default Affiliates;
