import "./PopupCheckout.css";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import TimerIcon from "../icons/TimerIcon";
import CopyClip from "../icons/CopyClip"
import QRCode from "react-qr-code";
import tutorialVideoQuotex from "../../assets/videos/tutorial.mp4";
import { Link } from "react-router-dom";
import {
  getDatabase,
  ref,
  set,
  push,
  orderByChild,
  equalTo,
  query,
  get,
  update,
} from "firebase/database";
import { app, getUserDetails } from "../../firebase/firebase";

const PopupCheckout = (props) => {
  const [PAGEURL] = useState({
    quotex: "https://qxbroker.com/",
    iqoption: "https://iqoption.com/",
    binomo: "",
  });
  const [show, setShow] = useState(false);
  const [inRequest,setInRequest] = useState(false) 
  const [emailUser,setEmailUser] = useState("")
  const [status, setStatus] = useState({
    transaction: props.status,
    lastUpdated: new Date(),
    payinHash: "",
    payoutHash: "",
  });
  const [timeRestant, setTimeRestant] = useState(
    new Date(props.timeEnd) - new Date(props.timeStart)
  );

  const [transactionFinished,setTransactionFinished] = useState(false)
  const minutes = Math.floor(timeRestant / (1000 * 60));
  const seconds = Math.floor((timeRestant % (1000 * 60)) / 1000);
  const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);

  useEffect(()=>{
    setTimeout(()=>{
      window.scrollTo(0, 0);
    },1000)
  },[transactionFinished])

  const API_KEY_NOWPAYMENTS = "238HNGY-DPHMFAX-N7RNVGH-WCNJ02G";
  const CHECK_TRANSACTIONS = ["waiting", "failed", "expired", "refunded","sending","confirming","partially_pay"]; // Not call functions if exists this states
  const STATES_TRANSACTIONS = {
    waiting: "En espera",
    confirming: "En confirmacion",
    confirmed: "Confirmada",
    sending: "Enviando... continue consultado el estado de pago",
    partially_pay: "Parcialmente pagado",
    finished: "Finalizada",
    failed: "Fallida",
    refunded: "Reintegrado",
    expired: "Expirada",
  };

  const INITIAL_FUNDS = {
    '2k': '10.000',
    '5k': '10.000',
    '10k': '10.000',
    '25k': '10.000',
    '50k': '10.000',
    '100k': '10.000',
  }

  const checkPayment = async () => {
    if(inRequest) return
    setInRequest(true)
    const customer = props.customerInfo;
    try {
      const res = await fetch(
        `https://api.nowpayments.io/v1/payment/${props.paymentId}`,
        {
          headers: {
            "x-api-key": API_KEY_NOWPAYMENTS,
          },
        }
      );
      if (!res.ok) {
        setInRequest(false)
        return alert("No se pudo obtener el estado de la transaccion, intente de nuevo.");
      }

      const response = await res.json();
      //console.log(response);
      setStatus({
        ...status,
        payinHash: response.payin_hash,
        payoutHash: response.payout_hash,
      });

      // If fetch status ok

      // Save data to firebase from the check payment
      const userDetails = await getUserDetails()
      
      setEmailUser(userDetails.email)
      const db = getDatabase(app);
      const ref_data = ref(db, `customers/payments/criptocurrency/${props.toBroker.toLowerCase()}/${userDetails.userId}/${response.payment_id}`);
      const newDoc = push(ref_data);
      //console.log(customer.state_country)
      const sendDataNew = {
        payment_id: response.payment_id,
        purchase_id: response.purchase_id,
        payment_status: response.payment_status,
        code_country:customer.country.split("-")[0],
        country: customer.country.split("-")[1],
        email: userDetails.email,
        pay_amount: response.pay_amount,
        payout_hash: response.payout_hash || "",
        payin_hash: response.payin_has || "",
        cripto_currency: props.criptoCurrency,
        price_cripto_currency: response.pay_currency,
        pay_address: response.pay_address,
        actually_paid: response.actually_paid,
        created_at: response.created_at,
        order_description: response.order_description,
        order_id: response.order_id,
        server:`${props.toBroker.toLowerCase()}`,
        package:props.packageSelected,
        initial_funds: INITIAL_FUNDS[props.packageSelected],
        // eslint-disable-next-line no-undef
      };

      //console.log('Data al servidor', sendDataNew)

      const sendDataPrevious = {...sendDataNew}

      //Search customer and payment_id in database
      const paymentsList = ref(db, `customers/payments/criptocurrency/${props.toBroker.toLowerCase()}/${userDetails.userId}/${response.payment_id}`);
      const paymentQuery = query(
        paymentsList
      );
      const data_previous = await get(paymentQuery);
      //console.log({messge:'data previa',data_previous})
      //If customer and payment_id is not found and transaction is in process
      
      let userPass = {
        email:'',
        password:''
      }
      //console.log(data_previous.exists())
    if (!CHECK_TRANSACTIONS.includes(response.payment_status)) { 
        if (!data_previous.exists()) {
          await set(newDoc, sendDataNew);

        }
        // If payment_id exits
        if (data_previous.exists()) {
          const paymentData = Object.keys(data_previous.val())[0];
          const dataCustomer = data_previous.val()[paymentData]
          //console.log('customer',dataCustomer)
 
          const paymentRef = ref(db, `customers/payments/criptocurrency/${props.toBroker.toLowerCase()}/${userDetails.userId}/${response.payment_id}/${paymentData}`);
          await update(paymentRef, sendDataPrevious);
        }


      //Save data account in user data
      const dataUserAccounts = ref(db, `users/${userDetails.userId}/accounts`);
      let visibility_account = true;
      // First check length from dataUserAccounts if it is > 0
      const dataQuery = query(dataUserAccounts);
      const userAccounts = await get(dataQuery);

      if (userAccounts.size > 0) {
        visibility_account = false
      }

      const userQuery = query(
        dataUserAccounts,
        orderByChild("order_id"),
        equalTo(String(response.payment_id))
      );

      // Check if exists previous order_id to save
      const credentials_previous = await get(userQuery);
      if(credentials_previous.exists()){
        //console.log(credentials_previous.val())
      }else{
        // Save data in user accounts
        const newDocAccount = push(dataUserAccounts);
        await set(newDocAccount, {server:props.toBroker.toLowerCase(), status:"process", order_id:String(response.payment_id), created_at:response.created_at, initial_funds: INITIAL_FUNDS[props.packageSelected],package:props.packageSelected, visibility:visibility_account});
      }

     } 
      
      // Updated status to the customer
      setStatus({
        transaction: response.payment_status,
        lastUpdated: new Date(),
      });

    
 
    if (!CHECK_TRANSACTIONS.includes(response.payment_status)){
        //Check if transaction is confirmed to send email
        const templateParams = {
          to_name: `${userDetails.first_name} ${userDetails.last_name}`,
          user_email: `${userDetails.email}`,
          to_trader:props.toBroker,
          id_account:`${response.payment_id}`,
        };
     
      if (props.sendEmail === false) {
        
        const sendEmailCustomer = async ()=>{
          const sendEmail = await emailjs.send(
            "service_2uxrsrl",
            "template_lkoxqx4",
            templateParams,
            {
              publicKey: "osDlde-xFXkXx2V3-",
            }
          );

          return sendEmail
        }
      const senderEmail = await sendEmailCustomer()

        if(senderEmail.status !== 200){
          setInRequest(false)
          alert("No se pudo enviar su correo, intente de nuevo comprobar el pago")
        }else{
          props.sentEmail();
          setTransactionFinished(true)
        }
       }
    }

      setInRequest(false)
      //console.log(sendData);
    } catch (err) {
      setInRequest(false)
      alert("Ocurrio un error al comprobar su pago, intente nuevamente")
    }
  };

  useEffect(() => {
    //console.log(props);
    setShow(props.popup);
    setStatus({ transaction: props.status, lastUpdated: new Date() });
  }, [props.popup]);


  useEffect(() => {
    let intervalId;

    if (show) {
      intervalId = setInterval(() => {
        checkPayment()
      }, 30000); // Every 30 seg check the status from the pay
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [show]);

  useEffect(() => {
    // Countdown timer
    if (props.timeStart && props.timeEnd) {
      setTimeRestant(new Date(props.timeEnd) - new Date(props.timeStart));

      const countdown = setInterval(() => {
        setTimeRestant((prevTime) => {
          if (prevTime === 0) {
            checkPayment();
          }
          return prevTime - 1000;
        });
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    }
  }, [props]);

  return (
    <div
      className={`checkout-popup position-fixed top-0 left-0 right-0 bottom-0 w-100 h-100 d-flex justify-content-center align-items-center z-3 ${
        show ? "checkout-popup-active" : ""
      }`}
    >
      <div className={`position-relative checkout-popup-process  checkout-infomation ${
        transactionFinished === true ? "checkout-popup-finished" : ""
      }`}>
        <button
          className="checkout-close"
          onClick={(e) => {
            if (
              window.confirm(
                "Estas seguro de cerrar la ventana? esto eliminara el proceso de pago si aun no has hecho el deposito"
              )
            ) {
              props.setPopup();
            }
          }}
        >
          X
        </button>

        <div className="information">
          <h3>Proceso de transferencia </h3>
          <p>
            Tienes una cierta cantidad de tiempo para realizar el deposito de la
            transacción. según la siguiente información.
          </p>
          <p>
            Es importante que verifiques los datos antes de realizar la
            transferencia, Si comete un error, es posible que pierda sus fondos.
          </p>
        </div>

        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-column flex-lg-row gap-2">
            <div class="col-12 col-lg-6 d-flex flex-column">
              <div className="col-12 d-lg-flex">
                <span className="label">Estado</span>
              </div>
              <div className="col-12  d-lg-flex align-items-center  ">
                <p
                  className="m-0"
                  style={{ textTransform: "capitalize", fontWeight: "600" }}
                >
                  {STATES_TRANSACTIONS[status.transaction]}
                </p>
              </div>
            </div>
            <div class="col-12 col-lg-6 d-flex flex-column">
              <div className="col-12 col-lg-6 d-lg-flex  ">
                <span className="label">Moneda para pagar</span>
              </div>
              <div className="col-12 col-lg-6 d-lg-flex align-items-center  ">
                <p
                  className="mb-0"
                  style={{ textTransform: "uppercase", fontWeight: "600" }}
                >
                  {props.payFormatCurrencie}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-lg-row-reverse divider-pay gap-2">
            
            <div class="col-12 col-lg-6 d-flex flex-column">
              <div className="col-12 d-lg-flex">
                <span className="label">Monto de pago</span>
              </div>
              <div className="col-12  d-lg-flex align-items-start flex-column  ">
                <p className="m-0" style={{ fontWeight: "600" }}>
                  {props.payAmount}
                </p>
                <small style={{color: 'white', fontSize: '15px'}}>Solo envie el monto indicado <span style={{color:'red'}}>*</span></small>
              </div>
            </div>
            
            <div className="col-12 col-lg-6 d-flex flex-column">
            <p className="label mb-1">Descripción:</p>
            <div className="d-flex flex-wrap">
              <p className="mb-0" style={{ fontWeight: "600" }}>
                {props.orderDescription}
              </p>
            </div>
          </div>

          </div>

          

          <div className="col-12">
            <p className="label mb-1">Dirección de envio del pago:</p>
            <div className="d-flex flex-column gap-2">
            <div style={{padding:'20px', background:'white',  maxWidth: 'max-content'}}>
            <QRCode size={175} title={`${props.payAmount}`} value={`${props.payAddress}`} />
            </div>
              <p
                id="wallet_direction"
                style={{
                  wordBreak: "break-word",
                  marginBottom: "4px",
                  fontWeight: "600",
                }}
              >
                {props.payAddress}
              </p>
              <button
                className="button-send"
                style={{ fontWeight: "600", display:'flex', justifyContent:'center', gap:'6px' }}
                onClick={async () => {
                  // Get the text field
                  await navigator.clipboard.writeText(props.payAddress);
                  alert("Direccion copiada: " + props.payAddress);
                }}
              >
                Copiar dirección <CopyClip style={{color:'#000000',width:'18px',height:'18px'}}/>
              </button>
            </div>
          </div>
          <div className="col-12">
            <p className="label mb-1">
              Tiempo restante para completar la transacción:
            </p>
            <div className="d-flex flex-wrap gap-2 text-center">
            <TimerIcon style={{fill:'#fff'}}/><p
                style={{
                  display: minutes <= 0 && seconds <= 0 ? "none" : "flex",
                  fontWeight: "600",
                  fontSize: "20px",
                }}
              >
                {minutes < 10 ? "0" + minutes : minutes}:
                {seconds < 10 ? "0" + seconds : seconds}
              </p>
              <p
                style={{
                  display: minutes <= 0 && seconds <= 0 ? "flex" : "none",
                  fontWeight: "600",
                }}
              >
                Tiempo expirado, pero puede seguir consultado el estado de su
                transaccion
              </p>
            </div>
          </div>

          <div className="col-12">
            <p className="label mb-1">
              Después de completar la transacción, haga clic aquí para verificar
              el pago:
            </p>
            <div className="d-flex flex-wrap gap-2 text-center">
              <button
                className="button-send"
                type="button"
                onClick={() => {
                  checkPayment();
                }}
              >
                Comprobar el estado del pago
              </button>
            </div>
          </div>

          <div className="col-12">
            <p className="text-center text-white mb-1">
              El estado de su transacción es:
              <span style={{ textTransform: "capitalize", fontWeight: "600" }}>
                {" "}
                {STATES_TRANSACTIONS[status.transaction]}
              </span>
            </p>
            <p
              className="text-center text-white mb-1"
              style={{ fontWeight: "600" }}
            >
              Última actualización:{" "}
              {`${padL(status.lastUpdated.getMonth() + 1)}/${padL(
                status.lastUpdated.getDate()
              )}/${status.lastUpdated.getFullYear()} ${padL(
                status.lastUpdated.getHours()
              )}:${padL(status.lastUpdated.getMinutes())}:${padL(
                status.lastUpdated.getSeconds()
              )}`}
            </p>
          </div>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', gap:'10px'}}>
            <small className="w-full mt-2 text-center">
                Es importante que no abandone esta ventana y consulte el estado de la transaccion al realizar la transferencia de fondos, asegurese que el estado de su transaccion sea confirmada
            </small>
            <small className="w-full mt-2 text-center">
              Si el estado de la transaccion es confirmada, usted recibira un
              correo en el email proporcionado
            </small>
          </div>
        </div>
      </div>

      {/* Show when transaction is finished */}
      <div className={`position-relative checkout-infomation checkout-success ${transactionFinished === true ? 'checkout-success-activate' : ''}`} >
        <div className="information">
          <h2>¡Felicidades!</h2>
            <h3>Tu transaccion a sido completada</h3>
            <p>
              Te llegará un email con intrucciones a seguir en esta direccion de correo
            </p>
            <p style={{fontSize:'16px', fontWeight:'600'}}>{emailUser}</p>

          { /* <div class="accordion mb-3" id={`accordion-${props.paymentId}`}>
              <div className="accordion-item">
                <h2
                  className="accordion-header"
                  id={`headingOne-${props.paymentId}`}
                >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#CollapseOne-${props.paymentId}`}
                    aria-expanded="false"
                    aria-controls={`CollapseOne-${props.paymentId}`}
                  >
                    ¿Como puedo acceder a cuenta de {props.toBroker.toLowerCase()} por
                    primera vez?
                  </button>
                </h2>
                <div
                  id={`CollapseOne-${props.paymentId}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`headingOne-${props.paymentId}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-4 pt-0">
                    <div className="d-flex flex-column">
                      <video
                        className="video-account"
                        style={{ maxWidth: "450px" }}
                        controls
                      >
                        <source src={tutorialVideoQuotex} type="video/mp4" />
                      </video>
                      <h4
                        className="description-title mt-2"
                        style={{ fontSize: "17px" }}
                      >
                        Pasos descriptivos
                      </h4>
                      <p>
                        1. Ingresa a la plataforma de{" "}
                        <Link
                          className="url-server"
                          target="_blank"
                          rel="noopener noreferrer"
                          to={`${PAGEURL[props.toBroker.toLowerCase()]}`}
                        >
                          {props.toBroker.toLowerCase()}{" "}
                        </Link>
                      </p>
                      <p>
                        2. Dirigase al boton para registrar el correo y la
                        contraseña
                      </p>
                      <p>
                        3.(Importante) Complete los datos del formulario usando
                        las credenciales proporcionadas
                      </p>
                      <p>
                        4. De click en el boton azul registro y cambie su cuenta
                        real por la de demo
                      </p>
                      <p>5. Comienza con tus operaciones</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <button class="button-send" onClick={()=>{props.setPopup(); setTimeout(()=>{
              setTransactionFinished(false)
            },1000)}}>Cerrar ventana</button>
          </div>
      </div>

    </div>
  );
};

export default PopupCheckout;
