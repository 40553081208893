import { useEffect,useState } from "react";
import { STATUS_ACCOUNT } from "../../utils/index";
import ModalAccountInfo from "../Modals/ModalAccountInfo";
import "../AccountInformation/account-information.css";
import { Link } from "react-router-dom";
import HouseIcon from "../icons/HouseIcon";
import KeyIcon from "../../assets/icons/key.png";
import {TYPE_ACCOUNT_FUNDED} from "../../utils/index"

const AccountSuspense = (props) =>{

useEffect(()=>{
},[props])



    const openModal = () => {
      setShowMod(true);
    };
  
    const closeModal = () => {
      setShowMod(false);
    };
  
  
    const [showMod, setShowMod] = useState(false);

  
    const [modalInfo, setModalInfo] = useState({
      title: "",
      email: ``,
      password: "",
    });
  
    
      return (
        <>
          <ModalAccountInfo
            show={showMod}
            user={modalInfo}
            handleClose={closeModal}
          />
          <div className="d-flex flex-column container-content-app container-account-details">
          { !props.isAdmin ? <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">
                  <HouseIcon /> Inicio
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/dashboard">Mis cuentas fondeadas</Link>
              </li>
              <li className="breadcrumb-item active">
                Cuenta - #{props.account.order_id}
              </li>
            </ul>
          :
            <ul className="breadcrumb">
                <li className="breadcrumb-item">
                <Link to="#">
                    <HouseIcon /> Inicio
                </Link>
                </li>
                <li className="breadcrumb-item">Soporte</li>
                <li className="breadcrumb-item">
                    <Link to={'/admin/users'}>Usuarios</Link></li>
                    <li className="breadcrumb-item">
                    <Link to={'/admin/user/' + props.isAdmin}>#{props.isAdmin}</Link></li>
                <li className="breadcrumb-item active">Cuenta - #{props.account.order_id}</li>
            </ul> }
      
              <>
                <div className="d-flex flex-column flex-lg-row justify-content-lg-between mb-3">
                  <div className="col-12 col-lg-5">
                    <p className="m-0 w-100 text-white">
                      Cuenta{" "}
                      <span>
                        {
                          props.account.package || ''
                        }
                      </span>{" "}
                      -{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {props.account.server}
                      </span>{" "}
                      - #{props.account.order_id}
                    </p>
                  </div>
                  <div className="ms-3 ms-lg-0 mt-2 mt-lg-0 col-12 col-lg-7  d-flex flex-wrap align-items-center justify-content-lg-end gap-3">
                    <span className={`status account-${props.account.status}`}>
                      {STATUS_ACCOUNT[props.account.status]}
                    </span>
                    <button
                      className="btn-credencials"
                      type="button"
                      onClick={() => {
                        openModal();
                        setModalInfo({
                          title: "Informacion de la cuenta",
                          email: `${props.account.email}`,
                          password: `${props.account.password}`,
                          server: `${props.account.server}`,
                        });
                      }}
                    >
                      <img src={KeyIcon} className="icon-btn" alt="key-icon" />
                      Credenciales
                    </button>
    
                    
                  </div>
                </div>
                <div className="row flex-column-reverse flex-lg-row gap-3 gap-lg-0">
                  <div className="col-lg-8 col-12">
                    <section>
                       <div className="d-flex flex-column justify-content-center align-items-start text-white container-account account-suspense">
                        <h3 className="m-auto mb-2" style={{fontSize:'20px', textAlign:'center'}}>Cuenta suspendida</h3>
                        <p className="text-center">Tu cuenta ha sido suspendida, ya que ha incumplido con las reglas establecidas, si deseas realizar alguna apelación puede contactarse con soporte</p>
                        <Link to="/support" className="button-send-transparent m-auto text-center" style={{textDecoration:'none'}}>Soporte</Link>
                       </div>
                    </section>
                  </div>
    
                  <div className="col-lg-4 col-12 p-lg-0">
                    <div className="d-flex flex-column align-items-start text-white container-account">
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Tamaño de la cuenta</strong>
                          </p>
                          <p className="m-0">
                            $
                            {Number(TYPE_ACCOUNT_FUNDED[props.account.package]).toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </p>
                        </div>
                      </div>
    
                      
    
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Tipo de cuenta</strong>
                          </p>
                          <p className="m-0" style={{ textTransform: "capitalize" }}>{props.account.server}</p>
                        </div>
                      </div>

                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Balance actual</strong>
                          </p>
                          <p className="m-0">
                            $
                            {Number(props.account.balance_actual_real)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </p>
                        </div>
                      </div>
    
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Fecha de creacion</strong>
                          </p>
                          <p className="m-0">
                            {new Date(props.account.created_at).toString() !==
                            "Invalid Date"
                              ? new Date(props.account.created_at)
                                  .toISOString()
                                  .slice(0, 10)
                                  .replaceAll("-", "/")
                              : ""}
                          </p>
                        </div>
                      </div>
    
                      <div className=" d-flex flex-column w-100 content-row">
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="m-0">
                            <strong>Ultima actualizacion</strong>
                          </p>
                          <p className="m-0">
                            {props.account.updated_at
                              ? new Date(props.account.updated_at).toString() !==
                                "Invalid Date"
                                ? new Date(props.account.updated_at)
                                    .toISOString()
                                    .slice(0, 10)
                                    .replaceAll("-", "/")
                                : ""
                              : ""}
    
                            {!props.account.updated_at
                              ? new Date(props.account.created_at).toString() !==
                                "Invalid Date"
                                ? new Date(props.account.created_at)
                                    .toISOString()
                                    .slice(0, 10)
                                    .replaceAll("-", "/")
                                : ""
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
 
              </>
   
    
            
          </div>
        </>
      );
}

export default AccountSuspense