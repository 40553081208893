import { Link } from "react-router-dom";
import HouseIcon from "../components/icons/HouseIcon";
import Advice from "../components/Dashboard/Advice";
import iqoptionsImg from "../assets/images/platforms/iqoption.png";
import quotexImg from "../assets/images/platforms/quotex.png";
import binomoImg from "../assets/images/platforms/binomo.png";
import "../components/Platforms/platforms.css";
const Platforms = () => {
  return (
    <div className="d-flex flex-column container-content-app">
      <ul class="breadcrumb">
        <li class="breadcrumb-item">
          <Link to="/dashboard">
            <HouseIcon /> Inicio
          </Link>
        </li>
        <li class="breadcrumb-item active">Plataformas</li>
      </ul>

      <div className="container-platforms">
        <h3>Plataformas afiliadas</h3>
        <div className="d-flex flex-wrap gap-3 justify-content-center justify-content-lg-start ">
          <Link to="https://qxbroker.com/" target="_blank" rel="noopener noreferrer" className="platform active">
            <img src={quotexImg} alt="quotex" />
          </Link>
          <Link className="platform inactive">
            <img src={iqoptionsImg} alt="iqoption" />
          </Link>

          <Link className="platform inactive">
            <img src={binomoImg} alt="binomo" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Platforms;
