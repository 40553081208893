import "./accounts-users.css";
import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import KeyIcon from "../../assets/icons/key.png";
import ModalAccountInfo from "../Modals/ModalAccountInfo";
import ModalAccountAdmin from '../Modals/ModalAccountAdmin'
import { auth } from "../../firebase/firebase";
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  get,
  equalTo,
  update,
  remove,
  set,
} from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { app } from "../../firebase/firebase";
import MetricIcon from "../icons/MetricIcon";
import PencilIcon from "../icons/PencilIcon"
import TrashIcon from "../icons/TrashIcon"
import ModalAccountRegister from "../Modals/ModalAccountRegister";
import {STATUS_ACCOUNT} from "../../utils/index";
import Swal from "sweetalert2";
const AccountsUsers = (props) => {
  
  const [showMod, setShowMod] = useState(false);
  const [showModalCredentials, setShowModalCredentials] = useState(false);
  const [showModalAdmin, setShowModalAdmin] = useState(false);
  const TYPE_ACCOUNT = {
    '2k':'2.000',
    '5k':'5.000',
    '10k':'10.000',
    '25k':'25.000',
    '50k':'50.000',
    '100k':'100.000',
  }

  const [modalInfo, setModalInfo] = useState({
    id:"",
    server: "",
    email: "",
    password: "",
    package:"",
    order_id:"",
    status:""
  });

  const [avalaibleBrokers] = useState(["quotex"])

  const [listAccount, setListAccount] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const [uid, setUid] = useState("");

  const handleVisibilityItem = async (order_id, checked) => {
    const db = getDatabase(app);
    const dataUserAccounts = ref(db, `users/${uid}/accounts`);

    // Obtain account if order_id matches the parameter
    const userQuery = query(
      dataUserAccounts,
      orderByChild("order_id"),
      equalTo(order_id)
    );

    const credentials_previous = await get(userQuery);

    if (credentials_previous.exists()) {
      // Set data collected
      const data = credentials_previous.val();
      const positionArray = Object.keys(data);
      //Format data
      let informationAccounts = [];
      positionArray.forEach((el, i) => {
        informationAccounts[i] = {...data[el]};
      });
      //Update data
      informationAccounts[0].visibility = checked;
      const dataUpdate = informationAccounts[0];
      
      //AFirst update state array listAccounts
      setListAccount(() => {
        return listAccount.map((el) => {
          if (el.order_id === order_id) {
            return dataUpdate;
          }
          return el;
        });
      });
      

      //Update data in database
      const updateData = ref(db, `users/${uid}/accounts/${positionArray[0]}`);
      await update(updateData, {visibility:checked});

      
    }
  };

  const HandleModalCredentials = (value) => {
    if(value){
      setShowModalCredentials(true);
    }else{
      setShowModalCredentials(false);
    }
  }

  useEffect(()=>{
    if(showAccounts){
      document.querySelector(".list-accounts").style.maxHeight = `${
        document.querySelector(".list-accounts").scrollHeight
      }px`;}
  },[listAccount, showAccounts])
  

  const AccountItem = ({ data, index }) => {
    return (
      <div className="account" key={index} style={{marginBottom: data.visibility ? '-4px' : ''}}>
        <div className="d-flex flex-column-reverse flex-lg-row align-items-center justify-content-start justify-content-lg-between w-100">
          <h3>
            Cuenta <span className="indicator-server" translate="no">{data.server} </span>
          </h3>
          <p className="m-0">
            N° Pedido: <span>#{data.order_id}</span>
          </p>
        </div>
        <div className="mt-1 info d-flex flex-wrap  flex-column flex-lg-row gap-1 gap-lg-3">
          <div className="d-flex flex-column flex-lg-row text-center text-lg-start justify-content-start gap-lg-1">
            <p className="m-0">Fecha de creacion:</p>
            <span>
              {new Date(data.created_at).toString() !== "Invalid Date" ? new Date(data.created_at)
                .toISOString()
                .slice(0, 10)
                .replaceAll("-", "/") : ''}
            </span>
          </div>

          <div className="d-flex flex-column flex-lg-row text-center text-lg-start justify-content-start gap-lg-1">
            <p className="m-0">Balance:</p>
            <span>${TYPE_ACCOUNT[data.package] || ''}</span>
          </div>

          <div className="d-flex flex-column flex-lg-row text-center text-lg-start justify-content-start gap-lg-1">
            <p className="m-0">Estado de la cuenta:</p>
            <span>{STATUS_ACCOUNT[data.status]}</span>
          </div>
        </div>

        <div className="sensitive-data mt-1 mt-lg-3">
          <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center gap-1">
            <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center gap-1">
              <button
                type="button"
                className="btn-data"
                onClick={() => {
                  if(!data.email || !data.password || !data.user_credentials){
                    setModalInfo({
                      email:data.email || '',
                      password:data.password || '',
                      server: data.server,
                      id: data.id,
                    })
                    HandleModalCredentials(true);
                  }else{
                  // Set data to show modal with credentials
                    setShowMod(true)
                    setModalInfo({
                      server: data.server,
                      email: data.email,
                      password: data.password,
                      package:data.package,
                      status:data.status
                    });
                  } 
                 
                }}
              >
                <div className="d-flex gap-2 align-items-center">
                  <div className="content-icon">
                    <img
                      src={KeyIcon}
                      style={{ filter: "invert(1)" }}
                      alt="key-icon"
                    />
                  </div>
                  <span>Credenciales</span>
                </div>
              </button>

             
              <Link to={`${avalaibleBrokers.includes(data.server) && data.email && data.user_credentials && data.testing_account ? `${props.isAdmin ? `/admin/user/${uid}/account/${data.order_id}` : `/account/${data.order_id}`}` : '#'}`} type="button" style={{opacity:`${avalaibleBrokers.includes(data.server) && data.email && data.user_credentials && data.testing_account ? '1' : '0.6'}`, textDecoration:'none'}} className="btn-data" >
                <div className="d-flex gap-2 align-items-center">
                  <div className="content-icon">
                    <MetricIcon style={{width: '20px', height: '20px'}} />
                  </div>
                  <span>Métricas</span>
                </div>
              </Link>

              { props.isAdmin ? <button
                type="button"
                className="btn-data"
                onClick={()=>{
                  setModalInfo({
                    email:data.email || '',
                    password:data.password || '',
                    server: data.server,
                    id: data.id,
                    package:data.package || '',
                    status:data.status || '',
                    order_id:data.order_id || ''
                  })
                  setShowModalAdmin(true)
                }}
              >
                <div className="d-flex gap-2 align-items-center">
                  <div className="content-icon">
                     <PencilIcon style={{width: '20px', height: '20px'}} />
                  </div>
                  <span>Editar</span>
                </div>
              </button>
   : ''}

{ props.isAdmin ? <button
                type="button"
                className="btn-data"
                onClick={()=>{
                  Swal.fire({
                    title:"¿Estas seguro que desea eliminar esta cuenta?",
                    text:"Esta es una accion irreversible",
                    icon:"warning",
                    showDenyButton: true,
                    confirmButtonText:"Eliminar",
                    denyButtonText: `Cancelar`
                  }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      try{
                        // Set start_date if email and password not exists
                        const db = getDatabase(app);
                        const dataUserAccounts = ref(db, `users/${uid}/accounts`);
                        // Obtain account if order_id matches the parameter
                        const userQueryAccount = query(
                            dataUserAccounts,
                            orderByChild("order_id"),
                            equalTo(data.order_id)
                        );

                        const getAccount = await get(userQueryAccount)
                    
                        
                        if(getAccount.exists()){
                          const accountIdUser = Object.keys(getAccount.val())[0]
                          const accountRef = ref(db, `users/${uid}/accounts/${accountIdUser}`);
                          await remove(accountRef);
                        }
                        Swal.fire({
                          title: 'Cuenta eliminada',
                          icon: "success"
                        });

                        requestAccountsUser()
                    
                    }catch(el){
                      Swal.fire({
                        title: "Ocurrio un error al eliminar la cuenta",
                        icon: "error"
                      });
                    }
                    } 
                  });
                }}
              >
                <div className="d-flex gap-2 align-items-center">
                  <div className="content-icon">
                     <TrashIcon style={{width: '20px', height: '20px'}} />
                  </div>
                  <span>Eliminar</span>
                </div>
              </button>
   : ''}
            </div>
            <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-items-center gap-1">
              <div className="d-flex flex-row align-items-center gap-2">
              <label htmlFor={data.order_id}>Visible</label>

              <div className="form-check form-switch ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={data.order_id}
                  onChange={(e) => {
                    handleVisibilityItem(data.order_id, e.target.checked);
                  }}
                  checked={data.visibility || false}
                />
              </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    );
  };
  const requestAccountsUser = useCallback(async () => {
    try {
      //Save data account in user data

      const db = getDatabase(app);
      const dataUserAccounts = ref(db, `users/${uid}/accounts`);
      const userQuery = query(dataUserAccounts);

      // Check if exists credentials registered
      const credentials_previous = await get(userQuery);
      
      if (credentials_previous.exists()) {
        // Set data collected
        const data = credentials_previous.val();
        const positionArray = Object.keys(data);
        //Format data
        let informationAccounts = [];
        positionArray.forEach((el, i) => {
          informationAccounts[i] = {...data[el], id:el};
        });

        setListAccount(informationAccounts);
      }else{
        setListAccount([]);
      }
      setLoaded(true);

    } catch (err) {
      setTimeout(()=>{
        requestAccountsUser()
      },3000)
      setLoaded(false);
    }
  }, [uid]);

  const closeModal = () => {
    setShowMod(false);
  };

  const openModal = () => {
    setShowMod(true);
  };


  useEffect(() => {
    if(!props.isAdmin){
      onAuthStateChanged(auth, (user) => {
        setUid(user.uid);
      });
    }

    if(props.isAdmin && props.userId){
      setUid(props.userId)
    }

  
  }, [props]);

  useEffect(() => {
    if (uid) {
      requestAccountsUser();
    }
  }, [requestAccountsUser, uid]);

useEffect(()=>{
  if(props.refresh){
    requestAccountsUser()
  }
},[props])


  return (
    <>
      <ModalAccountInfo
        show={showMod}
        user={modalInfo}

        isAdmin={props.isAdmin || false}
        handleClose={closeModal}
      />
{
    props.isAdmin ?  <ModalAccountAdmin 
        created={false} 
        requestAccountsUser={requestAccountsUser} 
        edit={true} 
        account={modalInfo}
        show={showModalAdmin} 
        accountId={""} 
        userId={props.userId || ""} 
        handleModalAdmin={(value)=>{setShowModalAdmin(value)}}
        /> : ''
}
      <ModalAccountRegister
        show={showModalCredentials}
        account={modalInfo}
        requestAccountsUser={requestAccountsUser}
        HandleModalCredentials={HandleModalCredentials}
      />
      <div className="section-accounts">
        <div className="d-flex justify-content-lg-between align-items-center">
          <h3 className="title-account">{!props.isAdmin ? 'Mis cuentas fondeadas' : ''}</h3>
        </div>

        {
          loaded === false ? <div className="d-flex flex-column align-items-center justify-content-center mb-4 mb-lg-0"><div className="lds-dual-ring"></div></div> : ''
        }

        {
          /* Show accounts if visibility is true */
          loaded ? listAccount.sort((a,b)=>{
            return new Date(b.created_at) - new Date(a.created_at)
        }).map((el, index) => {
            if (el.visibility) {
              return <AccountItem data={el} index={index} />;
            } else {
              return <div index={index} style={{display:'none'}}></div>;
            }
          }) : ''
        }
        {
          loaded ? <div
            className={`list-accounts gap-2 d-flex flex-column ${
              showAccounts ? "list-accounts-active" : ""
            }`}
          >
            {listAccount.sort((a,b)=>{
                    return new Date(b.created_at) - new Date(a.created_at)
                }).map((el, index) => {
              if (el.visibility) {
                return <></>;
              } else {
                return <AccountItem data={el} index={index} />;
              }
            })}
          </div> : ''
        }

        {listAccount.length === 0 && loaded === true ? (
          <div
            className={`list-accounts mb-1 gap-2 d-flex flex-column ${
              showAccounts ? "list-accounts-active" : ""
            }`}
            style={{maxHeight:showAccounts ? 'max-content' : '0px'}}
          >
            <h3 className="title-account">
              Todavía no has adquirido ninguna cuenta
            </h3>
          </div>
        ) : (
          ""
        )}
        <div className="d-flex align-items-center justify-content-center justify-content-lg-end">
          {" "}
          <button
            onClick={() => {
              if (showAccounts) {
                setShowAccounts(false);
                document.querySelector(".list-accounts").style.maxHeight = 0;
              } else {
                setShowAccounts(true);
                //Set height from the sum of all children from element .list-accounts when is active
                document.querySelector(".list-accounts").style.maxHeight = `${
                  document.querySelector(".list-accounts").scrollHeight
                }px`;
              }
            }}
            style={{ maxWidth: "max-content", marginTop: "0px" }}
            type="button"
            className="button-send button-accounts"
          >
            {showAccounts ? "Mostrar solo cuentas visibles" : "Mostrar todas las cuentas"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AccountsUsers;
