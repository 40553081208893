import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Link,useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';
import Logo from '../../assets/Logo-01-green.png'
import userIcon from '../../assets/user-icon.png' 
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  
  // Show and hide the menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };


  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };



  const location = useLocation();
  const lastHash = useRef('');

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  const [width] = useWindowSize();
  const toCommunitySection = () =>{
      //container-community
      let position = document.querySelector('#container-community').getBoundingClientRect();
            // scrolls to 20px above element
    window.scrollTo(position.left, position.top + window.scrollY + 250)

    if(width < 600){
      window.scrollTo(position.left, position.top + window.scrollY + 360)
    }
  }
  const getStarted = () =>{
    navigate("/Login");
  }
  return (
    <div className='container'>
      <nav className={`navbar fixed-top navbar-expand-lg navbar-dark  ${isMenuOpen ? 'show' : ''}`}>
        <div className="container-fluid ">
          <Link class="navbar-brand" to="/">
            <img src={Logo} alt="logo-page" className='logo' />
          </Link>
          <button className={`navbar-toggler ${isMenuOpen ? '' : 'collapsed'}`} type="button" onClick={toggleMenu} data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen ? 'true' : 'false'} aria-label="Toggle navigation">
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
          <Link to="/SignUp" className="start-btn start-btn-mobile" style={{textDecoration:'none'}} >Obtener fondos</Link>
          
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <div className='container-navbar-mobile'>
              <ul className="navbar-nav ul-bg ms-lg-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/" onClick={handleMenuItemClick}>Inicio</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#faqs" onClick={handleMenuItemClick}>Examen y <span style={{margin: '0 1px'}}>R</span>eglas</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#community" onClick={handleMenuItemClick}>Comunidad</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/support" onClick={handleMenuItemClick}>Soporte</Link>
                </li>
              </ul>
              <div className='nav-btns'>
                <Link to="/Login"><button className="login-btn" type="button">
                <img src={userIcon} alt="icon-user" className='icon-user' /> Iniciar sesión</button></Link>
                <Link to="/SignUp" style={{textDecoration:'none'}}><button className="start-btn start-btn-desktop" type="button">
                Obtener fondos</button></Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
