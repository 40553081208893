import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ModalDetailMessage = (props) => {
  const [show, setShow] = useState(props.show);
  const options_date = { 
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric", 
    minute: "numeric",
    second: "numeric"
  };

  useEffect(() => {
    setShow(props.show);
  }, [props]);
  return (
    <>
      <Modal
        className="modal-custom-ticket"
        show={show}
        onHide={props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detalles del mensaje</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">

            <div className="row">
                <div className="d-flex col-12 col-md-6 flex-column">
                <h3>Mensaje de:</h3>
                <p style={{ fontSize: "14px"}}>
                    {props.ticket?.fullname}
                </p>
                </div>
                <div className="d-flex col-12 col-md-6 flex-column">
                <h3>Fecha de creacion:</h3>
                <p style={{ fontSize: "14px", textTransform:'capitalize' }}>
                    {props.ticket?.created_at ? new Date(props.ticket?.created_at) !== "Invalid Date" ? new Date(props.ticket?.created_at).toLocaleDateString("es-ES", options_date): "" : ""}
                </p>
                </div>
            </div>

              <div className="d-flex flex-column">
                <h3>Email del usuario</h3>
                <p style={{ fontSize: "14px"}}>
                  {props.ticket?.email}
                </p>
              </div>
 

 

            <div className="d-flex flex-column">
              <h3>Descripción</h3>
              <p
                style={{
                  fontSize: "14px",
                  maxHeight: "180px",
                  overflow: "auto",
                }}
              >
                {props.ticket?.message}
              </p>
            </div>



          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={props.handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDetailMessage;
