import Iqoption from "../assets/images/Iqoption.png";
import Quotex from "../assets/images/Quotex.png";
import Binomo from "../assets/images/Binomo.png";

export const TYPE_ACCOUNT_INITIAL = {
    2000: "2k",
    5000: "5k",
    10000: "10k",
    25000: "25k",
    50000: "50k",
    100000: "100k",
  };

export const TYPE_ACCOUNT_FUNDED = {
  "2k": 2000,
  "5k": 5000,
  "10k": 10000,
  "25k": 25000,
  "50k": 50000,
  "100k": 100000,
}

export const PRICE_TO_PAY = {
    "2k": { price: "$49", pay: 49 },
    "5k": { price: "$99", pay: 99 },
    "10k": { price: "$199", pay: 199 },
    "25k": { price: "$499", pay: 499 },
    "50k": { price: "$899", pay: 899 },
    "100k": { price: "$1499", pay: 1499 },
  }

export const PRICES = Object.values(PRICE_TO_PAY).map((item) => item.pay)


export const LIST_BROKER = [
    { broker: "Iqoption", active: true, logo: Iqoption },
    { broker: "Quotex", active: true, logo: Quotex },
    { broker: "Binomo", active: false, logo: Binomo },
  ];

export const STATUS_TICKET ={
    OPEN:'Abierto',
    ATTENDED:'Atendido',
    CLOSED:'Cerrado'
}

export const STATUS_ACCOUNT = {
  active: "Activa",
  process: "En proceso",
  suspense: "Suspendida",
  
};