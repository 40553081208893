import React, { useState, useEffect, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import {Link} from "react-router-dom"
import "./Home.css";
import {
  tradingCourse,
  profitTarget,
  profitSplit,
  maxDrawDown,
  dailyDrawDown,
  payouts,
  bonus,
  timeLimit,
  oneTimeFee,
  Phase1,
  Phase2,
  maxWithdraw,
} from "./ToolTipTexts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import candles from "../../assets/Candles.png";
import candle1 from "../../assets/candle-1-01.png";
import candle2 from "../../assets/candle-2-01.png";
import candle3 from "../../assets/candle-3-01.png";
import candle4 from "../../assets/candle-4-01.png";
import ImageWin from "../../assets/hero-globe.png"
// Social Icons
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
// Icons
import { CiGlobe } from "react-icons/ci";
import { TbCash } from "react-icons/tb";
import { TbMoodDollar } from "react-icons/tb";
import { TbHours24 } from "react-icons/tb";
import { IoIosPeople } from "react-icons/io";
import { CiVideoOn } from "react-icons/ci";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { TbTargetArrow } from "react-icons/tb";
import { FaArrowTrendDown } from "react-icons/fa6";
import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { GiCash } from "react-icons/gi";
import { AiOutlineSplitCells } from "react-icons/ai";
import { TbReload } from "react-icons/tb";
import { FaBusinessTime } from "react-icons/fa";
import { IoPricetagOutline } from "react-icons/io5";
import { IoWalletOutline } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { TiSupport } from "react-icons/ti";
// images
import chooseIcon from "../../assets/choose.png";
import tradeIcon from "../../assets/trade.png";
import coinsIcon from "../../assets/coins.png";
import image from "../../assets/gradient-img.png";
import customersImg from "../../assets/images/customers.png";
import OneStep from "../OneStepPackage/OneStep";
import TwoStep from "../TwoStepPackage/TwoStep";
import TwoStepLite from "../TwoStepLitePackage/TwoStepLite";
import crypto from "../../assets/crypto.svg";
import debitCard from "../../assets/debit-card.svg";
import bankTransfer from "../../assets/bank-transfer.svg";
import videoThumbnail from "../../assets/video-thumbnail.jpg";
import PriceRanger from "./PriceRanger";
import OneStepSlider from "../OneStepSlider/OneStepSlider";
import TwoStepSlider from "../TwoStepSlider.jsx/TwoStepSlider";
import ListPrice from "./ListPrices";
import Footer from "../Footer/Footer";
import NewFooter from "../Footer/NewFooter";
import TwoStepLiteSlider from "../TwoStepLiteSLider/TwoStepLiteSlider";
import NavbarWhenUserLogin from "../Navbar/NavbarWhenUserLogin";
import Navbar from "../Navbar/Navbar";
import telegramIcon from "../../assets/images/telegram.png";
import TelegramOutlineIcon from "../icons/TelegramOutlineIcon";
const Home = () => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(true);
      } else {
        setUser(false);
      }
    });
  }, []);

  let sliderRef = useRef(null);

  const [settings, setSettingC] = useState({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 0.1,
    slidesToScroll: 1,
    speed: 10000,
    cssEase: "linear",
    variableWidth: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    draggable: false,
    rtl: false,
    initialSlide: 0,
    /* responsive: [
      {
        breakpoint: 1025, // Medium devices (tablets, 768px and up)
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint:993,
        settings:{
          slidesToShow:2
        }
      },
      {
        breakpoint: 768, // Small devices (landscape phones, 576px and up)
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576, // Extra small devices (portrait phones, 576px and below)
        settings: {
          slidesToShow: 1
        }
      }
    ]*/
  });
  // For FAQ
  const [GeneralFaq, setGeneralFaq] = useState(true);
  const [RuleFaq, setRuleFaq] = useState(false);
  const [ChallengeFaq, setChallnegeFaq] = useState(false);
  function generalFaq() {
    setGeneralFaq(true);
    setRuleFaq(false);
    setChallnegeFaq(false);
  }
  function rulesFAQ() {
    setRuleFaq(true);
    setGeneralFaq(false);
    setChallnegeFaq(false);
  }
  function challengesFaq() {
    setChallnegeFaq(true);
    setRuleFaq(false);
    setGeneralFaq(false);
  }
  // package buttons
  const [onestep, setOneStep] = useState(true);
  const [twostep, setTwoStep] = useState(false);
  const [twosteplite, setTwoStepLite] = useState(false);
  const oneStep = () => {
    setOneStep(true);
    setTwoStep(false);
    setTwoStepLite(false);
  };
  const twoStep = () => {
    setTwoStep(true);
    setOneStep(false);
    setTwoStepLite(false);
  };
  const twoStepLite = () => {
    setTwoStepLite(true);
    setTwoStep(false);
    setOneStep(false);
  };
  return (
    <div>
      {user ? <NavbarWhenUserLogin /> : <Navbar />}
      {/* 1st Section */}
      <section className="container main-section">
        <div>
          <p className="rank-text">
            La Empresa de Fondeo #1 de Opciones Binarias
          </p>
        </div>
        <h1 className="main-text">Opera con nuestro capital</h1>
        <p className="sub-text">
          Opera con hasta 100.000 dólares y recibe el 90% de las ganancias que
          tengas. Nosotros cubrimos las pérdidas.
        </p>

        <div className="btns">
          <button className="start-btn" type="submit"onClick={()=>{
            let position = document.querySelector('.section-price').getBoundingClientRect();
            // scrolls to 20px above element
            window.scrollTo(position.left, position.top + window.scrollY - 120);
          }}>
            Empezar Ahora
          </button>
          <button className="demo-btn" type="submit" style={{display:'flex', gap:'5px'}} onClick={()=>{
            let position = document.querySelector('.section-how').getBoundingClientRect();
            // scrolls to 20px above element
            window.scrollTo(position.left, position.top + window.scrollY - 120);
          }}>
            Más Info <span>></span>
          </button>
        </div>
        <div className="social-icons d-none">
          <FaInstagram className="icon" />
          <FaXTwitter className="icon" />
          <FaTelegramPlane className="icon" />
          <FaDiscord className="icon" />
        </div>
        <div className="d-flex flex-wrap gap-2 justify-content-center align-items-center mt-2">
          <img
            src={customersImg}
            style={{ width: "120px", height: "auto", objectFit: "contain" }}
            alt="binary-customers"
          />
          <p>Únete a +857 Traders fondeados</p>
        </div>
        <div className="candles-section">
          <img src={candles} className="candles-box-1" alt="candle-box-1"/>
          <img src={candles} className="candles-box-2" alt="candle-box-2" />
        </div>
      </section>
      {/* 1st section End */}
      {/* 2nd Section */}
      <section className="section-carousel" style={{ width: "100%" }}>
        <div className="slider"
        >
          <div className="slider-track">

          <div className="slide">
            <div className="card">
              <CiGlobe className="card-icon" />
              <div className="card-body">
                <h5 className="card-title">1 Fase</h5>
                <p className="card-text">Para cualquier tamaño de cuenta</p>
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="card">
              <TbCash className="card-icon" />
              <div className="card-body">
                <h5 className="card-title">$321,000 USD</h5>
                <p className="card-text">Capital pagado a traders</p>
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="card">
              <TbMoodDollar className="card-icon" />
              <div className="card-body">
                <h5 className="card-title">$100k</h5>
                <p className="card-text">Cuentas desde 1.5k hasta 100k</p>
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="card">
              <div>
                <TbHours24 className="card-icon" />
              </div>

              <div className="card-body">
                <h5 className="card-title">24/7</h5>
                <p className="card-text">
                  Opera todos los días del año. OTC admitido
                </p>
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="card">
              <IoIosPeople className="card-icon" />
              <div className="card-body">
                <h5 className="card-title">90%</h5>
                <p className="card-text">de las ganancias generadas para ti</p>
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="card">
              <CiGlobe className="card-icon" />
              <div className="card-body">
                <h5 className="card-title">Fase</h5>
                <p className="card-text">Para cualquier tamaño de cuenta</p>
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="card">
              <TbCash className="card-icon" />
              <div className="card-body">
                <h5 className="card-title">$321,000 USD</h5>
                <p className="card-text">Capital pagado a traders</p>
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="card">
              <TbMoodDollar className="card-icon" />
              <div className="card-body">
                <h5 className="card-title">$100k</h5>
                <p className="card-text">Cuentas desde 1.5k hasta 100k</p>
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="card">
              <div>
                <TbHours24 className="card-icon" />
              </div>

              <div className="card-body">
                <h5 className="card-title">24/7</h5>
                <p className="card-text">
                  Opera todos los días del año. OTC admitido
                </p>
              </div>
            </div>
          </div>

          <div className="slide">
            <div className="card">
              <IoIosPeople className="card-icon" />
              <div className="card-body">
                <h5 className="card-title">90%</h5>
                <p className="card-text">de las ganancias generadas para ti</p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
      {/* 2nd section end */}
      {/* 3rd section */}
      <section className="section-escala">
        <div className="container">
          <div className="build-scale">
            <div className="row p-md-5 py-4 px-2">
              <div className="col-lg-8">
                <p className="scale-text">Atención!</p>
                <h1 className="title">
                  Eres Trader de<span> Opciones Binarias?</span>
                </h1>
                <p className="sub-title">
                  Te fondeamos en todas las plataformas: QUOTEX, BINOMO, IQOPTION…
                </p>
                <p className="desc-text">
                  En BinaryFunded buscamos traders talentosos, y es por eso que
                  nuestro programa de fondeo se basa en un examen de solo una
                  fase. Después de completar el examen recibes una cuenta (en tu
                  plataforma preferida) con un saldo de hasta 100,000 dólares.
                </p>
              </div>
              <div className="col-lg-4 scale-candles">
                <img src={candle2} className="scale-candle-img-1" alt="" />
                <img src={candle1} className="scale-candle-img-2" alt="" />
                <img src={candle4} className="scale-candle-img-3" alt="" />
                <img src={candle3} className="scale-candle-img-4" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 3rd section end */}
      {/* 4th section */}
      <section className="section-how">
        <div className="container">
          <p className="text-center text">Así funciona</p>
          <h1 className="text-white text-center fw-bold">
            Como <span className="text-up">Fondearte</span> en{" "}
            <span className="binaryadv-text"><span className="text-down">Opciones</span> Binarias </span>{" "}
          </h1>
          <div className="row py-5 selection-row justify-content-center">
            {/* Choose column */}
            <div className="col-lg-4 text-center ">
              <div className="row choose-col">
                <div className="col-lg-8">
                  <div className="num-img">
                    <h2 className="number">01.</h2>
                    <img src={chooseIcon} alt="Choose Icon" />
                  </div>
                  <h2 className="text-white choose-text">Escoge</h2>
                  <p className="selection-text">
                    Escoge el tamaño de la cuenta fondeada que deseas obtener
                  </p>
                </div>
                <div className="col-lg-4">
                  <img src={image} className="gradient-img" alt="" />
                </div>
              </div>
            </div>
            {/* Trade column */}
            <div className="col-lg-4 text-center">
              <div className="row trade-col">
                <div className="col-lg-8">
                  <div className="num-img">
                    <h2 className="number">02.</h2>
                    <img src={tradeIcon} alt="Trade Icon" />
                  </div>
                  <h2 className="text-white trade-text">Opera</h2>
                  <p className="selection-text">
                    Completa el examen con éxito sin infringir ninguna de las
                    reglas
                  </p>
                </div>
                <div className="col-lg-4">
                  <img src={image} className="gradient-img" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 text-center funded-col">
              <div className="num-img">
                <h2 className="number">03.</h2>
                <img src={coinsIcon} alt="Coins Icon" />
              </div>
              <h2 className="text-white getfunded-text">Recibes Fondos</h2>
              <p className="selection-text">
                Enhorabuena! Ya tienes acceso a una cuenta fondeada con el
                capital escogido.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* 4th sectin end */}
      {/* 5th section */}
      {/**/}
      <ListPrice />
      {/* 6th section */}
      <section className="section-payments">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="payout-text text-center  text-lg-start">
                Pagos Rapidos y <span>Confiables</span>
              </h1>
              <p className="text-white text-center text-lg-start">
                Disfrute de pagos sin complicaciones como recompensa por sus
                operaciones simuladas. Porque no debes esperar por tu dinero.
              </p>
            </div>
            <div className="col-lg-5 d-flex justify-content-lg-end justify-content-center align-items-end gap-2">
              <div className="border-end">
                <div className="time-payout">
                  <IoMdTime className="time-icon" />
                  <h6 className="mb-0">3h</h6>
                </div>
                <p className="text-white text-center">Tiempo de pago promedio</p>
              </div>
              <div>
                <div className="customer-support">
                  <TiSupport className="support-icon" />
                  <h6 className="mb-0">24/5</h6>
                </div>
                <p className="text-white text-center">Atención al cliente</p>
              </div>
            </div>
          </div>
          {/* Payment cards */}
          <div className="row py-3">
            <div className="col-lg-4 col-sm-6 d-flex justify-content-center justify-content-md-start">
              {/* Bank Transfer Card */}
              <div className="card payout-card-width card-disabled">
                <div className="card-body">
                  <div className="d-flex flex-column gap-1">
                    <h5 className="card-title bankcard-title mb-0">
                      Transferencia bancaria
                    </h5>
                    <p className="card-text">
                      Dinero en el banco a las pocas horas de su solicitud.
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mt-2">
                    <div className="badged-disabled">
                      <p>Proximamente</p>
                    </div>
                    <div className="payoutcards-img">
                      <img src={bankTransfer} alt="icon-card" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 d-flex justify-content-center justify-content-md-start">
              {/* Crypto payout Card */}
              <div className="card payout-card-width">
                <div className="card-body">
                  <div className="d-flex flex-column gap-1">
                    <h5 className="card-title cryptocard-title mb-0">
                      Crypto Pagos
                    </h5>
                    <p className="card-text mb-0">
                      Admitimos pagos a través de USDC y otras monedas estables.
                    </p>
                  </div>
                  <div className="payoutcards-img">
                    <img src={crypto} alt="icon-crypto" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 d-flex justify-content-center justify-content-lg-start">
              {/* Debit Payout card */}
              <div className="card payout-card-width card-disabled">
                <div className="card-body">
                  <div className="d-flex flex-column gap-1">
                    <h5 className="card-title visacard-title mb-0">
                      Tarjeta de débito
                    </h5>
                    <p className="card-text">
                      Utilice sus ganancias directamente con nuestras tarjetas
                      VISA de marca.
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mt-2">
                    <div className="badged-disabled">
                      <p>Proximamente</p>
                    </div>
                    <div className="payoutcards-img">
                      <img src={debitCard} alt="icon-card" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Price ranger */}

          <div className="row flex-column flex-lg-row py-5 pb-0 price-ranger-container">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <img className="image-win" src={ImageWin} alt="image" />
             
              {/* Video Section */}
              {/*

              <a href='https://www.youtube.com/watch?v=9pOXYTOUeqg' className='text-decoration-none' target='_blank'>
                <div className="row px-2 py-3 video-row">
                  <div className="col-sm-5 position-relative">
                    <img src={videoThumbnail} className='w-100' alt='Video thumbnail' style={{ borderRadius: '10px' }} />
                    <button className='interview-btn mb-0'><FaPlay className="play-icon" />   Interview</button>
                  </div>
                  
                  <div className="col-sm-7">
                    <h5 className='text-white fw-bold'>How a 20 yo Student Makes Money as a Day Trader </h5>
                    <p className='text-white'>Watch our interview with Ayush, an emerging day trader from India</p>
                  </div>
                </div>
              </a>
                  */}
            </div>
            <div className="col-lg-6 d-flex flex-column text-center justify-content-center justify-content-lg-end p-0">
              {/* write profit ranger code here */}
              <h1
                className="fw-bold how-much-text text-center "
                style={{ padding: 0 }}
              >
                ¿Cuánto puedes ganar?
              </h1>
              <p className="text-white text-center ">
                Gana tu vida operando a tiempo completo. Sin arriesgar su propio
                capital. Según nuestros datos, un operador promedio a tiempo
                completo tiene una tasa de beneficio mensual del 4,3%
              </p>
              <div className="pricerange-bg m-auto mt-4 mb-0">
                <PriceRanger />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* last Section */}
      <section className="container-faqs" id="faqs">
        <div className="container d-flex d-lg-flex flex-column align-items-center flex-lg-row justify-content-center justify-content-lg-between" style={{gap:'10px'}}>
        <div className="d-flex flex-column col-lg-7 ">
          <div className="text-center text-lg-start pb-md-2 pb-2 d-flex flex-column" style={{gap:'1.5rem'}}>
            <p className="text m-0">¿Alguna pregunta?</p>
            <h2 className="text-white fw-bold display-5 m-0">
              Preguntas <span className="asked-text">Frecuentes</span>
            </h2>
            <p className="text-white">
              Lea información clave sobre las cuentas fondeadas para opciones
              binarias
            </p>
          </div>

          <div className="d-flex justify-content-center justify-content-lg-start gap-2 m-auto m-lg-0 container-faq-buttons">
            <button
              className={`faq-buttons ${GeneralFaq ? "active" : ""}`}
              onClick={generalFaq}
            >
              General
            </button>
            <button
              className={`faq-buttons ${RuleFaq ? "active" : ""}`}
              onClick={rulesFAQ}
            >
              Reglas
            </button>
            <button
              className={`faq-buttons ${ChallengeFaq ? "active" : ""}`}
              onClick={challengesFaq}
            >
              Exámen
            </button>
          </div>
          {/* General FAQs */}
          {GeneralFaq && (
            <div class="accordion  py-3 general-FAQ" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                   ¿Quién puede abrir una cuenta en Binary Funded?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>Para abrir una cuenta en binaryfunded, debe cumplir los siguientes requisitos:</p>
                    <ul>
                      <li>Residir en un país en el que aceptemos comerciantes. Tenga en cuenta que no se aceptan personas de los siguientes países: Cuba, Irán, Líbano, Siria, Corea del Norte, Libia, Sudán, Somalia, Vietnam, Rusia, Pakistán y Yemen.</li>
                      <li>Ser mayor de edad</li>
                    </ul>
                
                  </div>
                </div>
              </div>
            
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    ¿Cómo empiezo con Binary Funded?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                 <p> Para empezar con binaryfunded y aprovechar al máximo las oportunidades disponibles, siga estos pasos:</p>
                  <ul>
                      <li>
                      Crear una cuenta: Visite el sitio web principal de binaryfunded y regístrese para obtener una cuenta.
                      </li>
                      <li>Seleccione el tamaño de su cuenta: Vaya al panel de control del examen binaryfunded y elija el tamaño de cuenta que desea comprar.</li>
                      <li>Realice el pago: Proceda al pago haciendo clic en el botón comprar y seleccionando el método de pago que prefiera.</li>
                      <li>Recibir credenciales de cuenta: Una vez completada la transacción, recibirás las credenciales de tu cuenta por correo electrónico y también en tu panel de control de cliente.</li>
                      <li>Comience a operar: Con su cuenta configurada, ya está listo para comenzar a operar como un trader de binaryfunded a su conveniencia.</li>
                      
                    </ul>
                    <p>Para mantener la elegibilidad y aumentar sus posibilidades de éxito, es esencial mantener la contraseña sin cambios durante toda la duración del examen.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                     ¿Cómo es el entorno de trading de Binary Funded y cuáles son sus fases?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>En Binary Funded todos los exámenes cuentan de 1 sola Fase. Todas los exámenes ocurren dentro de un entorno de trading simulado utilizando cuentas demo. Incluso en la Fase de Trader Fondead, las cuentas pueden aparecer como «Demo» bajo nuestros brokers pero en la mayoría de los casos otorgamos cuentas reales. Solo serán demo si apruebas el examen con estrategias riesgosas. Pero podrás seguir ganando dinero real aún así.</p>
                    <p>Es importante tener en cuenta que la información proporcionada en el sitio web tiene únicamente fines informativos e instructivos generales y no constituye asesoramiento en materia de inversión. Además, los productos apalancados, como los CFD y las operaciones con divisas, o las opciones binarias, conllevan un alto riesgo de pérdida, por lo que los usuarios deben conocer las leyes y normativas locales relativas a las actividades de negociación.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    ¿Qué métodos de pago acepta Binary Funded y qué debo tener en cuenta al realizar pagos en criptomonedas?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>Binaryfunded.com acepta pagos mediante tarjeta de crédito y criptodivisas (Cryptomonedas). Consulte a la hora del pago, en el menú desplegable las criptomonedas y tarjetas de crédito y debito que aceptamos.</p>
                    <p>Cuando realices pagos en criptomoneda, asegúrate de que estás enviando el activo o moneda a la red correcta para evitar cualquier problema con la recepción del pago y la entrega de tus credenciales.</p>
                    <p>Si tiene cualquier pregunta o problema escríbanos a: soporte@binaryfunded.com</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Rule Faq */}
          {RuleFaq && (
            <div className="accordion py-3 rule-FAQ" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    ¿Cuáles son las prácticas de trading prohibidas en Binary
                    Funded?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                   <p>En binaryfunded, los traders tienen flexibilidad a la hora
                    de elegir sus estilos y estrategias de trading, pero ciertas
                    prácticas están prohibidas para mantener la imparcialidad.
                    Aquí tienes una lista completa de las prácticas de trading
                    simulado prohibidas:</p>
                    <ul>
                      <li>
                        Copy Trading: Consiste en imitar las operaciones de
                        otros operadores en varias cuentas utilizando Asesores
                        Expertos (EA, Bots) comprados en el mercado.
                      </li>
                      <li>
                        Operaciones inversas/Cobertura: Está prohibido
                        participar en operaciones inversas a través de múltiples
                        cuentas o coordinar posiciones opuestas para así
                        asegurar el pase de un Examen. Tambien esto está
                        prohibido en entorno "Real"
                      </li>
                      <li>
                        Servicios de gestión de cuentas: Está estrictamente
                        prohibido adquirir o prestar servicios de gestión de
                        cuentas reales o cuentas demo, o compartir información
                        sobre cuentas.
                      </li>
                      <li>
                        HFT simulado (negociación de alta frecuencia): No está
                        permitido participar en métodos simulados de negociación
                        de alta frecuencia.
                      </li>
                    </ul>
                    La violación de cualquiera de estas normas supondrá el fallo
                    inmediato y el cierre de la cuenta fondeada o exámen. Estas
                    restricciones se aplican para garantizar un entorno de
                    trading justo y transparente para todos.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    ¿Qué acciones están prohibidas al realizar operaciones en
                    Binary Funded?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>Es esencial adherirse a ciertas reglas y directrices cuando
                    se participa en el examen y trading en binaryfunded. Aquí
                    hay una lista de acciones prohibidas:</p>
                    <ul>
                      <li>
                        Utilizar estrategias de trading que aprovechen los
                        errores de los servicios, como errores de visualización
                        de precios o retrasos en las actualizaciones del
                        gráfico.
                      </li>
                      <li>
                        Ejecución de operaciones utilizando una fuente de datos
                        externa o lenta.
                      </li>
                      <li>
                        Participar en operaciones con la intención de manipular
                        el trading, incluida la entrada en posiciones opuestas
                        simultáneas.
                      </li>
                      <li>
                        Realizar operaciones que contradigan los términos y
                        condiciones del proveedor y de la plataforma de trading;
                        y obvio las nuestras.
                      </li>
                      <li>
                        Utilizar programas informáticos, inteligencia
                        artificial, técnicas de alta velocidad o métodos de
                        introducción masiva de datos que puedan manipular o
                        abusar del sistema.
                      </li>
                      <li>
                        Realizar operaciones de una manera que contradiga las
                        prácticas habituales de trading o suscite preocupación
                        por posibles perjuicios al proveedor.
                      </li>
                    </ul>
                    La violación de estas prohibiciones puede acarrear
                    consecuencias como el cese de los privilegios de trading o
                    el cierre de las cuentas. Estas normas se han establecido
                    para mantener un entorno de trading justo y transparente.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    ¿Qué deben saber los traders acerca de hacer operaciones
                    durante el cierre de los mercados (OTC) en Binary Funded?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                   <p>En binaryfunded, los traders tienen la flexibilidad de
                    mantener operaciones simuladas durante la noche en todos los
                    tipos y fases de cuentas. Esto es lo que necesitas saber:</p> 
                    <ul>
                      <li>
                        {" "}
                        Puedes operar en mercados OTC a cualquier hora, incluso
                        los fin de semana.
                      </li>
                      <li>
                        Puedes mantener abiertas las operaciones durante horas.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Challenges FAQ */}
          {ChallengeFaq && (
            <div className="accordion py-3 challenge-FAQ" id="accordionExample">
                <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    ¿Por qué son importantes las reglas de pérdida diaria y pérdida total máxima en binaryfunded, y cuáles son las consecuencias de violar estas reglas?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                    En binaryfunded, damos prioridad a la gestión de riesgo y a la gestion de capital. <br/>Para conseguirlo, aplicamos reglas de pérdida diaria y pérdida total máxima. Esto es lo que necesitas saber:
                    </p>
                    <ul>
                      <li>
                      Regla de pérdida diaria máxima: Esta regla establece la pérdida máxima permitida en la que puede incurrir un trader en un solo día de trading. Superar este límite puede llevar a la cancelación de la cuenta. Al aplicar esta regla, pretendemos evitar pérdidas excesivas y proteger a los traders de caídas significativas.
                      </li>
                      <li>Regla de pérdida total máxima: Esta regla define el umbral de la pérdida máxima acumulada que un trader puede experimentar a lo largo del examen de evaluación y en las cuentas fondeadas. Si se supera este límite, la cuenta se cancelará, lo que provocará el fracaso del examen.</li>
                    </ul>
                    <p>El cumplimiento de estas normas de pérdida son cruciales para una gestión responsable del riesgo y un trading sostenible. Crean un entorno de trading justo y seguro en el que los traders pueden centrarse en el crecimiento y, al mismo tiempo, evitar pérdidas significativas que podrían enterrar su rendimiento.</p>
                    <p>Es esencial que los traders supervisen sus operaciones, apliquen técnicas eficaces de gestión de riesgo y respeten las normas de pérdidas máximas para mantener un historial de operaciones exitoso. Proporcionamos orientación y apoyo para ayudar a los traders a navegar por estas reglas y desarrollar estrategias de trading conscientes del riesgo.</p>
                  </div>
                </div>
              </div>
            
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    ¿Por qué está prohibido cambiar las contraseñas de las cuentas demo durante el examen o en la cuenta fondeada en binaryfunded?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                 <p> En binaryfunded, mantener la integridad e imparcialidad de nuestros examenes es primordial. Por lo tanto, está estrictamente prohibido cambiar las contraseñas asignadas de las cuentas demo para los exámenes o de las cuentas ya fondeadas. He aquí por qué:</p>
                  <ul>
                      <li>
                      Garantizar la integridad: Conservar las contraseñas originales permite supervisar y evaluar adecuadamente las actividades de trading, garantizando la transparencia y la coherencia en todo el proceso.
                      </li>
                      <li>Violación de las reglas: Alterar las contraseñas se considera una violación de las reglas, lo que resulta en el fracaso automático en el examen o eliminación de la cuenta fondeada.</li>
                      
                    </ul>
                    <p>Para mantener la elegibilidad y aumentar sus posibilidades de éxito, es esencial mantener la contraseña sin cambios durante toda la duración del examen.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    ¿Qué es el objetivo de ganancias en binaryfunded, y cómo afecta al examen?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>En binaryfunded, el objetivo de ganancia juega un papel crucial en el examen. Si ya aprobaste el examen, no existe esta regla para ti. Esto es lo que necesitas saber:</p>
                    <ul>
                      <li>
                      Definición: El objetivo de ganancia representa el porcentaje especificado del saldo inicial de la cuenta demo que los traders pretenden alcanzar mediante posiciones cerradas. Este objetivo suele fijarse entre el 5% y el 12% del saldo inicial.
                      </li>
                      <li>Período de trading: Los traders disponen de tiempo limitado para alcanzar el objetivo de ganancia de 30 días, lo que permite flexibilidad en las estrategias.</li>
                      <li>Ejemplo: Por ejemplo, en un Examen con un capital simulado de 10.000 $ y un objetivo de ganancia del 12%, los traders aspiran a alcanzar 1,200 $ en ganancias. Tras completar el Examen, se elimina el objetivo mínimo de ganancia.</li>
                      <li>Avance a cuenta fondeada: Cumplir el objetivo de ganancia es esencial para avanzar de examen a cuenta fondeada y ganar comisiones. Una vez alcanzado el objetivo, los traders pueden seguir operando en la cuenta sin restricciones, es decir sin objetivo de ganancias, siempre que cumplan todas las otras normas y objetivos.
                      </li>
                      
                    </ul>
                    <p>Entender y alcanzar el objetivo de ganancia es vital para que los traders progresen de examen a fondeada y demuestren su habilidad para operar en binaryfunded.</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
          <div className="position-relative col-12 justify-content-center col-lg-5 d-flex justify-content-lg-end" >
            <div id="community" style={{display:'block', height:'0px', marginTop:'-150px'}}></div>
        <div  className="container-community position-relative">
         
          <h3 className="title-community" >
          Invitamos a los{" "}
            <span style={{ fontWeight: "800" }}>
            traders más inteligentes
            </span>{" "}
            a nuestra{" "}
            <span style={{ textDecoration: "underline" }}>
              comunidad 
            </span>
           <span style={{padding: '0 10px', fontSize: '27px'}}>❤️</span> 
          </h3>

          <div className="logo-community">
            <span style={{ marginRight: "-6px" }}>En </span>{" "}
            <img
              style={{ height: "36px" }}
              src={telegramIcon}
              alt="telegram-icon"
            />{" "}
            <span
              style={{
                fontSize: "35px",
                fontWeight: "700",
                letterSpacing: "1px",
              }}
            >
              Telegram
            </span>
          </div>

          <Link to="https://t.me/+Bt4YjbpXOIA2MWQ0" rel="noopener noreferrer" target="_blank" class="btn-community">
            Unirse a la comunidad
          </Link>

          <TelegramOutlineIcon
            style={{
              width: "164px",
              height: "164px",
              position: "absolute",
              right: "-36px",
              bottom: "-38px",
              transform: "rotate(5deg)",
            }}
          />
        </div>
        </div>
        </div>
      </section>
      <NewFooter />
    </div>
  );
};

export default Home;
