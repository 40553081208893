import {useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase/firebase';

const Logout = () => {
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
          await signOut(auth);
          localStorage.removeItem('username');
          localStorage.removeItem('email');
          navigate('/');
        } catch (error) {
          navigate('/');
          console.error('Error logging out:', error);
        }
      };

    useEffect(()=>{
        handleLogout()
    },[])


    return(<></>)
}

export default Logout
