import React, { useState,useEffect } from 'react'
import { auth, app, getUserDetails } from '../../firebase/firebase'
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import { Link } from "react-router-dom";
import './SignUp.css'
import {
    getDatabase,
    ref,
    set,
    push,
  } from "firebase/database";
const SignUp = () => {

    const [user, setUser] = useState({ email: '', password: '', firstname: '', lastname:'', passwordConfirm:'' });
    const [errors, setErrors] = useState({email: '', password: '', firstname: '', lastname:'', passwordConfirm:'',  general: '' });
    // const [login, setLogin] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [acceptTerms, setAcceptTerms] = useState({
        termsWeb: false,
        newsletter: true,
      });

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateName = (firstname, lastname) => {
        return firstname.trim().length > 0;
    };

    

    const handleClickSignUp = (e) => {
        e.preventDefault()
        let valid = true;
        let newErrors = { email: '', password: '', firstname: '', general: '' };
        if (Object.values(acceptTerms)[0] === false) {
            return alert("Por favor lee los terminos y condiciones");
        }
        if (!validateName(user.firstname)) {
            newErrors.firstname = 'Su nombre es requerido.';
            valid = false;
        }

        if (!validateName(user.lastname)) {
            newErrors.lastname = 'Su apellido es requerido.';
            valid = false;
        }

        if(!user.password){
            newErrors.password = 'La contraseña es requerida.';
            valid = false;
        }

        if(user.password !== user.passwordConfirm){
            newErrors.passwordConfirm = "La contraseñas no coinciden"
            valid = false
        }


        if (!validateEmail(user.email)) {
            newErrors.email = 'Direccion de email invalida.';
            valid = false;
        }

        setErrors(newErrors);

        if (valid) {
            createUserWithEmailAndPassword(auth, user.email, user.password)
                .then(async (data) => {
                    console.log(data)
                    //Set data user and id
                    const userId = data.user.uid;
                    const dataUser = {
                        first_name: user.firstname,
                        last_name: user.lastname,
                        email: user.email,
                        newsletter:acceptTerms.newsletter,
                        created_at: new Date().toISOString(),
                        userId,
                    }
                    // Connect to firebase 
                     const db = getDatabase(app);
                     const ref_data = ref(db, `users/${userId}`);

                     //Push new data from the user registered
                     const newDoc = push(ref_data);
                     await set(newDoc, dataUser);
                     navigate('/Login');
                  })
                .catch((err) => {
                    console.log(err);
                    setErrors({ ...errors, general: "Fallo al registra. por favor verifique su informacion." });
                });

        }
    };

    const handleClickLogin = () => {
        navigate('/Login');
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (userFirebase) => {
          if (userFirebase) {
            setUser(userFirebase);
            const dataUser = await getUserDetails();
              if(dataUser && dataUser?.admin){
                navigate('/admin/support/tickets')
                return
              }else{
                navigate('/dashboard')
              }
          }
        });
        return () => unsubscribe();
      }, []);

    return (
        <div className='container login-container container-signUp'>
            <form onSubmit={handleClickSignUp}>
                <h1 className='signUp-text  fw-bold'>Registrarse</h1>
                <div className='row p-0 align-items-center'>
                <div className='col-6 mt-0 mt-lg-0 col-lg-6 field-input text-center'>
                    <label>Nombre</label>
                    <input
                        type='text'
                        className='input-signup'
                        placeholder='Coloca tu nombre...'
                        value={user.firstname}
                        onChange={event => setUser({ ...user, firstname: event.target.value })}
                    />
                    {errors.firstname && <p className="text-danger error-md">{errors.firstname}</p>}
                </div>
                <div className='col-6 mt-0 mt-lg-0 col-lg-6 field-input text-center'>
                    <label>Apellido</label>
                    <input
                        type='text'
                        className='input-signup'
                        placeholder='Coloca tu apellido...'
                        value={user.lastname}
                        onChange={event => setUser({ ...user, lastname: event.target.value })}
                    />
                    {errors.lastname && <p className="text-danger error-md">{errors.lastname}</p>}
                </div>
                </div>
                <div className='row p-0 '>
                <div className='col-12 field-input text-center'>
                    <label>Correo electronico</label>
                    <input
                        type='email'
                        className='input-signup'
                        placeholder='Introduce tu correo...'
                        value={user.email}
                        onChange={event => setUser({ ...user, email: event.target.value })}
                    />
                    {errors.email && <p className="text-danger error-md">{errors.email}</p>}
                </div>
                </div>

                <div className='row p-0 align-items-center'>

                <div className='col-12 position-relative field-input  text-center'>
                    <label>Contraseña</label>
                    <div className='position-relative w-100'>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        className='input-signup'
                        placeholder='Introduce tu contraseña..'
                        value={user.password}
                        onChange={event => setUser({ ...user, password: event.target.value })}
                    />
                    {showPassword ? <FaEye onClick={togglePasswordVisibility} className='eye-icon' /> : <FaEyeSlash onClick={togglePasswordVisibility} className='eye-icon' />}
                    </div>
                    {errors.password && <p className="text-danger error-md">{errors.password}</p>}
                </div>

                <div className='col-12 mt-2 position-relative field-input text-center'>
                    <label>Confirme la contraseña</label>
                    <div className='position-relative w-100'>
                    <input
                        type={'password'}
                        className='input-signup'
                        placeholder='Introduce tu contraseña..'
                        value={user.passwordConfirm}
                        onChange={event => setUser({ ...user, passwordConfirm: event.target.value })}
                    />
                    </div>
                    {errors.passwordConfirm && <p className="text-danger error-md">{errors.passwordConfirm}</p>}
                </div>

                </div>
                <div className="field-checks field-input">
                <input
                  type="checkbox"
                  id="first-check"
                  style={{ marginTop: "4px" }}
                  onChange={(e) => {
                    setAcceptTerms({ ...acceptTerms, termsWeb: e.target.checked });
                  }}
                  checked={acceptTerms.termsWeb}
                />
                <label htmlFor="first-check" className="">
                  He leído y acepto del sitio web:
                  <Link to="/privacy-policy" style={{ color: "#b0ef96" }}>
                    Política de privacidad
                    <span style={{ color: "#fa5745" }}>*</span>
                  </Link>
                  <span style={{ margin: "0 2px" }}>,</span>
                  <Link to="/general-disclaimer" style={{ color: "#b0ef96" }}>
                    Descargo de responsabilidad general
                    <span style={{ color: "#fa5745" }}>*</span>
                  </Link>
                  <span style={{ margin: "0 2px" }}>,</span>
                  <Link to="/refund-cancellation" style={{ color: "#b0ef96" }}>
                    Política de reembolso y cancelación
                    <span style={{ color: "#fa5745" }}>*</span>
                  </Link>
                  <span style={{ margin: "0 3px" }}>y </span>
                  <Link to="/privacy-policy" style={{ color: "#b0ef96" }}>
                    Política de privacidad
                    <span style={{ color: "#fa5745" }}>*</span>
                  </Link>
                </label>
              </div>

              <div className="field-checks field-input">
                <input
                  type="checkbox"
                  id="second-check"
                  style={{ marginTop: "4px" }}
                  onChange={(e) => {
                    setAcceptTerms({
                      ...acceptTerms,
                      newsletter: e.target.checked,
                    });
                  }}
                  checked={acceptTerms.newsletter}
                />
                <label htmlFor="second-check" className="">
                  Acepto recibir mensajes de marketing del sitio web
                </label>
              </div>

                {errors.general && <p className="text-danger error-md">{errors.general}</p>}

                <button type='submit' className='button-send'>Registrarse</button>
                <div>
                    <p className='acc-text'>Ya tiene una cuenta?<Link to="/Login" className='login-text' style={{textDecoration:'none'}}>Inicia sesion</Link></p>
                </div>
            </form>
        </div>
    );
};

export default SignUp;
