import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    getDatabase,
    ref,
    orderByChild,
    equalTo,
    query,
    get,
    push,
    set,
    update,
  } from "firebase/database";
  import { app, getUserDetails } from "../../firebase/firebase";
import Swal from "sweetalert2";
import {TYPE_ACCOUNT_FUNDED} from "../../utils/index"

function ModalAccountRegister(props) {
  // This component is a modal that shows the information of the account
  const [show, setShow] = useState(props.show);
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

  const INITIAL_FUNDS = {
    '2k': '10.000',
    '5k': '10.000',
    '10k': '10.000',
    '25k': '10.000',
    '50k': '10.000',
    '100k': '10.000',
  }
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({email:'',id:'', password:'',package:"2k",server:"quotex",status:"process",visibility:true,order_id:"",initial_funds:INITIAL_FUNDS["2k"],created_at:new Date().toISOString()})
  // This function is executed when the component is rendered
  useEffect(() => {
    setShow(props.show);
    if(props.account){
        setCredentials({email:props.account.email, password:props.account.password,package:props.account.package,server:props.account.server,status:props.account.status,visibility:true,order_id:props.account.order_id,initial_funds:INITIAL_FUNDS[props.account.package],created_at:props.account.created_at, id:props.account.id})
    }
  }, [props]);

  useEffect(()=>{
    
  },[])

  const sendCredentials = async (e)=>{
    //Send the credentials to firebase in the account user
    e.preventDefault()
    Swal.fire({
      title:props.created ? "¿Estas seguro que desea crear esta cuenta?" : "¿Estas seguro que desea actualizar esta cuenta?",
      showDenyButton: true,
      confirmButtonText: props.created ? "Crear" : "Editar",
      denyButtonText: `Cancelar`
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try{
            const data = {...credentials, order_id: credentials.order_id || window.crypto.randomUUID().split('-').join("").slice(0,16)}
            // To create data
            if(props.created){
                
                const db = getDatabase(app);
                const dataUserAccounts = ref(db, `users/${props.userId}/accounts`);
                
                //Find if exists accounts with same order_id
                const orderQuery = query(
                    dataUserAccounts,
                    orderByChild("order_id"),
                    equalTo(String(data.order_id))
                );

                //Find if exists accounts with same order_id
                const emailQuery = query(
                    dataUserAccounts,
                    orderByChild("email"),
                    equalTo(credentials.email)
                );
            
            
                // Check if exists previous order_id to save
                const credentials_previous = await get(orderQuery);
                const credentials_previous_email = await get(emailQuery);
                if(credentials_previous_email.exists()){
                    Swal.fire({
                        title: "No pueden exitir un mismo email entre cuentas",
                        icon: "error"
                    });
                    return
                }
                if(credentials_previous.exists()){
                    Swal.fire({
                        title: "No pueden exitir un mismo orden de pedido entre cuentas",
                        icon: "error"
                    });
                    return
                }else{
                    // Save data in user accounts
                    const newDocAccount = push(dataUserAccounts);
                    await set(newDocAccount, {server:data.server.toLowerCase(), status:data.status, order_id:String(data.order_id), created_at:data.created_at, initial_funds: INITIAL_FUNDS[credentials.package],package:data.package, visibility:true, balance_actual_real:TYPE_ACCOUNT_FUNDED[credentials.package]});
                }
            }
            // To edit the data
            if(props.edit){
                const db = getDatabase(app);
                const dataUserAccounts = ref(db, `users/${props.userId}/accounts`);
            
                // Obtain account if order_id matches the parameter
                const userQuery = query(
                    dataUserAccounts,
                    orderByChild("email"),
                    equalTo(credentials.email)
                );
        
                const credentials_previous = await get(userQuery);
                if(credentials_previous.exists()){
                   //Check if email is equal to order_id
                   const dataAccountCheck = Object.values(credentials_previous.val())[0]
                   if(dataAccountCheck.order_id !== credentials.order_id){
                    Swal.fire({
                        title: "Error",
                        text:"Ya esta en uso este correo electronico en algunas de las cuentas ",
                        icon: "error"
                      });
                      return
                    }
                }
                
                if(credentials.email !== ""){
                  if(!validateEmail(credentials.email)){
                    Swal.fire({
                      title: "Error",
                      text:"El correo introducido no es valido",
                      icon: "error"
                    });
                    return
                  }
                }


                //Obtain previous data from the account 
                const accountQuery = query(
                  dataUserAccounts,
                  orderByChild("order_id"),
                  equalTo(credentials.order_id)
              );

                const accountPrevious = await get(accountQuery);
                if(!accountPrevious.exists()){
                  Swal.fire({
                    title: "Error",
                    text:"No se ha podido modificar la cuenta",
                    icon: "error"
                  });
                  return
                }

                const dataAccountData = Object.values(accountPrevious.val())[0]
        
                const userAccount = ref(db, `users/${props.userId}/accounts/${props.account.id}`);
                
                await update(userAccount, {email:credentials.email, password:credentials.password, initial_funds: INITIAL_FUNDS[credentials.package],package:data.package, server:data.server, status:data.status, user_credentials: credentials.email && credentials.password ? true : false, updated_at:new Date().toISOString(), balance_actual_real:dataAccountData.balance_actual_real ? dataAccountData.balance_actual_real : TYPE_ACCOUNT_FUNDED[credentials.package]})

               // Set start_date if email and password not exists
               

  
            }
            /*
          const userDetails = await getUserDetails()
          
          const db = getDatabase(app);
          const dataUserAccounts = ref(db, `users/${userDetails.userId}/accounts`);
      
          // Obtain account if order_id matches the parameter
          const userQuery = query(
            dataUserAccounts,
            orderByChild("email"),
            equalTo(credentials.email)
          );
  
          const credentials_previous = await get(userQuery);
          if(credentials_previous.exists()){
              Swal.fire({
                title: "Error",
                text:"Ya esta en uso este correo electronico en algunas de tus credenciales",
                icon: "error"
              });
              return
          }
  
  
          const userAccount = ref(db, `users/${userDetails.userId}/accounts/${props.account.id}`);
  
          await update(userAccount, {email:credentials.email, password:credentials.password, user_credentials:true, start_date: new Date().toISOString(), initial_funds:'10.000' })
            */
          Swal.fire({
            title: props.created ? 'Credenciales enviadas correctamente' : 'Informacion actualizada correctamente',
            icon: "success"
          });
          props.handleModalAdmin(false)
         
          if(props.created){
            setCredentials({email:'', password:'',package:"2k",server:"quotex",status:"process",visibility:true,order_id:"",initial_funds:INITIAL_FUNDS["2k"],created_at:new Date().toISOString(), updated_at:new Date().toISOString()})
            props.handleRefreshData(true)
        }

        if(props.edit && props.requestAccountsUser){
            props.requestAccountsUser()
        }
          //props.HandleModalCredentials(false)
          //props.requestAccountsUser()
      
      }catch(el){
        Swal.fire({
          title: "Ocurrio un error al enviar las credenciales",
          icon: "error"
        });
      }
      } 
    });
    
    
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};

const handleSelectPackage = (e) =>{
    setCredentials({...credentials,package:e.target.value})
}

const HandleStatusAccount = (e)=>{
    setCredentials({...credentials,status:e.target.value})
}

const handleSelectBroker = (e) =>[
    setCredentials({...credentials,server:e.target.value})
]

  return (
    <>
      <Modal
        className="modal-custom-accounts modal-admin modal-create-account"
        show={show}
        onHide={()=>{props.handleModalAdmin(false)}}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.edit ? 'Editar cuenta' : ''} 
            {props.created ? 'Registro de cuenta' : ''}
             </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="d-flex flex-column" onSubmit={sendCredentials}>
            <p>Introduzca la informacion de la cuenta</p>
            <div className={` ${props.edit ? 'd-flex' : 'd-none'} flex-column`} >
              <h3>Correo electronico</h3>
             <div className="d-flex">
                <input type="email" placeholder="Introduzca el correo electronico" onInput={(e)=>{
                    setCredentials({...credentials, email:e.target.value})
                }} value={credentials.email}/>
             </div>
            </div>
            <div className={`${props.edit ? 'd-flex' : 'd-none'} flex-column mt-2`}>
              <h3>Contraseña</h3>
             <div className="d-flex position-relative">
             {showPassword ? <FaEye onClick={togglePasswordVisibility} className='eye-icon' /> : <FaEyeSlash onClick={togglePasswordVisibility} className='eye-icon' />}
                <input type={showPassword ? 'text' : 'password'} value={credentials.password} onInput={(e)=>{
                    setCredentials({...credentials, password:e.target.value})
                }} placeholder="Introduzca la contraseña"/>
             </div>
            </div>
            <div className="row">
            <div className="d-flex flex-column mt-2 col-sm-6">
              <h3>Broker de la cuenta</h3>
             <div className="d-flex position-relative">
             <select className="select-modal" defaultValue={credentials.server} value={credentials.server} onChange={handleSelectBroker}>
                <option value="quotex">Quotex</option>
                <option value="iqoption">Iqoption</option>
                <option value="binomo">Binomo</option>
             </select>
             </div>
            </div>

            <div className="d-flex flex-column mt-2 col-sm-6">
              <h3>Tamaño de la cuenta</h3>
             <div className="d-flex position-relative">
             <select className="select-modal" defaultValue={credentials.package} onChange={handleSelectPackage}>
                <option value="2k">2K</option>
                <option value="5k">5k</option>
                <option value="10k">10k</option>
                <option value="25k">25k</option>
                <option value="50k">50k</option>
                <option value="100k">100k</option>
             </select>
             </div>
            </div>
            </div>
            <div className="d-flex flex-column mt-2">
              <h3>Estado de la cuenta</h3>
             <div className="d-flex position-relative">
             <select className="select-modal" defaultValue={credentials.status} onChange={HandleStatusAccount}>
                <option value="process">En proceso</option>
                <option value="active">Activa</option>
                <option value="suspense">Suspendida</option>
             </select>
             </div>
            </div>
            <div className="d-flex flex-column mt-2">
              <h3>Identificador de compra</h3>
             <div className="d-flex flex-column position-relative">
             <input type="text" placeholder="Identificador" onInput={(e)=>{
                    setCredentials({...credentials, order_id:e.target.value})
                }} value={credentials.order_id} readOnly={props.edit}/>
                {props.created ? <small className="mt-1">Si esta vacio se generara uno aleatoriamente</small> : ''}
             </div>
            </div>
            
            <div className="d-flex flex-column w-100 justify-content-center">
                <button type="submit" className="button-send m-auto mt-3">
                    Enviar
                </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{props.handleModalAdmin(false)}}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalAccountRegister;
