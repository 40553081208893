import { useEffect } from 'react'
import ModalDetail from './ModalDetail'
import './tickets.css'
import { useState } from 'react'
import { STATUS_TICKET } from '../../utils'
import ModalCreateTicket from './ModalCreateTicket'
import {
    getDatabase,
    ref,
    query,
    get,
  } from "firebase/database";
  import { app,getUserDetails } from "../../firebase/firebase";
const Tickets = (props) =>{
    const LIMIT_ELEMENTS = 6
    const [tickets,setTickets]= useState([])
    const [filterTickets,setFilterTickets] = useState("all")
    const [load,setLoad] = useState(false)
    const [error,setOnError] = useState(true)
    const [modalDetail,setModalDetail]= useState({
        show:false,
        ticket:{
            title:'',
            id:'',
            email:'',
            created_at:'',
            status:'',
            description:''
        }
    })

    const [showCreateTicket,setshowCreateTicket]= useState(false)

    const changeFilter = (e)=>{
        setoffSetList(0)
        setFilterTickets(e.target.value)
    }

    const openDetailModal = (show, ticket) => {
          setModalDetail({
            show,
            ticket,
          });
    }

    const openCreateTicketModal = ()=>{
        setshowCreateTicket(true)
    }

    const handleClose = ()=>{
        setModalDetail({...modalDetail, show:false})
    }

    const [offSetList, setoffSetList] = useState(0);
    
    const handleNext = () => {
        // Check if the next page is available based on the limit of elements and the filter
        if (offSetList + LIMIT_ELEMENTS < tickets.filter((el)=>{
            if(filterTickets === 'all'){
                return el
            }
            if(filterTickets === 'buyer'){
                return el.buyer
            }
            if(filterTickets === 'normal'){
                return !el.buyer
            }
            return el
        }).length) {
          setoffSetList(offSetList + LIMIT_ELEMENTS);
        }
      };
    
      const handlePrev = () => {
        if (offSetList > 0) {
          setoffSetList(offSetList - LIMIT_ELEMENTS);
        }
      };

      const requestTickets = async () =>{
        try{
            // Initialize states and show the tickets from the user
            setOnError(false)
            setLoad(false)
            const userDetails = await getUserDetails()
            const db = getDatabase(app);
            const ref_data = ref(db, `support/tickets/${userDetails.userId}`);
            const data = await get(query(ref_data));
            if(data.exists()){
                const tickets = Object.values(data.val())
                // Sort by created_at
                const orderedTickets = tickets.sort((a,b)=>{
                    return new Date(b.created_at) - new Date(a.created_at)
                })
                setTickets(orderedTickets)
                setLoad(true)
            }else{
                setLoad(true)
            }
        }catch(e){
            setLoad(true)
            setOnError(true)
      }
    }

    const requestTicketsAdmin = async () =>{
        try{
            // Initialize states
            setTickets([])
            setoffSetList(0)
            setOnError(false)
            setLoad(false)
            const db = getDatabase(app);
            const ref_data = ref(db, `support/tickets`);
            const data = await get(query(ref_data));
            let list = []
            if(data.exists()){
                const tickets = Object.values(data.val())
                //Each every user
                tickets.forEach((user_data)=>{
                    const registeredTickets = Object.values(user_data)
                    //Each ticket and push to list
                    if(registeredTickets && registeredTickets.length > 0){
                    registeredTickets.forEach((ticket)=>{
                        list.push(ticket)
                    })
                }
                })

                //Sort by created_at
                if (list.length > 0){
                    list.sort((a,b)=>{
                        return new Date(b.created_at) - new Date(a.created_at)
                    })
                }

                setTickets(list)
            }else{
                setLoad(true)
            }
            setLoad(true)
        }catch(e){
            setLoad(true)
            setOnError(true)
      }
    }
    

    useEffect(()=>{
        if(props.isAdmin){
            requestTicketsAdmin()
        }else{
        requestTickets()
    }
    
    },[props.isAdmin, props.user])

    return (
        <>
        <ModalDetail
            requestTicketsAdmin={requestTicketsAdmin}
            show={modalDetail.show}
            isAdmin={props.isAdmin}
            ticket={modalDetail.ticket}
            handleClose={handleClose}
        />

        {!props.isAdmin ? <ModalCreateTicket
            show={showCreateTicket}
            handleClose={()=>{setshowCreateTicket(false); requestTickets();}}
        /> : ''}
        <div className="container-section-tickets">

        
        <section className="section-tickets">
            <div className="container">
                <div className={`row ${props.isAdmin ? 'd-none' : ''}`}>
                    <div className={`col-12 ${props.isAdmin ? 'col-lg-12' : 'col-lg-6'} d-flex flex-column justify-content-center justify-content-lg-start`}>
                        <h2 className="title-section text-center text-lg-start mb-1 mb-lg-0">Tickets de soporte</h2>
                    </div>
                    {props.user && !props.isAdmin  ? <div className="d-none d-lg-flex col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                        <button className='button-send-transparent' style={{textTransform:'initial ',fontSize:'16px'}} type="button" onClick={()=>{
                            openCreateTicketModal()
                        }}>Abrir nuevo ticket</button>
                    </div> : ''}
                </div>

                <div className={`row mt-3 ${props.isAdmin ? 'd-none' : ''}`}>
                    <div className='col-12 d-flex flex-column text-center text-lg-start'>
                   {!props.isAdmin ?  <p>En esta sección podrás abrir tickets de soporte para realizar consultas o solicitar asistencia. <br/>Nuestro equipo de soporte estará encantado de ayudarte con cualquier inquietud que tengas relacionada con nuestros servicios.</p>
: ''}
                    {!props.user && !props.isAdmin ? <p>Para abrir un ticket de soporte, por favor inicia sesión o crea una cuenta.</p>: ''}
                    {props.user && !props.isAdmin ? <button onClick={()=>{
                        openCreateTicketModal()
                    }} className='d-flex d-lg-none m-auto button-send-transparent mt-2 mb-2' style={{textTransform:'initial ',fontSize:'16px'}} type="button">Abrir nuevo ticket</button> : ''}
                    </div>
                </div>

                {props.user ? 
                
                    <div className={`d-flex flex-column ${props.isAdmin ? 'mt-0' : 'mt-4'}`}>
                        <h4 className='title-tickets text-center text-lg-start'>{!props.isAdmin ? 'Tus tickets creados' : 'Tickets de usuarios' }</h4>
                        {load && tickets.length === 0 && !error ? <p className='text-center text-lg-start'>No tienes tickets creados</p> : ''}
                        {load && tickets.length >= 1 && !error && !props.isAdmin ? <p className='text-center text-lg-start'>Cantidad de tickets creados: {tickets.length}</p> : ''}
                        {load && tickets.length >= 1 && !error  && props.isAdmin ? 
                        <div className='d-flex flex-column flex-lg-row justify-content-lg-between'>
                            <div className='d-flex flex-column'>
                                <p className='m-0 text-center fw-medium text-lg-start'>Mostrar:</p>
                                <select className='select-users m-auto mt-2 m-lg-0' onChange={changeFilter} style={{width:'100%', maxWidth:'350px'}}>
                                    <option value='all'>Todos los usuarios</option>
                                    <option value="buyer">Clientes</option>
                                    <option value="normal">No clientes</option>
                                </select>
                            </div>
                            <p className={`text-center text-lg-start ${props.isAdmin ? 'align-self-end m-auto mt-2 m-lg-0' : ''}`}>Cantidad de tickets registrados: {tickets.filter((el)=>{
                            if(filterTickets === 'all'){
                                return el
                            }
                            if(filterTickets === 'buyer'){
                                return el.buyer
                            }
                            if(filterTickets === 'normal'){
                                return !el.buyer
                            }
                            return el
                        }).length}</p>
                            
                        </div> : ''}
                        {!load ? (
                            <div className="d-flex flex-column align-items-center justify-content-center mb-4 mb-lg-0">
                                <div className="lds-dual-ring"></div>
                            </div>
                            ) : (
                            ""
                            )}
                      { load && !error ? <div className='container-tickets-mobile mt-2'>
                        {tickets.filter((el)=>{
                            if(filterTickets === 'all'){
                                return el
                            }
                            if(filterTickets === 'buyer'){
                                return el.buyer
                            }
                            if(filterTickets === 'normal'){
                                return !el.buyer
                            }
                            return el
                        }).slice(offSetList, offSetList + LIMIT_ELEMENTS).map((ticket,index)=>{
                                return(
                                    <div className='ticket position-relative' key={ticket.id} onClick={()=>{
                                        openDetailModal(true,{id:ticket.id, uid:ticket.uid, subject:ticket.subject, 
                                        buyer:ticket.buyer || '',description:ticket.description, created_at: ticket.created_at, status: ticket.status})
                                    }}>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 '>
                                                <div className='d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between'>
                                                    <h2 className='label-ticket'>Asunto del ticket</h2>
                                                    {props.isAdmin && ticket.buyer ? <span className='label-buyer'>Cliente</span> : ''}
                                                </div>
                                                <p className='ticket-title'>{ticket.subject}</p>
                                            </div>
                                        </div>
                                        
                                        <div className='row'>
                                            <div className='col-12 mb-1 col-sm-7'>
                                                    <h4 className='label-ticket'>Fecha de creacion</h4>
                                                    <p>{new Date(ticket.created_at).toString() !== "Invalid Date" ? new Date(ticket.created_at).toISOString().slice(0, 10).replaceAll("-", "/") : ''}</p>
                                            </div>
                                            <div className='col-12 mb-1 col-sm-5'>
                                                    <h4 className='label-ticket'>Estado</h4>
                                                    <p style={{textTransform: "capitalize"}}>{ticket.status}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                        })}

                        </div> : ''}

                        {load && error ? <div className='d-flex flex-column'> 
                            <p className='text-start'>Ha ocurrido un error al cargar los tickets, prueba a recargar</p>
                            <button className='button-transparent button-send-transparent m-auto m-lg-0' type="button" onClick={()=>{requestTickets()}}>Recargar</button>
                             </div>: ''}
                        {tickets.length > LIMIT_ELEMENTS ? 
                        <div className="d-flex align-items-center gap-2 mt-3 justify-content-between">
                            <button
                            className="button-send-transparent"
                            onClick={() => {
                                handlePrev();
                            }}
                            style={{
                                textTransform: "capitalize",
                                padding: "10px 20px",
                                width: "max-content",
                            }}
                            >
                            Anterior
                            </button>
                            <button
                            className="button-send-transparent"
                            onClick={() => {
                                handleNext();
                            }}
                            style={{
                                textTransform: "capitalize",
                                padding: "10px 20px",
                                width: "max-content",
                            }}
                            >
                            Siguiente
                            </button>
                        </div>: ''}

                    </div> 
                
                
                : ''}
                
            </div>
        </section>
        </div>
        </>
    )
}

export default Tickets